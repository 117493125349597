//================================================================
//  Tab: Terms
//================================================================

//  Purpose: This tab displays all the agreement terms

//  Example:
//    <Terms></Terms>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images


export default function Terms() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');
  
    // Holds the application document from 
    const [agreementDoc, setAgreementDoc] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Query Listener
    // - Get Firestore document
    useEffect(() => {

      const agreementid = params.id;

      if (agreementid === '') return;
      if (agreementid === null) return;

      function onLoadChange(document){  

        setAgreementDoc(document);
        setPageStatus('onload');

      }
      
      function onError(error){

        console.log(error);
        setPageStatus('error-fatal');
      
      }
      
      const unsubscribe = DocumentListener('agreements', agreementid, onLoadChange, onLoadChange, onError);
      
      return () =>{
        unsubscribe();
      };
        
    // eslint-disable-next-line
    }, [getUser]);
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='flex flex-col w-[50%] px-[20px]'>

            {/* Agreement Start Date */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'agreementstartdate'}
              label={<label className='font-medium'>Agreement Start Date</label>}
              defaultValue={agreementDoc?.agreementstartdate}
              type={'date-string'}
              disabled={false}
            ></LiveField>  

            {/* Agreement End Date */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'agreementenddate'}
              label={<label className='font-medium'>Agreement End Date</label>}
              defaultValue={agreementDoc?.agreementenddate}
              type={'date-string'}
              disabled={false}
            ></LiveField>

            {/* Execution Method */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'executionmethod'}
              label={<label className='font-medium'>Execution Method</label>}
              defaultValue={agreementDoc?.executionmethod}
              type={'choice'}
              options={[
                'PoA',
                's127',
              ]}
              disabled={false}
            ></LiveField> 

            {/* Execution Date */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'executiondate'}
              label={<label className='font-medium'>Execution Date</label>}
              defaultValue={agreementDoc?.executiondate}
              type={'date-string'}
              disabled={false}
            ></LiveField> 

            {/* Currency */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'currency'}
              label={<label className='font-medium'>Currency</label>}
              defaultValue={agreementDoc?.currency}
              type={'choice'}
              options={[
                'USD',
                'SGD',
                'GBP',
                'AUD',
              ]}
              disabled={false}
            ></LiveField>  

            {/* Lendlease Entity */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'lendleaseentity'}
              label={<label className='font-medium'>Lendlease Entity</label>}
              defaultValue={agreementDoc?.lendleaseentity}
              type={'choice'}
              options={[
                'LLMS',
                'LLC',
                'LLiS',
              ]}
              disabled={false}
            ></LiveField>  

            {/* Estimated Annual Contract Value */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'estannualcontractvalue'}
              label={<label className='font-medium'>Estimated Annual Contract Value</label>}
              defaultValue={agreementDoc?.estannualcontractvalue}
              type={'number'}
              placeholder={'Enter contract value'}
              disabled={false}
            ></LiveField> 

            {/* Estimated Total Contract Value */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'esttotalcontractvalue'}
              label={<label className='font-medium'>Estimated Total Contract Value</label>}
              defaultValue={agreementDoc?.esttotalcontractvalue}
              type={'number'}
              placeholder={'Enter contract value'}
              disabled={false}
            ></LiveField> 

            {/* Total Agreement Amount */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'totalagreementamount'}
              label={<label className='font-medium'>Total Agreement Amount</label>}
              defaultValue={agreementDoc?.totalagreementamount}
              type={'number'}
              placeholder={'Enter amount'}
              disabled={false}
            ></LiveField> 

            {/* Auto-Renewals */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'autorenewals'}
              label={<label className='font-medium'>Auto-Renewals</label>}
              defaultValue={agreementDoc?.autorenewals}
              type={'choice'}
              options={[
                'Yes',
                'No',
              ]}
              disabled={false}
            ></LiveField> 

            {/* Notice Period to Terminate Auto-Renewal */}
            <div hidden={agreementDoc?.autorenewals !== 'Yes'}>
              <LiveField
                type={'date-string'}
                collectionid={'agreements'}
                documentid={agreementDoc?.agreementid}
                fieldpath={'noticeperiodforautorenewaltermination'}
                label={<label className='font-medium'>Notice Period to Terminate Auto-Renewal</label>}
                defaultValue={agreementDoc?.noticeperiodforautorenewaltermination}
                disabled={false}
              ></LiveField> 
            </div>

            {/* Extension Agreement */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'extensionagreement'}
              label={<label className='font-medium'>Extension Agreement</label>}
              defaultValue={agreementDoc?.extensionagreement}
              type={'choice'}
              options={[
                'Yes',
                'No',
              ]}
              disabled={false}
            ></LiveField> 

            {/* Notice Period for Extension of Agreement */}
            <div hidden={agreementDoc?.extensionagreement !== 'Yes'}>
              <LiveField
                type={'date-string'}
                collectionid={'agreements'}
                documentid={agreementDoc?.agreementid}
                fieldpath={'noticeperiodforextension'}
                label={<label className='font-medium'>Notice Period for Extension of Agreement</label>}
                defaultValue={agreementDoc?.noticeperiodforextension}
                disabled={false}
              ></LiveField>
            </div>

            {/* Project Code */}
            <LiveField
              type={'text'}
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'projectcode'}
              label={<label className='font-medium'>Project Code</label>}
              defaultValue={agreementDoc?.projectcode}
              placeholder={'Enter project code'}
              disabled={false}
            ></LiveField> 

            {/* Task Number */}
            <LiveField
              type={'text'}
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'tasknumber'}
              label={<label className='font-medium'>Task Number</label>}
              defaultValue={agreementDoc?.tasknumber}
              placeholder={'Enter task number'}
              disabled={false}
            ></LiveField> 

            {/* Expenditure Type */}
            <LiveField
              type={'text'}
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'expendituretype'}
              label={<label className='font-medium'>Expenditure Type</label>}
              defaultValue={agreementDoc?.expendituretype}
              placeholder={'Enter expenditure type'}
              disabled={false}
            ></LiveField> 

          </div>
        }
      ></PageComponent>
    )
}
