//================================================================
//  Component: User Search (Live Fields)
//================================================================

//  Purpose: This is an input field that allows you to search all LL Users and select a user

//  Properties:
//    - defaultValue = {string, onLoad email address}
//    - handleBlur = {function, call back for handle blur events}
//    - errors = {defined value, this determines if this field is in its error state}
//    - disabled = <optional> - {boolean, disables the input}

//  Example:
//    <UserSearchLiveFields
//      defaultValue={emailaddress}
//      handleBlur={handleBlur}
//      errors={true}
//      disabled={disabled}
//    ></UserSearchLiveFields>    

//================================================================


//Libraries
import React, { useEffect, useReducer } from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../../Library/QueryDocument';

//Images


export default function UserSearchLiveFields({
    defaultValue,
    handleBlur,
    errors,
    disabled,
}) {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the search data
    const [selectedUser, setSelectedUser] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'newUserInput': '', // email of the selected user
            'newUserName': '', // fullname of the selected user
            'newGivenname': '', // givenname of the selected user
            'newSurname': '', // surname of the selected user
            'searchResults': '',
            'queryMessage': '',
        }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Function used to handle search
    function handleSearch(value) {

        setSelectedUser({
            'searchResults': [],
            'newUserInput': value,
        })

        // Validate input
        if (value?.length === 0) {

            return setSelectedUser({ 'queryMessage': 'Please enter an email address.' });

        }

        if (value?.length <= 2) {

            return setSelectedUser({ 'queryMessage': 'Search requires more than two characters.' });

        }

        const query = selectedUser?.newUserInput?.toLowerCase();

        // Search by characters in email
        QueryDocument('users', [
            ['searcharray', 'array-contains-any', [query]], 
            ['usertype', '==', 'lendleaseuser']
        ])
        .then((users) => {

            setSelectedUser({
                'searchResults': users,
                'queryMessage': `Showing ${users?.length} result(s)`,
            });

        })
        .catch((errors) => {

            console.log(errors);
            setSelectedUser({ 'queryMessage': 'Oops something went wrong!' });

        });

    }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onChange
    //  - set to default value
    useEffect(() => {

        if (defaultValue === undefined) return;

        setSelectedUser({'newUserInput': defaultValue?.email});

    }, [defaultValue]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <div className='relative'>

            {/* ------------------------------------------------------ */}
            {/*  Users Search Bar                                      */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center'>

                {/* Search Input Field */}
                <input
                    className={
                        errors ?
                            'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border-[1px] border-solid border-[#DE0000] rounded-[5px] disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'

                        : selectedUser?.searchResults?.length > 0 ?
                            'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                        : 
                            'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                    }
                    type='text'
                    placeholder='Search user by email address'
                    value={selectedUser?.newUserInput}
                    onChange={(evt) => handleSearch(evt.target.value)}
                    onPaste={(evt) => handleSearch(evt.clipboardData.getData('Text'))}
                    autoComplete='no'
                    disabled={disabled}
                ></input>

            </div>

            {/* Search Results */}
            {
            selectedUser?.searchResults?.length > 0 &&
            (
                <div className='absolute w-[450px] top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>
                    
                    {/* Results */}
                    {
                        selectedUser?.searchResults.map((user, index) => (
                        <div 
                            key={index} 
                            className='flex flex-row justify-between px-4 py-[13px] cursor-pointer rounded-sm hover:bg-[#F0F0F0]' 
                            onClick={() => { 

                                handleBlur({
                                    'email': user?.emailaddress,
                                    'givenname': user?.givenname,
                                    'surname': user?.surname,
                                }, true);

                                setSelectedUser({ 
                                    'searchResults': [],
                                    'newUserInput': user?.emailaddress,
                                    'newUserName': `${user?.givenname} ${user?.surname}`,
                                    'newGivenname': user?.givenname,
                                    'newSurname': user?.surname,
                                });
                            
                            }}
                        >
            
                            {/* User Detail */}
                            <div className='flex flex-col'>
                                <p className='m-0 font-medium text-[14px]'>{user?.givenname} {user?.surname}</p>
                                <p className='m-0 text-[14px]'>{user?.emailaddress} ({user?.jobtitle})</p>
                            </div>
                        
                        </div>
                        ))
                    }
                    
                    {/* Total */}
                    <div className='text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={selectedUser.queryMessage.length === 0}>
                        {selectedUser.queryMessage}
                    </div>

                </div>
            )
            }
            
        </div>
    )

  //------------------------------------------------------
}
