//================================================================
//  Tab: BCP
//================================================================

//  Purpose: This tab displays all 'BCP' of an Application

//  Example:
//    <BCP></BCP>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images


export default function BCP() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState();

  // Holds the application document from 
  const [applicationDoc, setApplicationDoc] = useState();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Overview with the status of 'Active'
  useEffect(() => {

    const applicationId = params.id;

    if (applicationId === '') return;
    if (applicationId === null) return;

    function onLoadChange(document) {

      setApplicationDoc(document);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('applications', applicationId, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col w-[50%] px-[20px]'>

          {/* BCP */}

          <LiveField
            type='choice'
            collectionid='applications'
            documentid={applicationDoc?.applicationid}
            fieldpath={'bcp'}
            label={<label className='font-medium'>BCP Defined</label>}
            defaultValue={applicationDoc?.bcp}
            options={[
              'Yes',
              'No',
              'N/A'
            ]}
          ></LiveField>

          {/* RPO - Recovery Time Objective */}

          <LiveField
            type='choice'
            collectionid='applications'
            documentid={applicationDoc?.applicationid}
            fieldpath={'rpo'}
            label={<label className='font-medium'>RPO</label>}
            defaultValue={applicationDoc?.rpo}
            options={[
              '0 - 4 hours',
              '4 - 12 hours',
              '12 - 24 hours',
              '24 - 48 hours',
              '7 - 14 days',
              'Best Effort ',
            ]}
          ></LiveField>

          {/* RTO - Recovery Point Objective */}

          <LiveField
            type='choice'
            collectionid='applications'
            documentid={applicationDoc?.applicationid}
            fieldpath={'rto'}
            label={<label className='font-medium'>RTO</label>}
            defaultValue={applicationDoc?.rto}
            options={[
              '0 - 4 hours',
              '4 - 12 hours',
              '12 - 24 hours',
              '24 - 48 hours',
              '7 - 14 days',
              'Best Effort ',
            ]}
          ></LiveField>

          {/* Link to DR */}

          <LiveField
            type='text'
            collectionid='applications'
            documentid={applicationDoc?.applicationid}
            fieldpath={'linktodr'}
            label={<label className='font-medium'>Link to DR</label>}
            placeholder={'Enter link'}
            defaultValue={applicationDoc?.linktodr}
          ></LiveField>

        </div>
      }
    ></PageComponent>
  )
}
