//================================================================
//  Component: User Search
//================================================================

//  Purpose: This is an input field that allows you to search all LL Users and select a user

//  Properties:
//    - selectedUser = {useReducer, this stores the current selected user, search results and query message}
//    - setSelectedUser = {useReducer, this is used to set the selected user state}
//    - errorMessage = {string, error message}

// <REQUIRED> useReducer structure:
//      {
//          'newUserInput': '', // email of the selected user
//          'newUserName': '', // fullname of the selected user
//          'newGivenname': '', // givenname of the selected user
//          'newSurname': '', // surname of the selected user
//          'searchResults': '',
//          'queryMessage': '',
//      }

//  Example:
//    <UserSearch
//      selectedUser={formData}
//      setSelectedUser={setFormData}
//      errorMessage={errorMessage}
//    ></UserSearch>    

//================================================================


//Libraries
import React from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Images

export default function UserSearch({
    selectedUser,
    setSelectedUser,
    errorMessage,
}) {

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Function used to handle search
    function handleSearch(value) {

        setSelectedUser({
            'searchResults': [],
            'newUserInput': value,
        })

        // Validate input
        if (selectedUser?.newUserInput?.length === 0) {

            return setSelectedUser({ 'queryMessage': 'Please enter an email address.' });

        }

        if (selectedUser?.newUserInput?.length === 2) {

            return setSelectedUser({ 'queryMessage': 'Search requires more than two characters.' });

        }

        const query = selectedUser?.newUserInput?.toLowerCase();

        // Search by characters in email
        QueryDocument('users', [
            ['searcharray', 'array-contains-any', [query]], 
            ['usertype', '==', 'lendleaseuser']
        ])
        .then((users) => {

            setSelectedUser({
                'searchResults': users,
                'queryMessage': `Showing ${users?.length} result(s)`,
            });

        })
        .catch((error) => {

            console.log(error);
            setSelectedUser({ 'queryMessage': 'Oops something went wrong!' });

        });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <div className='flex flex-col w-full h-full relative'>

            {/* ------------------------------------------------------ */}
            {/*  Users Search Bar                                      */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center'>

                {/* Search Input Field */}
                <input
                    className={
                        errorMessage?.length > 0 ? 
                            'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border-1 border-solid border-[#DE0000] rounded-[5px]'
                        :
                        selectedUser?.searchResults?.length > 0 ?
                            'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none'
                        : 
                            'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-[5px]'
                    }
                    type='text'
                    placeholder='Search user by email address'
                    value={selectedUser?.newUserInput}
                    onChange={(evt) => handleSearch(evt.target.value)}
                    onPaste={(evt) => handleSearch(evt.clipboardData.getData('Text'))}
                    autoComplete='no'
                ></input>

            </div>

            {/* Error Message */}
            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errorMessage}</p>

            {/* Search Results */}
            {
            selectedUser?.searchResults?.length > 0 &&
            (
                <div className='absolute w-full top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>
                    
                    {/* Results */}
                    {
                        selectedUser?.searchResults.map((user, index) => (
                        <div 
                            key={index} 
                            className='flex flex-row justify-between px-4 py-[13px] cursor-pointer rounded-sm hover:bg-[#F0F0F0]' 
                            onClick={() => { 
                                setSelectedUser({ 
                                    'searchResults': [],
                                    'newUserInput': user?.emailaddress,
                                    'newUserName': `${user?.givenname} ${user?.surname}`,
                                    'newGivenname': user?.givenname,
                                    'newSurname': user?.surname,
                                });
                            }}
                        >
            
                            {/* User Detail */}
                            <div className='flex flex-col'>
                                <p className='m-0 font-medium text-[14px]'>{user?.givenname} {user?.surname}</p>
                                <p className='m-0 text-[14px]'>{user?.emailaddress} ({user?.jobtitle})</p>
                            </div>
                        
                        </div>
                        ))
                    }
                    
                    {/* Total */}
                    <div className='text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={selectedUser.queryMessage.length === 0}>
                        {selectedUser.queryMessage}
                    </div>

                </div>
            )
            }
            
        </div>
    )

  //------------------------------------------------------
}
