//================================================================
//  Tab: Contracts
//================================================================

//  Purpose: This tab displays all the contracts associated to this agreement

//  Example:
//    <Contracts></Contracts>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images
import IconSearch from '../../../../../Components/Images/Icon_Search_Grey.svg';


export default function Contracts() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');
  
    // Holds the application document from 
    const [agreementDoc, setAgreementDoc] = useState();

    // Search Input
    const [searchInput, setSearchInput] = useState('');

    // Holds the filtered agreement doc
    const [agreementDocFiltered, setAgreementDocFiltered] = useState([]);
    
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

      setSearchInput(value);

      // Reset the Filter
      if (value.length === 0) {

        setAgreementDocFiltered(agreementDoc);

      };

      // Search Filter
      //  1. Contract ID
      //  2. Short Description
      const searchResults = agreementDoc.filter((object) =>
        object?.contractid?.toLowerCase().includes(value.toLowerCase()) ||
        object?.shortdescription?.toLowerCase().includes(value.toLowerCase())
      );

      // Save the filtered search results to the useState
      setAgreementDocFiltered(searchResults);

    };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Query Listener
    // - Get Firestore document
    useEffect(() => {

      const agreementid = params.id;

      if (agreementid === '') return;
      if (agreementid === null) return;

      function onLoadChange(document){  

        setAgreementDoc(document);
        setAgreementDocFiltered(document);
        setPageStatus('onload');

      }
      
      function onError(error){

        console.log(error);
        setPageStatus('error-fatal');
      
      }
      
      const unsubscribe = DocumentListener('agreements', agreementid, onLoadChange, onLoadChange, onError);
      
      return () =>{
        unsubscribe();
      };
        
    // eslint-disable-next-line
    }, [getUser]);
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='px-[20px]'>
            <div className='Table-Container'>

              {/* ======== Sorting and Filters ========== */}
              <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                <label className='font-semibold self-center'>
                  Total: {agreementDocFiltered?.signedcontracts?.length} of {agreementDoc?.signedcontracts?.length}
                </label>

                {/* Search Bar */}
                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                  
                  <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                  <label htmlFor='searchInput'>
                    <input
                        id='searchInput'
                        className='border-none h-[40px] p-0 m-0 outline-none'
                        type='text'
                        placeholder='Search'
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchInput}
                    ></input>
                  </label>
                </div>

              </div>

              <table className='w-full max-h-96 overflow-y-scroll'>
                <thead>
                  <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                    <th className='py-[15px] px-[20px] font-medium'>Contract ID</th>
                    <th className='py-[15px] px-[20px] font-medium'>Short Description</th>
                    <th className='py-[15px] px-[20px] font-medium'>Signees</th>
                    <th className='py-[15px] px-[20px] font-medium'>Documents</th>
                    <th className='py-[15px] px-[20px] font-medium'>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // No contracts found
                    agreementDocFiltered?.signedcontracts?.length === 0 ? 
                    (
                      <tr>
                        <td colSpan={7}>
                          <div className='text-center p-[2%]'>
                            No contracts found.
                          </div>
                        </td>
                      </tr>
                    )
                    : 
                    (
                      // Map in each contract
                      agreementDocFiltered?.signedcontracts?.map((contract, index) => (
                        <tr key={index} className='border-b-[#C8C8C9] border-solid border-b align-top'>

                          {/* Contract ID */}
                          <td className='py-[15px] px-[20px] uppercase font-medium'>
                            {contract?.contractid}
                          </td>
                          
                          {/* Short Description */}
                          <td className='py-[15px] px-[20px]'>
                            <div className='max-w-[250px] leading-normal overflow-hidden text-ellipsis'>
                              {contract?.shortdescription}
                            </div>
                          </td>

                          {/* Signees */}
                          <td className='py-[15px] px-[20px]'>
                            {
                              contract?.signees?.length === 0 ?
                              <div>
                                -
                              </div>
                              :
                              <div className='flex flex-col gap-2'>
                                {
                                  contract?.signees?.map((signee) => (
                                    <div key={index} className='w-full flex flex-row justify-between border border-[#D8D8D8] px-3 py-2 rounded-[5px] bg-[#F8F8F8]'>
                                      <div className='flex flex-col'>
                                        <p className='font-medium m-0'>{signee?.signeeemail}</p>
                                        <p className='text-[13px] m-0 text-slate-600'>{signee?.signingparty}</p>
                                        <p className='mb-0 text-[13px]'><i>Date signed: {signee?.signaturedate.split('-')[2]}/{signee?.signaturedate.split('-')[1]}/{signee?.signaturedate.split('-')[0]}</i></p>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                            }
                          </td>
                          
                          {/* Documents */}
                          <td className='py-[15px] px-[20px]'>
                            <div className='flex flex-col gap-2'>
                              {
                                contract?.documents?.map((doc) => (
                                  <label className='bg-[#F5F5F5] text-[#0972D3] font-medium py-2 px-3 rounded-md'>
                                    <a className='no-underline' href={doc?.fileUrl} target='_blank' rel='noopener noreferrer'>{doc?.fileName}</a>
                                  </label>
                                ))
                              }
                            </div>
                          </td>

                          {/* Created By */}
                          <td className='py-[15px] px-[20px]'>
                            <b className='font-medium'>{contract?.createdby?.givenname} {contract?.createdby?.surname}</b>
                            <br></br>
                            {contract?.createdby?.email}
                          </td>

                        </tr>
                      ))
                    )
                  }
                </tbody>
              </table>

            </div>
          </div>
        }
      ></PageComponent>
    )
}
