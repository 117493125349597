//================================================================
//  Component: User Pane
//================================================================

//  Purpose: Pane to add/remove users to a field in Firestore document

//  Properties:
//    - title = {String, title of the sidepane, typically the column name}
//    - vendorname = {String, name of the vendorname}
//    - collectionid = {String, Firestore collection id}
//    - documentid = {String, Firestore document id}
//    - fieldPath = {String, Firestore field path}
//    - defaultUsers = {Array, list of users who have access}
//    - paneOpen = {useState, used to determine if the modal is open/closed}
//    - setPaneOpen = {useState, used to determine if the modal is open/closed}


//  Example:
//    <UserPane
//      title={'@Risk'}
//      vendorname={'Auto Desk'}
//      collectionid={'applications'}
//      documentid={'app-1234567890'}
//      fieldPath={'subscribed'} 
//      defaultUsers={usersArray} 
//      paneOpen = {paneOpen}
//      setPaneOpen = {setPaneOpen}
//    ></UserPane>    

//================================================================


//Libraries
import React, { useState, useEffect, useReducer, useRef } from 'react';
import { CSVLink } from 'react-csv';

//Contexts

//Components

//Functions
import GetDocument from '../../Library/GetDocument';
import QueryDocument from '../../Library/QueryDocument';
import AddRemoveDocumentArray from '../../Library/AddRemoveDocumentArray';
import GetCollection from '../../Library/GetCollection';

//Images
import Error from '../Images/Icon_Error_Red.svg';
import Add from '../Images/Icon_Add_Teal.svg';
import AddDisabled from '../Images/Icon_AddDisabled_Teal.svg';
import LoadingIcon from '../Images/Image_Loading_Ripple.svg';
import ClearSearch from '../Images/Icon_Clear_Grey.svg';
import DownloadIcon from '../Images/Icon_Download_Teal.svg';


export default function UserPane({
  title,
  vendorname,
  collectionid,
  documentid,
  fieldPath,
  defaultUsers,
  paneOpen,
  setPaneOpen,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set pane status > 'pending', 'onload', 'error'
  const [paneStatus, setPaneStatus] = useState('pending');

  // Download all AzureAD User Data states
  const csvLink = useRef();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store details about the users
  const [userData, setUserData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'paginatedUsers': [],
      'addedUsers': [],
      'removedUsers': [],

      // Download Subscribers Report
      'currentUsers': [],
      'disableUsageReportButton': false,

      // Add Users Search
      'newUserInput': '',
      'queryMessage': '',
      'searchResults': []
    }
  );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Function used to handle search
  function handleUserSearch(value) {

    if (value.length <= 3) {

      return setUserData({
        'queryMessage': 'Search requires more than three characters.',
        'searchResults': [],
      });

    }

    // Reset search
    setUserData({
      'queryMessage': '',
    });

    const query = value.toLowerCase();

    // Search by characters in email
    QueryDocument('users', [['searcharray', 'array-contains-any', [query]]])
    .then((users) => {

      setUserData({
        'searchResults': users,
        'queryMessage': `Found ${users.length} result(s)`
      });

    })
    .catch((error) => {

      console.log(error);
      return setUserData({ 'queryMessage': 'Oops something went wrong!' });

    });

  }

  // Function used to add a user
  function addUser(user) {

    // Remove the emailaddress from the 'removedUsers' array
    const removedUsers = userData.removedUsers.filter(object => object !== user.emailaddress);

    // Update the new users
    setUserData({
      'addedUsers': [...userData.addedUsers, user.emailaddress],
      'paginatedUsers': [...userData.paginatedUsers, user],
      'removedUsers': removedUsers,

      // Add Users Search
      'newUserInput': '',
      'queryMessage': '',
      'searchResults': [],
    });

  }

  // Function used to remove a user
  function removeUser(user) {

    // Remove the emailaddress from the 'addedUsers' array
    const addedUsers = userData.addedUsers.filter(object => object !== user.emailaddress);
    const paginatedUsers = userData.paginatedUsers.filter(object => object.emailaddress !== user.emailaddress);

    setUserData({
      'addedUsers': addedUsers,
      'removedUsers': [...userData.removedUsers, user.emailaddress],
      'paginatedUsers': paginatedUsers,

      // Add Users Search
      'newUserInput': '',
      'queryMessage': '',
      'searchResults': [],

    });

  }

  // Function used to save changes
  function handleSave() {

    // Hide Search Results
    setUserData({
      'newUserInput': '',
      'queryMessage': '',
      'searchResults': []      
    });

    const docWritePromises = [];

    // Add users
    userData.addedUsers.forEach((emailaddress) => {

      docWritePromises.push(
        AddRemoveDocumentArray(collectionid, documentid, fieldPath, emailaddress, 'add'),
      );

    });

    // Remove users
    userData.removedUsers.forEach((emailaddress) => {

      docWritePromises.push(
        AddRemoveDocumentArray(collectionid, documentid, fieldPath, emailaddress, 'remove'),
      );

    });

    // Settle promises
    Promise.all(docWritePromises)
    .then(() => {

      // Close Search Results
      setPaneOpen(false);

    }).catch((error) => {

      console.log('error', error);
      setPaneStatus('error');

    });

  }

  // Get the subscribed users
  function getCurrentUsers() {

    // Frontend paginates over 500 users
    if (userData.paginatedUsers.length < 500) {

      const currentUsers = [];

      // Extract only the values we need
      userData.paginatedUsers.forEach((user) => {

        currentUsers.push({
          'Email Address': user.emailaddress,
          'Given Name': user.givenname,
          'Surname': user.surname,
          'Business Unit': user.department,
          'Office': user.office,
          'City': user.city,
          'State': user.state,
          'Country': user.country,
        });

      });

      return setUserData({
        'disableUsageReportButton': false,
        'currentUsers': currentUsers,
      });

    };

    // ----------------------------------------------------------------------
    // OVER 500 users are subscribed! Fetch the data from Firestore directly!
    // ----------------------------------------------------------------------

    // Disable button
    setUserData({
      'disableUsageReportButton': true,
    });

    // Get the whole users collection (Querying for each user doc will cause a rate limit)
    GetCollection('users').then((firestoreUsers) => {

      const currentUsers = [];

      defaultUsers.forEach((emailaddress) => {

        const userCheck = firestoreUsers.filter((object) => object.emailaddress === emailaddress);

        if (userCheck.length === 0) return;

        currentUsers.push({
          'Email Address': userCheck[0].emailaddress,
          'Given Name': userCheck[0].givenname,
          'Surname': userCheck[0].surname,
          'Business Unit': userCheck[0].department,
          'Office': userCheck[0].office,
          'City': userCheck[0].city,
          'State': userCheck[0].state,
          'Country': userCheck[0].country,
        });

      });

      setUserData({
        'currentUsers': currentUsers,
        'disableUsageReportButton': false,
      });

    }).catch((error) => {

      console.log('Unable to extract all Lendlease Users', error);

    });

  }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

  // Onload > Get the user docs
  useEffect(() => {

    if (defaultUsers === undefined) return;

    const docPromises = [];

    // Loop through users > Get Document
    // https://masteringjs.io/tutorials/fundamentals/foreach-break
    defaultUsers.every((emailaddress, index) => {

      if (index >= 500) return false;

      docPromises.push(
        GetDocument('users', emailaddress.toLowerCase()),
      );

      return true;

    });

    // Resolve promises
    Promise.all(docPromises)
    .then((users) => {

      setUserData({
        'paginatedUsers': users,
        'currentUsers': [],
        'addedUsers': [],
        'removedUsers': [],
      });
      setPaneStatus('onload');

    })
    .catch((error) => {

      console.log('error', error);
      setPaneStatus('error');

    });

  }, [defaultUsers]);

  //------------------------------------------------------
  //  Edit User Modal
  //------------------------------------------------------

  if (!paneOpen) return null;

  // ---------------------------------------------------
  //  Pending
  // ---------------------------------------------------

  if (paneStatus === 'pending') {

    return (
      <div className='Pane-Background'>

        {/* Modal Container */}
        <dialog className='Application-Details-Pane-Container flex flex-col min-w-[950px] items-center'>

          <img className='w-[200px] self-center' alt='loading-circle-icon' src={LoadingIcon}></img>

          <button className='Primary-Button w-fit self-center' onClick={() => setPaneOpen(false)}>
            Cancel
          </button>

        </dialog>

      </div>
    )

  }

  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  else if (paneStatus === 'onload') {

    return (
      <div className='Pane-Background'>

        {/* Modal Container */}
        <dialog className='Application-Details-Pane-Container flex flex-col min-w-[950px]'>

          {/* ------------------------------------------------------ */}
          {/*  Header Container                                      */}
          {/* ------------------------------------------------------ */}

          <div className='flex flex-col'>

            {/* ------------------------------------------------------ */}
            {/*  Header                                                */}
            {/* ------------------------------------------------------ */}

            <div className='flex flex-col'>

              <div className='flex flex-row justify-between'>

                {/* Title */}
                <div className='flex flex-col gap-1'>
                  <h5>{title}</h5>
                  <span className='text-[#424242] text-sm'>
                    {vendorname}
                  </span>
                </div>

              </div>

              {/* Border - Bottom */}
              <hr className='my-2'></hr>

            </div>

            {/* ------------------------------------------------------ */}
            {/*  Add Users Search Bar                                  */}
            {/* ------------------------------------------------------ */}

            <div className='relative my-[10px]'>

              {/* Search Bar */}
              <div className='flex flex-row items-center'>

                {/* Search Input Field */}
                <input
                  className={
                    userData?.searchResults.length > 0 ?
                    'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] focus:outline-none'
                    :
                    'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] focus:outline-none'
                  }
                  type='text'
                  placeholder='Search user by email address'
                  value={userData?.newUserInput}
                  onChange={(evt) => {

                    const value = evt.target.value;
                    setUserData({ 'newUserInput': value });

                    // Hide search
                    if (value.length === 0) {

                      return setUserData({
                        'newUserInput': '',
                        'queryMessage': '',
                        'searchResults': []
                      })

                    }

                    // Trigger search
                    handleUserSearch(value);

                  }}
                  autoComplete='no'
                ></input>

                {/* Clear Search Button */}
                {
                  (userData?.newUserInput.length || userData?.searchResults.length) > 0 &&

                  <div className='animate-fadein  bg-[#F1F1F1] h-[45px] p-[15px] shadow-md border border-solid border-[#080808] border-l-[none] rounded-tl-none rounded-br-none rounded-tr-[5px] rounded-bl-none text-center align-center cursor-pointer'>

                    <img className='self-center' src={ClearSearch} alt='ClearSearch' onClick={() =>
                      setUserData({
                        'newUserInput': '',
                        'queryMessage': '',
                        'searchResults': []
                      })
                    }></img>

                  </div>
                }

              </div>

              {/* Search Results */}
              {
                (userData?.searchResults.length > 0 || userData.queryMessage.length > 0) &&
                (
                  <div className='absolute z-10 max-h-[350px] w-full overflow-y-auto bg-white shadow-lg border-[1px] border-[solid] border-[#D2D2D2]'>
                    {
                      userData?.searchResults.map((user, index) => (
                        <div key={index} className='border-b border-b-[#E1E1E1] last:border-0 hover:bg-[#F0F0F0]'>

                          {/* User Detail */}
                          {
                              // Check if the user exists in the list
                              (defaultUsers.filter((emailaddress) => emailaddress === user?.emailaddress).length > 0 || userData.addedUsers.filter((emailaddress) => emailaddress === user?.emailaddress).length > 0) ? 

                              // Disabled State
                              (
                                <div className='flex flex-row justify-between py-[10px] px-[15px] cursor-not-allowed'>
                                  <div className='flex flex-col'>
                                    <p className='m-0 font-medium text-[14px] text-[#C4C4C4]'>
                                      {user?.givenname} {user?.surname}
                                    </p>
                                    <p className='m-0 text-[14px] text-[#C4C4C4]'>
                                      {user?.emailaddress} {(user?.jobtitle && user?.department) && `(${user?.jobtitle} | ${user?.department})`}
                                    </p>
                                  </div>

                                  <img className='w-[28px] cursor-not-allowed' src={AddDisabled} alt='Add'></img>
                                </div>
                              ) 
                              : 

                              // Active State
                              (
                                <div className='flex flex-row justify-between py-[10px] px-[15px]'>
                                  <div className='flex flex-col'>
                                    <p className='m-0 font-medium text-[14px] text-[#424242] disabled:text-[#C4C4C4]'>
                                      {user?.givenname} {user?.surname}
                                    </p>
                                    <p className='m-0 text-[14px] text-[#424242]'>
                                      {user?.emailaddress} {(user?.jobtitle && user?.department) && `(${user?.jobtitle} | ${user?.department})`}
                                    </p>
                                  </div>
                                  
                                  <img className='w-[28px] cursor-pointer' src={Add} alt='Add' onClick={() => addUser(user)}></img>
                                </div>
                              )
                          }

                        </div>
                      ))
                    }

                    {/* Total Search Results */}
                    <div className='text-sm py-[10px] px-[15px]'>
                      {userData.queryMessage}
                    </div>

                  </div>
                )
              }

            </div>

          </div>

          {/* ------------------------------------------------------ */}
          {/*  Body Container                                        */}
          {/* ------------------------------------------------------ */}

          <div className='flex flex-col justify-between h-full overflow-hidden'>

            {/* ------------------------------------------------------ */}
            {/*  Table Container                                       */}
            {/* ------------------------------------------------------ */}

            <div className='Table-Container overflow-hidden'>

              {/* Table header (Grey Section) */}
              <div className='flex flex-row justify-between items-center py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                {/* Total */}
                <p className='m-0 font-medium '>
                  <span className='mr-[5px]'>Total:</span>
                  {
                    // More than 500 users exist!
                    defaultUsers.length > 500 ? (
                      <>
                        500 of {defaultUsers.length}
                      </>

                    ) : (

                      <>
                        {defaultUsers.length} of {defaultUsers.length}
                      </>
                    )
                  }
                </p>

                {/* ------------------------------------------------------ */}
                {/*  Download Subscribers Button                           */}
                {/* ------------------------------------------------------ */}

                {
                  userData.currentUsers.length === 0 ? (

                    // No data has been fetched from Firestore
                    <button onClick={() => getCurrentUsers()} disabled={userData.disableUsageReportButton} className='Secondary-Button flex flex-row self-start'>

                      <img className='mr-[5px] w-[21px]' src={DownloadIcon} alt='Download'></img>

                      {/* If user has clicked the button, show 'onload' or 'pending' UI */}
                      {
                        userData.disableUsageReportButton === false ? (
                          'Create Report'
                        ) : (
                          'Preparing...'
                        )

                      }

                    </button>

                  ) 
                  : 
                  (
                    // Firestore has returned the response --> Only show the download link button
                    <CSVLink className='no-underline' ref={csvLink} filename={`${title}-${Date.now()}.csv`} data={userData.currentUsers} target='_blank'>

                      <button disabled={userData.disableUsageReportButton} className='Secondary-Button flex flex-row self-start'>

                        <img className='mr-[5px] w-[21px]' src={DownloadIcon} alt='Download'></img>

                        {/* Name of the button */}
                        Download
                      </button>

                    </CSVLink>
                  )
                }

              </div>

              {/* Makes the table scrollable */}
              <div className='overflow-auto h-full'>

                <table className='table-fixed w-full'>

                  <colgroup>
                    <col span='1' style={{ width: '80px' }}></col>
                    <col span='1' style={{ width: '80px' }}></col>
                    <col span='1' style={{ width: '150px' }}></col>
                    <col span='1' style={{ width: '100px' }}></col>
                    <col span='1' style={{ width: '100px' }}></col>
                    <col span='1' style={{ width: '100px' }}></col>
                    <col span='1' style={{ width: '80px' }}></col>
                    <col span='1' style={{ width: '40px' }}></col>
                  </colgroup>

                  <thead>

                    {/* Sticky the header */}
                    <tr className='sticky top-0 drop-shadow-md bg-white border-b-[#D8D8D8] border-solid border-b rounded'>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>First Name</th>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Last Name</th>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Email</th>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Job Title</th>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Department</th>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>State</th>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Region</th>
                      <th className='py-[15px] px-[18px] font-semibold text-[#212529]'></th>
                    </tr>

                  </thead>

                  <tbody>
                    {
                      userData?.paginatedUsers?.length > 0 ?
                        (
                          userData?.paginatedUsers?.map((user, index) => (
                            <tr key={index} className='border-b-[#D8D8D8] border-solid border-b rounded last:border-none'>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis'> {user?.givenname} </td>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis'> {user?.surname} </td>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis'> {user?.emailaddress} </td>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis'> {user?.jobtitle} </td>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis'> {user?.department} </td>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis'> {user?.state} </td>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis'> {user?.country} </td>
                              <td className='py-[15px] px-[18px] font-normal break-words align-top whitespace-nowrap overflow-hidden text-ellipsis items-center'>
                                <div className='Cancel-Icon cursor-pointer w-[28] self-center' onClick={() => removeUser(user)}></div>
                              </td>
                            </tr>
                          ))

                        )
                        :
                        (
                          <tr className=''>
                            <td colSpan={8} className='py-4 px-3 font-normal break-words text-center align-top'> No users found.</td>
                          </tr>
                        )
                    }
                  </tbody>

                </table>

              </div>

            </div>

            {/* ------------------------------------------------------ */}
            {/*  Buttons Container                                     */}
            {/* ------------------------------------------------------ */}

            <div className='flex flex-row mt-3 gap-2'>

              <button className='Primary-Button' disabled={!(userData.addedUsers.length > 0 || userData.removedUsers.length > 0)} onClick={() => handleSave()}> 
                Save 
              </button>

              <button className='Secondary-Button' onClick={() => {

                // Clear Search Results
                setUserData({
                  'newUserInput': '',
                  'queryMessage': '',
                  'searchResults': []
                });

                // Close Side Pane
                setPaneOpen(false);

              }}> 
                Cancel 
              </button>

            </div>

          </div>

        </dialog>

      </div>
    )

  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------

  else if (paneStatus === 'error') {

    return (
      <div className='Modal-Background'>

        {/* Modal Container */}
        <dialog className='Application-Details-Pane-Container flex flex-col min-w-[950px] justify-center items-center'>

          <img className='my-2 w-[50px]' src={Error} alt='Error'></img>
          <h4>Oops! Something went wrong.</h4>
          <p className='leading-[1.7]'>
            There was an error loading this page.
          </p>
          <button className='Primary-Button w-fit' onClick={() => setPaneOpen(undefined)}>Back</button>

        </dialog>

      </div>

    )

  }

  //------------------------------------------------------
}
