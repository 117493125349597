//================================================================
//  Page: Application
//================================================================

//  Purpose: This page shows the current Application

//  Example:
//    <Application></Application>    

//================================================================


// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

// Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import TabView from '../../../../Components/TabView/TabView';
import BreadCrumbs from '../../../../Components/Breadcrumbs/BreadCrumbs';
import StatusLabel from '../../../../Components/StatusLabel/StatusLabel';

// Tabs
import Overview from './Tabs/Overview';
import Workflows from './Tabs/Workflows';
import Configuration from './Tabs/Configuration';
import Allocation from './Tabs/Allocation';
import BCP from './Tabs/BCP';
import SupportingDocumentation from './Tabs/SupportingDocumentation';

// Functions
import DocumentListener from '../../../../Library/DocumentListener';

// Images

// CSS


export default function Application() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const routerUseLocation = useLocation();
  const params = useParams();
  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState();

  // Holds the application document from 
  const [applicationDoc, setApplicationDoc] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Overview with the status of 'Active'
  useEffect(() => {

    const applicationId = params.id;

    if (applicationId === '') return;
    if (applicationId === null) return;

    function onLoadChange(document) {

      setApplicationDoc(document);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('applications', applicationId, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      pageStatus={pageStatus}
      header={
        <div className='Page-Header-Container'>

          <BreadCrumbs
            rootLinkName='Applications'
            rootLinkRoute={`/${routerUseLocation.pathname.split('/')[1]}?view=applications`}
            childLinkName={applicationDoc?.applicationname}
          ></BreadCrumbs>

          <div className='flex flex-row gap-4'>
            <p className='font-medium text-[20px] mb-0'>{applicationDoc?.applicationname}</p>
            <StatusLabel status={applicationDoc?.applicationstatus}></StatusLabel>
          </div>
        </div>
      }
      body={
        <div>

          <TabView
            defaultView={1}

            //------------------------------------------------------
            // Overview
            //------------------------------------------------------

            oneTitle={'Overview'}
            oneRoute={`/inventory/application/${params.id}?view=overview`}
            oneContent={
              <Overview></Overview>
            }

            //------------------------------------------------------
            // Configuration
            //------------------------------------------------------

            twoTitle={'Configuration'}
            twoRoute={`/inventory/application/${params.id}?view=configration`}
            twoContent={
              <Configuration></Configuration>
            }

            //------------------------------------------------------
            // Allocation
            //------------------------------------------------------

            threeTitle={'Allocation'}
            threeRoute={`/inventory/application/${params.id}?view=allocation`}
            threeContent={
              <Allocation></Allocation>
            }

            //------------------------------------------------------
            // Workflows
            //------------------------------------------------------

            fourTitle={'Workflows'}
            fourRoute={`/inventory/application/${params.id}?view=workflows`}
            fourContent={
              <Workflows></Workflows>
            }

            //------------------------------------------------------
            // BCP
            //------------------------------------------------------

            fiveTitle={'BCP'}
            fiveRoute={`/inventory/application/${params.id}?view=bcp`}
            fiveContent={
              <BCP></BCP>
            }

            //------------------------------------------------------
            // Supporting Documentation 
            //------------------------------------------------------

            sixTitle={'Supporting Documentation'}
            sixRoute={`/inventory/application/${params.id}?view=documentation`}
            sixContent={
              <SupportingDocumentation></SupportingDocumentation>
            }

          ></TabView>

        </div>
      }
    ></PageComponent>
  )
}
