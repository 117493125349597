//================================================================
//  Component: Add Supporting Documentation Pane
//================================================================

//  Purpose: This pane will allow users upload supporting documentation

//  Properties:
//    - application = {Object, current Application Doc}
//    - paneOpen = {useState, used to determine if the pane is visible}
//    - setPaneOpen = {useState, used to toggle the visibility of the pane}

//  Example:
//    <AddSupportingDocumentationModal
//      application={application}
//      paneOpen={paneOpen}
//      setPaneOpen={setPaneOpen}
//    ></AddSupportingDocumentationModal>    

//================================================================


//Libraries
import React, { useContext, useState, useReducer } from 'react';

//Contexts
import { GetUser } from '../../../../../../Library/GlobalContexts';

//Components
import AttachFile from '../../../../../../Components/AttachFile/AttachFile';

//Functions
import WriteDocument from '../../../../../../Library/WriteDocument';

//Images
import Error from '../../../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import Loading from '../../../../../../Components/Images/Image_Loading_Ripple.svg';


export default function AddSupportingDocumentationModal({
  application,
  paneOpen,
  setPaneOpen
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set pane status > 'onload', 'pending', 'error'
  const [paneStatus, setPaneStatus] = useState('onload');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store the form inputs
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'filedescription': '',

      // AttachFile component
      'fileUrl': '',
      'fileId': '',
      'fileName': '',
      'fileType': '',
      'documentsErrorMessage': '',

    }
  );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Submit Handler
  function handleSubmit() {

    if (formData.fileId === undefined) return;
    if (formData.fileId.length === 0) return;

    setPaneStatus('pending');

    // ----------------------------------------------
    // Update Applications document
    // ----------------------------------------------

    // Handle docs missing 'supportingdocumentation' key
    let supportingDocs = application.supportingdocumentation;
    if (application.supportingdocumentation === undefined) {

      supportingDocs = [];

    }

    // Merge new file into Array
    const document = {
      'supportingdocumentation': [
        ...supportingDocs,
        {
          'filename': formData.fileName,
          'filepath': `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/applications/${application.applicationid}/${formData.fileId}`,
          'filedescription': formData.filedescription,
          'createddate': new Date(),
          'createdbyemail': getUser.emailaddress,
          'createdbyname': `${getUser.givenname} ${getUser.surname}`,
        }
      ]
    };

    WriteDocument('applications', application.applicationid, document, true).then(() => {

      setPaneStatus('onload');

      setFormData({
        'filedescription': '',

        // AttachFile component
        'fileUrl': '',
        'fileId': '',
        'fileName': '',
        'fileType': '',
        'documentsErrorMessage': '',
      });

      setPaneOpen(false);

    }).catch((error) => {

      console.log(error);
      setPaneStatus('error');

    });

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (!paneOpen) return null;

  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  if (paneStatus === 'onload') {
    return (
      <div className='Modal-Background'>
        <dialog className='Modal-Container w-[600px]'>

          <div className='flex flex-col gap-3 justify-between'>

            {/* ------------------------------- */}
            {/*  Approver Form                  */}
            {/* ------------------------------- */}

            <div className='Pane-Form-Container'>

              {/* ===================================== */}
              {/*  Header                               */}
              {/* ===================================== */}

              <h4 className='text-[22px]'> Attach Document </h4>

              {/* ===================================== */}
              {/*  Form                                 */}
              {/* ===================================== */}

              <div className='flex flex-col mt-3'>

                {/* ============ Uplaod Document ============ */}
                <div className='FormComponent-Row p-0'>

                  <label className='font-medium'> File Description <span className='text-[#C4314B] h-0'><span className='text-[#C4314B]'>*</span></span></label>
                  <textarea
                    className='Input-Field-TextArea'
                    type='text'
                    placeholder='Application support documention, with the FY24...'
                    value={formData.filedescription}
                    onChange={(e) => setFormData({ 'filedescription': e.target.value })}
                  />

                </div>

                {/* ============ Upload Document ============ */}
                <div className='mt-2'>

                  <AttachFile
                    folderPath={`applications/${application.applicationid}`}
                    allowedFileTypes={['pdf']}
                    allowedFileSize={20000000}
                    attachedFile={formData}
                    setAttachedFile={setFormData}
                  ></AttachFile>

                </div>

              </div>

            </div>

            {/* ------------------------------- */}
            {/*  Submit Buttons                 */}
            {/* ------------------------------- */}

            <div className='flex flex-row gap-2 justify-self-end'>

              {/* Upload */}
              <button
                className='Primary-Button'
                onClick={() => handleSubmit()}
                disabled={(formData.fileName.length === 0 || formData.filedescription.length === 0)}
              >
                Upload
              </button>

              {/* Cancel */}
              <button className='Secondary-Button' onClick={() => {

                setFormData({
                  'filedescription': '',

                  // AttachFile component
                  'fileUrl': '',
                  'fileId': '',
                  'fileName': '',
                  'fileType': '',
                  'documentsErrorMessage': '',
                });

                setPaneOpen(false);

              }}>
                Cancel
              </button>

            </div>

          </div>

        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Pending
  // ---------------------------------------------------

  else if (paneStatus === 'pending') {
    return (
      <div className='Modal-Background'>
        <dialog className='Modal-Container w-[600px] h-[486px] flex justify-center items-center text-center'>
          <img className='' alt='loading-circle-icon' src={Loading}></img>
        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------

  else if (paneStatus === 'error') {
    return (
      <div className='Modal-Background'>

        {/* Pane Container */}
        <dialog className='Modal-Container w-[600px] h-[486px] justify-center items-center'>
          <div className='flex flex-col gap-2 w-full justify-center items-center'>

            <img className='my-2 w-[50px]' src={Error} alt='Error'></img>
            <h4 className='mb-0'>Oops! Something went wrong.</h4>
            <p className='text-center leading-[1.7]'>
              An error occurred while we processed your request.
            </p>
            <button
              className='Primary-Button'
              onClick={() => {

                setFormData({
                  'filedescription': '',

                  // AttachFile component
                  'fileUrl': '',
                  'fileId': '',
                  'fileName': '',
                  'fileType': '',
                  'documentsErrorMessage': '',
                });

                setPaneStatus('onload')

                setPaneOpen(false);
              }}

            >
              Close
            </button>

          </div>
        </dialog>
      </div>

    )
  }

  //------------------------------------------------------
}
