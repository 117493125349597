//================================================================
//  Component: Add Agreement
//================================================================

//  Purpose: Add a new agreement to the agreement collection

//  Properties:
//    - None

//  AddAgreement:
//    <AddAgreement ></AddAgreement>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Contexts
import { GetUser, SetToast } from '../../../../Library/GlobalContexts';

//Components
import LookUpRHF from '../../../../Components/LookUpRHF/LookUpRHF';

//Functions
import GetCollection from '../../../../Library/GetCollection';
import WriteDocument from '../../../../Library/WriteDocument';
import AddRemoveDocumentArray from '../../../../Library/AddRemoveDocumentArray';

//Images
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';

export default function AddAgreement() {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Open or Close the 'Add Agreement' side pane
    const [addAgreement, setAddAgreement] = useState(false);

    // List of applications in Firestore
    const [allApplications, setAllApplications] = useState([]);
    const [selectedApps, setSelectedApps] = useState([]);
    const [applicationsFiltered, setApplicationsFiltered] = useState([]);

    // Stores the current selected application in the application selector
    const [selectedApp, setSelectedApp] = useState();

    // Used to disable the add application button
    const [addAppButtonDisabled, setAddAppButtonDisabled] = useState(true);

    // Used to disable the remove application button
    const [removeAppButtonDisabled, setRemoveAppButtonDisabled] = useState(true);

    // Used to store agreement collection for validate if agreement exist
    const [agreements, setAgreements] = useState();

    // --------------------------------------------------
    //  Form State
    // --------------------------------------------------

    // https://react-hook-form.com/get-started
    const {

        register,
        setValue,
        handleSubmit,
        reset,
        clearErrors,
        setError,
        watch,
        formState: { isSubmitSuccessful, errors },

    } = useForm();

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        // Reset the Filter
        if (value.length === 0) {

            setApplicationsFiltered(allApplications);

        };

        setApplicationsFiltered(

            allApplications.filter((app) => app.applicationname.toLowerCase().includes(value.toLowerCase()))

        );

    };

    // Changes "10000" to "10,000"
    const formatNumber = (value) => {

        // If invalid > setValue and the onSubmit will display an error
        if (!/^[0-9,]{0,30}$/g.test(value)) return setValue('totalagreementamount', value);

        value = value.replaceAll(',', '');

        // If valid > format the number with commas 
        value = new Intl.NumberFormat('en-GB').format(value);

        setValue('totalagreementamount', value);
    }

    // Sort applications list
    function sortApplications(appList) {

        appList.sort((a, b) => {

            const nameA = a.applicationname.toUpperCase();
            const nameB = b.applicationname.toUpperCase();

            if (nameA < nameB) {

                return -1;

            }

            if (nameA > nameB) {

                return 1;

            }

            // names must be equal
            return 0;

        });

    };

    // Update the selected application > Disable add/remove button
    function updateSelectedApp(app, action) {

        setSelectedApp(app)

        if (action === 'add') {

            setAddAppButtonDisabled(false)

            setRemoveAppButtonDisabled(true)

        } else {

            setRemoveAppButtonDisabled(false)

            setAddAppButtonDisabled(true)

        }

    }

    // Add application
    function addApplication(application) {

        // Add application to selectedApps
        setSelectedApps([...selectedApps, application]);

        // Reset selected application
        setSelectedApp(undefined);

    }

    // Function to add all applications
    function addAllApplications() {

        setSelectedApps([...applicationsFiltered]);

        // Reset selected application
        setSelectedApp(undefined);

    }

    // Remove an application
    function removeFromSelected(application) {

        // Remove application from selectedApps
        selectedApps.splice(selectedApps.indexOf(application), 1);

        setSelectedApps([...selectedApps]);

        // Reset selected application
        setSelectedApp(undefined);

    }

    // Function to remove all applications
    function removeAllFromSelected() {

        // Remove all applications from selectedApps
        setSelectedApps([]);

        // Reset selected application
        setSelectedApp(undefined);

    }

    // Function add days to calendar date
    function addDays(dateString, days) {

        if (dateString !== undefined && dateString !== '') {
            return new Date(new Date(dateString)?.getTime() + days * 24 * 60 * 60 * 1000)?.toISOString().split('T')[0]    // add one day from agreement start date
        }
      
    }

    // Submit Handler
    const onSubmit = (formData) => {

        //=================================================
        //  Check for Duplicate Agreements
        //=================================================
        
        // Loop through agreements and check for duplicates
        const duplicateAgreements = agreements.filter((agr) => agr?.poanumber === formData?.poanumber);

        if (duplicateAgreements?.length > 0) {

            return setError('poanumber', {
                type: 'manual',
                message: 'This PoA number already exists'
            });

        }

        //=================================================
        //  Show Pending Toast
        //=================================================        
        
        setToast({
            'type': 'pending',
            'message': 'Preparing agreement',
        });

        const docPromises = [];

        //=================================================
        //  Create Search Array for Lookup Fields
        //=================================================

        const searchArray = [];
        let previousValue = '';
        [...formData?.poanumber]?.forEach((value, index) => {

            // Skip the first character
            if (index === 0) {

                previousValue = value;
                return searchArray.push(value.toLowerCase());

            }

            // Create an array of each character
            searchArray.push(`${previousValue}${value}`.toLowerCase());
            previousValue = `${previousValue}${value}`.toLowerCase();

        });

        //=================================================
        //  Add Agreement Document in Firestore
        //=================================================

        const agreementid = `${btoa(formData.vendorname)}-${Date.now()}`

        // Firestore Document
        const documentObject = {
            'agreementid': agreementid,
            'requestid': [],

            'poanumber': formData.poanumber,
            'searcharray': searchArray,
            'createddate': new Date(),
            'createdby': {
                'email': getUser.emailaddress,
                'givenname': getUser.givenname,
                'surname': getUser.surname
            },
            'lastmodifieddate': new Date(),
            'lastmodifiedby': {
                'email': getUser.emailaddress,
                'givenname': getUser.givenname,
                'surname': getUser.surname
            },

            'shortdescription': formData.shortdescription,
            'status': formData.status,
            'expirydate': '', // Timestamp added by the system 
            'projectcode': '',
            'tasknumber': '',
            'expendituretype': '',
            'commercialscope': '',
            'sponsorname': '',
            'buysellagreement': '',
            'agreementtype': '',
            'agreementstartdate': formData.agreementstartdate,
            'agreementenddate': formData.agreementenddate,
            'executionmethod': '',
            'executiondate': formData.executiondate,
            'lendleaseentity': '',
            'vendor': {
                'vendorname': formData.vendorname,
                'vendorid': formData['vendorname-vendorid'],
                'tradingas': '',
            },
            'currency': formData.contractcurrency,
            'estannualcontractvalue': '',
            'esttotalcontractvalue': '',
            'agreementterm': {
                'term': '',
                'period': ''
            },
            'totalagreementamount': formData.totalagreementamount,
            'autorenewals': '',
            'noticeperiodforautorenewaltermination': '',
            'extensionagreement': '',
            'noticeperiodforextension': '',
            'signedcontracts': [],
        };

        docPromises.push(
            WriteDocument('agreements', agreementid, documentObject, false)
        );

        //=================================================
        //  Update Application Document in Firestore
        //=================================================

        selectedApps.forEach((app) => {

            const docObject = {
                'agreementid': agreementid,
                'poanumber': formData?.poanumber
            };

            docPromises.push(
                AddRemoveDocumentArray(
                    'applications',
                    app?.applicationid,
                    'agreements',
                    docObject,
                    'add'
                ))
        });

        Promise.all(docPromises)
        .then(() => {

            // Reset form & close side pane
            setAddAgreement(false);

            // Show Success Toast
            setToast(
                {
                    'type': 'success',
                    'message':
                        <>
                            Agreement added. <a href={`https://inventory.store.lendlease.com/inventory/agreement/${agreementid}?view=overview`} target='_blank' rel='noopener noreferrer'>{formData.poanumber}</a>
                        </>
                }
            );

        })
        .catch((error) => {

            console.log('error', error);

            // Reset form & close side pane
            setAddAgreement(false);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to create agreement',
            });

        });

    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Onload > Get all applications and agreements
    useEffect(() => {

        // If Form Submission Successful > Reset Form
        if (isSubmitSuccessful) {

            reset();
            removeAllFromSelected();

        };

        // Onload > Get all applications and agreements
        const docPromises = [];

        // Get all applications
        docPromises.push(
            GetCollection('applications')
        );

        // Get all agreements
        docPromises.push(
            GetCollection('agreements'),
        );
        
        // Resolve Promises
        Promise.all(docPromises)
        .then((results) => {

            const allApplications = results[0];
            const allAgreements = results[1];

            const appnameid = [];

            allApplications.forEach((app) => {

                appnameid.push({
                    'applicationid': app.applicationid,
                    'applicationname': app.applicationname
                });

            });

            // Sort in alphabetical order
            sortApplications(appnameid);

            // Set States to sorted applications
            setAllApplications(appnameid);
            setApplicationsFiltered(appnameid);

            // Update Agreements
            setAgreements(allAgreements);

        })
        .catch((error) => {

            console.log('error', error);
      
        });

    // eslint-disable-next-line 
    }, [isSubmitSuccessful, reset]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (addAgreement === false) return (

        <button className='Primary-Button whitespace-nowrap' onClick={() => setAddAgreement(true)}>
            <strong>+</strong> Add Agreement
        </button>

    );

    return (
        <>

            {/* ======== Keeps the button is visible during slide animation ========= */}
            <button className='Primary-Button whitespace-nowrap' onClick={() => setAddAgreement(true)}>
                <strong>+</strong> Add Agreement
            </button>

            {/* =========================================================== */}
            {/*  Add Agreement Pane                                         */}
            {/* =========================================================== */}

            <div className='Pane-Background'>

                <dialog className='Pane-Container'>

                    {/*  ========= Add Agreement Form * ========= */}
                    <form className='flex flex-col justify-between w-full' onSubmit={handleSubmit(onSubmit)}>

                        <div className='w-full overflow-x-auto'>

                            {/* Header */}
                            <div className='AddAgreement-Header'>
                                <h4> Add Agreement </h4>
                            </div>

                            <div className='FormComponent'>

                                {/*  ========= 1. PoA Number * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 1. PoA Number <span className='text-[#C4314B]'>*</span></label>
                                    <input
                                        className={errors.poanumber ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        type='text'
                                        placeholder='Enter PoA Number (FY23-00001)'
                                        autoComplete='no'
                                        {
                                            ...register('poanumber', {
                                                required: 'This is a required field.'
                                            })
                                        }
                                    />
                                    {errors.poanumber && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.poanumber?.message}</p>}
                                </div>

                                {/*  ========= 2. Agreement Status * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 2. Status <span className='text-[#C4314B]'>*</span></label>
                                    <select
                                        className='Input-Field-Select'
                                        {...register(
                                            'status', {
                                            required: 'This is a required field.'
                                        })
                                        } >
                                        <option value='Active'>Active</option>
                                        <option value='Draft'>Draft</option>
                                        <option value='Expired'>Expired</option>
                                        <option value='Cancelled'>Cancelled</option>
                                    </select>
                                </div>

                                {/*  ========= 3. Short Description * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 3. Short Description <span className='text-[#C4314B]'>*</span></label>
                                    <textarea
                                        className={errors.shortdescription ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                        type='text'
                                        placeholder='Enter description'
                                        autoComplete='no'
                                        {
                                            ...register('shortdescription', {
                                                required: 'This is a required field.'
                                            })
                                        }
                                    ></textarea>
                                    {errors.shortdescription && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.shortdescription?.message}</p>}

                                </div>

                                {/*  ========= 4. Vendor Name * ========= */}
                                <div className='FormComponent-Row'>

                                    <label className='font-medium'>4. Vendor Name <span className='text-[#C4314B]'>*</span></label>
                                    <LookUpRHF
                                        fieldId={'vendorname'}
                                        collectionId={'vendors'}
                                        dataField={'vendorname'}
                                        placeholder={'Search vendor by name'}
                                        required={true}
                                        register={register}
                                        setValue={setValue}
                                        errors={errors.vendorname}
                                        dataFieldSecondary={'tradingas'}
                                        dataFieldTertiary={'vendorid'}
                                        additionalQueries={['vendorstatus', '==', 'Active']}
                                        createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <a href='http://inventory.store.lendlease.com/inventory?view=vendors' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
                                        clearErrors={clearErrors}
                                    ></LookUpRHF>

                                </div>

                                {/*  ========= 5. Start Date * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 5. Start Date <span className='text-[#C4314B]'>*</span></label>
                                    <input
                                        className={errors.agreementstartdate ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        type='date'
                                        {...register(
                                            'agreementstartdate', {
                                            required: 'This is a required field.'
                                        })
                                        } />
                                    {errors.agreementstartdate && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.agreementstartdate?.message}</p>}
                                </div>

                                {/*  ========= 6. End Date * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 6. End Date <span className='text-[#C4314B]'>*</span></label>
                                    <input
                                        className={errors.agreementenddate ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        type='date'
                                        {...register(
                                            'agreementenddate', {
                                            required: 'This is a required field.'
                                        })
                                        } 
                                        min={
                                            addDays(watch('agreementstartdate'), 1)
                                        } 
                                        
                                    />
                                    {errors.agreementenddate && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.agreementenddate?.message}</p>}

                                </div>

                                {/*  ========= 7. Execution Date * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 7. Execution Date <span className='text-[#C4314B]'>*</span></label>
                                    <input
                                        className={errors.executiondate ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('executiondate', {
                                            required: 'This field is required',
                                        })}
                                        type='date'
                                    ></input>
                                    {errors.executiondate && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.executiondate?.message}</p>}
                                </div>

                                {/*  ========= 8. Total Agreement Amount (excl. tax) * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 8. Total Agreement Amount (excl. tax) <span className='text-[#C4314B]'>*</span></label>
                                    <input
                                        className={errors.totalagreementamount ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        type='text'
                                        autoComplete='no'
                                        placeholder='10,000'
                                        {...register(
                                            'totalagreementamount', {
                                            required: 'This is a required field.',
                                            pattern: {
                                                value: /^\d*(,\d*)*$/,
                                                message: 'Invalid number',
                                            },
                                            onChange: (e) => {formatNumber(e.target.value); console.log(errors.totalagreementamount)}
                                        })
                                        } />
                                    {errors.totalagreementamount && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.totalagreementamount?.message}</p>}

                                </div>

                                {/*  ========= 9. Contract Currency * ========= */}
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 9. Currency <span className='text-[#C4314B]'>*</span></label>
                                    <select
                                        className={errors.contractcurrency ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                                        {...register(
                                            'contractcurrency', {
                                            required: 'This is a required field.'
                                        })
                                        } >
                                        <option value='AUD'>AUD</option>
                                        <option value='GBP'>GBP</option>
                                        <option value='SGD'>SGD</option>
                                        <option value='USD'>USD</option>
                                    </select>
                                </div>

                            </div>

                            {/*  ========= 10. Applications under this agreement? * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> 10. Applications under this agreement</label>

                                {/* Search Box */}
                                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#D8D8D8] w-full'>
                                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                    <label htmlFor='searchInput'>
                                        <input
                                            id='searchInput'
                                            className='border-none h-[32px] p-0 m-0 outline-none bg-white'
                                            type='text'
                                            placeholder='Search'
                                            onChange={(e) => handleSearch(e.target.value)}
                                            autoComplete='no'
                                        ></input>
                                    </label>
                                </div>

                                {/* Registered Application Selector */}
                                <div className="Registered-Applications-Editor-Container">

                                    {/* Header */}

                                    <div className="Applications-Available-Header">
                                        Applications
                                    </div>

                                    <div className="Applications-Selector-Header"></div>

                                    <div className="Applications-Added-Header">
                                        Applications Added
                                    </div>

                                    {/* Applications Available */}
                                    <div className="Applications-Available-Body">
                                        {
                                            // applicationsFiltered && sortApplications(applicationsFiltered);
                                            applicationsFiltered?.map((choice, index) => (

                                                selectedApps.filter((app) => app.applicationid === choice.applicationid).length > 0 ? (

                                                    null

                                                )
                                                    :
                                                    (

                                                        <div key={index} className={selectedApp === choice ? 'Registered-Application-Label-Active' : 'Registered-Application-Label'}
                                                            onClick={() => updateSelectedApp(choice, "add")}
                                                        >
                                                            {choice.applicationname}
                                                        </div>
                                                    )

                                            ))
                                        }
                                    </div>

                                    {/* Selector Buttons*/}
                                    <div className="Applications-Selector-Body">

                                        {/* Add Button */}
                                        <div className="button-tooltip">
                                            <button className='Primary-Button' style={{ padding: "5px 20px" }} disabled={selectedApp === undefined ? true : addAppButtonDisabled}
                                                onClick={() => addApplication(selectedApp)}
                                            > {'>'} </button>
                                            <span className="tooltiptext"> Add choice </span>
                                        </div>

                                        {/* Add All Button */}
                                        <div className="button-tooltip">
                                            <button className='Primary-Button' disabled={selectedApps.length === applicationsFiltered.length} style={{ padding: "5px 20px" }}
                                                onClick={() => addAllApplications()}
                                            > {'>|'} </button>
                                            <span className="tooltiptext"> Add all choices </span>
                                        </div>

                                        {/* Remove Button */}
                                        <div className="button-tooltip">
                                            <button className='Primary-Button' style={{ padding: "5px 20px" }} disabled={selectedApp === undefined ? true : removeAppButtonDisabled}
                                                onClick={() => removeFromSelected(selectedApp)}
                                            > {'<'} </button>
                                            <span className="tooltiptext"> Remove choice </span>
                                        </div>

                                        {/* Remove All Button */}
                                        <div className="button-tooltip">
                                            <button className='Primary-Button' disabled={selectedApps.length === 0} style={{ padding: "5px 20px" }}
                                                onClick={() => removeAllFromSelected()}
                                            > {'|<'} </button>
                                            <span className="tooltiptext"> Remove all choices </span>
                                        </div>


                                    </div>

                                    {/* Applications Added */}
                                    <div className="Applications-Added-Body">
                                        {
                                            selectedApps?.map((choice, index) => (
                                                <div key={index} className={selectedApp === choice ? 'Registered-Application-Label-Active' : 'Registered-Application-Label'}
                                                    onClick={() => updateSelectedApp(choice, "remove")}
                                                >
                                                    {choice.applicationname}
                                                </div>
                                            ))
                                        }
                                    </div>

                                </div>

                            </div>

                        </div>

                        {/*  ========= Button Container ========= */}
                        <div className='flex flex-row gap-2 border-solid border-t-2 pt-3'>

                            {/* Submit */}
                            <button
                                className='Primary-Button whitespace-nowrap'
                                disabled={false}
                                type='submit'
                                value='submit'
                            >
                                Submit
                            </button>

                            {/* Cancel */}
                            <button className='Secondary-Button whitespace-nowrap' onClick={() => {

                                // Close Side Pane
                                setAddAgreement(false);
                                removeAllFromSelected();
                                reset();

                            }}>
                                Cancel
                            </button>

                        </div>

                    </form>

                    {errors.applications && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.applications?.message}</p>}


                </dialog >
            </div >
        </>
    )
}

