//================================================================
//  Tab: Cyber
//================================================================

//  Purpose: This tab displays all the cyber information related to this agreement

//  Example:
//    <Cyber></Cyber>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images


export default function Cyber() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');
  
    // Holds the application document from 
    const [agreementDoc, setAgreementDoc] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Query Listener
    // - Get Firestore document
    useEffect(() => {

      const agreementid = params.id;

      if (agreementid === '') return;
      if (agreementid === null) return;

      function onLoadChange(document){  

        setAgreementDoc(document);
        setPageStatus('onload');

      }
      
      function onError(error){

        console.log(error);
        setPageStatus('error-fatal');
      
      }
      
      const unsubscribe = DocumentListener('agreements', agreementid, onLoadChange, onLoadChange, onError);
      
      return () =>{
        unsubscribe();
      };
        
    // eslint-disable-next-line
    }, [getUser]);
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='flex flex-col w-[50%] px-[20px]'>

            {/* Solution Assessment Results Recommendations */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'solutionassessmentresultsrecommendations'}
              label={<label className='font-medium'>Solution Assessment Results Recommendations</label>}
              defaultValue={agreementDoc?.solutionassessmentresultsrecommendations}
              placeholder={'The applications high-level summary of any risks and recommendations'}
              type={'multiline'}
              disabled={false}
            ></LiveField>   

            {/* Business Criticality */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'businesscriticality'}
              label={<label className='font-medium'>Business Criticality</label>}
              defaultValue={agreementDoc?.businesscriticality}
              type={'multiselect'}
              options={[
                'Critical',
                'High',
                'Medium',
                'Low',
                'N/A',
              ]}
              disabled={false}
            ></LiveField>    

            {/* Vendor Cyberassessment Score */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'vendorcyberassessmentscore'}
              label={<label className='font-medium'>Vendor Cyber Assessment Score</label>}
              defaultValue={agreementDoc?.vendorcyberassessmentscore}
              type={'choice'}
              options={[
                'A',
                'B',
                'C',
                'D',
                'F',
                'Not Reviewed',
                'N/A',
              ]}
              disabled={false}
            ></LiveField>    

            {/* Last Cyber Assessment Date */}
            <LiveField
              collectionid={'agreements'}
              documentid={agreementDoc?.agreementid}
              fieldpath={'lastcyberassessmentdate'}
              label={<label className='font-medium'>Last Cyber Assessment Date</label>}
              defaultValue={agreementDoc?.lastcyberassessmentdate}
              type={'date-string'}
              disabled={false}
            ></LiveField>  

          </div>
        }
      ></PageComponent>
    )
}
