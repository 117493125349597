//================================================================
//  Page: My Tasks
//================================================================

//  Purpose: This page shows all commercial tasks assigned to the current user

//  Example:
//    <Inventory></Inventory>    

//================================================================


// Libraries
import React from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';

// Tabs
import Agreements from './Tabs/Agreements';
import ApplicationsTab from './Tabs/Applications';
import TransformationRegister from './Tabs/TransformationRegister';
import Vendors from './Tabs/Vendors';

// Functions

// Images

// CSS
import './Inventory.css';


export default function Inventory() {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      body={
        <TabView
          defaultView={1}

          //------------------------------------------------------
          // Agreements
          //------------------------------------------------------

          oneTitle={'Agreements'}
          oneRoute={`/inventory?view=agreements`}
          oneContent={
            <Agreements></Agreements>
          }

          //------------------------------------------------------
          // Applications
          //------------------------------------------------------

          twoTitle={'Applications'}
          twoRoute={`/inventory?view=applications`}
          twoContent={
            <ApplicationsTab></ApplicationsTab>
          }

          //------------------------------------------------------
          // Processes transformation list​
          //------------------------------------------------------

          threeTitle={'Process Transform Register'}
          threeRoute={`/inventory?view=transformationregister`}
          threeContent={
            <TransformationRegister></TransformationRegister>
          }

          //------------------------------------------------------
          // Vendors​
          //------------------------------------------------------

          fourTitle={'Vendors'}
          fourRoute={`/inventory?view=vendors`}
          fourContent={
            <Vendors></Vendors>
          }

        ></TabView>
      }
    ></PageComponent>
  )
}
