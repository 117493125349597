//================================================================
//  Tab: Vendors
//================================================================

//  Purpose: This tab displays all 'Vendors'

//  Example:
//    <Vendors></Vendors>    

//================================================================

// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { SetSortFilters } from '../../../Library/GlobalContexts';

// Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import AddVendor from './SidePanes/AddVendor';
import TableHeader from '../../../Components/SortFilter/TableHeader';
import FilterTags from '../../../Components/SortFilter/FilterTags';
import FilterByPane from '../../../Components/SortFilter/FilterByPane';

// Functions
import QueryListener from '../../../Library/QueryListener';

// Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';


export default function Vendors() {

    //------------------------------------------------------
    //  useContext & React Router
    //------------------------------------------------------

    const navigate = useNavigate();

    const setSortFilters = useContext(SetSortFilters);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Current Filter > 'Active', 'Decommissioned' & 'All'
    const [currentFilter, setCurrentFilter] = useState('Active');

    // Search Input 
    const [searchInput, setSearchInput] = useState('');

    // Stores all vendors
    const [vendors, setVendors] = useState([]);
    const [vendorsFiltered, setVendorsFiltered] = useState([]);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        // Clear ALL filters & sorting
        if (value.length > 0 && value.length < 2) {

            setSortFilters({

                // Sorting
                'sortBy': '', // vendor.vendorname
                'sortDirection': '', // ascending & descending
                'sortDataType': '', // '' or 'number'

                // Filtering (Map of objects)
                'filters': {},

                // Sidepane for selecting filterby content
                'filterByPane': {
                    'columnname': '', // Vendor Name
                    'columnid': '', // vendor.vendorname
                }

            });

        }

        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {

            setVendorsFiltered(vendors);

        };

        // Search Filter
        // 1. vendorname
        // 2. tradingas
        // 3. registrationnumber
        const searchResults = vendors.filter((object) =>
            object?.vendorname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.tradingas?.toLowerCase().includes(value.toLowerCase()) ||
            object?.registrationnumber.toLowerCase().includes(value.toLowerCase())
        );

        // Save the filtered search results to the useState
        setVendorsFiltered(searchResults);

    };

    // Create data format for CSV Export
    function getDataObject(vendors) {

        const data = [];

        vendors.forEach((vendor) => {

            data.push({
                'Name': vendor.vendorname,
                'Registration Number': vendor.registrationnumber,
                'Trading as Name': vendor.tradingas,
                'Street': vendor.address?.street,
                'City': vendor.address?.city,
                'Country': vendor.address?.country,
                'Postal Code': vendor.address?.postcode,
                'Website': vendor.website,
                'Status': vendor.vendorstatus,
            })
        })

        return data;
    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Query Listener
    // - Get all vendors with the status of 'Active'
    useEffect(() => {

        if (currentFilter === undefined) return;

        function onLoad(documents) {

            setVendors(documents);
            setVendorsFiltered(documents);
            
            setPageStatus('onload');

        }

        function onChange(documents) {

            setVendors(documents);

        }

        function onError(error) {

            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = QueryListener('vendors', currentFilter === 'All' ? [] : [['vendorstatus', '==', currentFilter]], onLoad, onChange, onError, ['vendorname', 'asc']);

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, [currentFilter]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <PageComponent
            status={pageStatus}
            body={
                <div className='flex flex-col px-[15px] gap-[20px] w-[calc(100vw-40px)] max-h-[calc(100vh-220px)] overflow-hidden'>

                    {/* ----------------------------------- */}
                    {/*  Search & Filters                   */}
                    {/* ----------------------------------- */}

                    <div className='flex flex-row justify-between items-center gap-3'>

                        {/* ======== Status (Buttons) ========== */}
                        <div className='flex flex-row items-center gap-2'>

                            {/* Active */}
                            <button className={currentFilter === 'Active' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Active')}>
                                Active
                            </button>

                            {/* Decommissioned */}
                            <button className={currentFilter === 'Decommissioned' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Decommissioned')}>
                                Decommissioned
                            </button>

                            {/* All */}
                            <button className={currentFilter === 'All' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('All')}>
                                All
                            </button>

                        </div>

                        {/* ======== Search, Add Vendor & Download ========== */}
                        <div className='flex flex-row justify-end items-center gap-2'>

                            {/* ======== Search Bar ======== */}
                            <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                <label htmlFor='searchInput'>
                                    <input
                                        id='searchInput'
                                        className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                        type='text'
                                        placeholder='Search'
                                        onChange={(e) => handleSearch(e.target.value)}
                                        value={searchInput}
                                        autoComplete='no'
                                    ></input>
                                </label>
                            </div>

                            {/* + Add Vendor */}
                            <AddVendor></AddVendor>

                            {/* Download Report */}
                            <ExportToCSV
                                filename={`Vendors-${Date.now()}`}
                                data={getDataObject(vendorsFiltered)}
                            ></ExportToCSV>

                        </div>

                    </div>

                    {/* ----------------------------------- */}
                    {/*  Table Container                    */}
                    {/* ----------------------------------- */}

                    <FilterTags
                        tableArray={vendors}
                        setFilteredTableArray={setVendorsFiltered}
                    ></FilterTags>

                    <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

                        {/* ======== Header ========== */}
                        <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                            <label className='font-semibold self-center'>
                                Total: {vendorsFiltered.length} of {vendors.length}
                            </label>

                        </div>

                        {/* ======== Table Scrollable ========== */}
                        <div className='w-full overflow-auto'>

                            <FilterByPane
                                tableArray={vendors}
                            ></FilterByPane>

                            <table className='w-full'>
                                <thead>
                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2 z-10 box-border overflow-visible'>

                                        <TableHeader
                                            column='Vendor'
                                            objectKey='vendorname'
                                            sticky={true}
                                        ></TableHeader>
                                        <TableHeader
                                            column='Registration Number'
                                            objectKey='registrationnumber'
                                        ></TableHeader>
                                        <TableHeader
                                            column='Trading as Name'
                                            objectKey='tradingas'
                                        ></TableHeader>
                                        <th className={`py-[15px] px-[20px] font-semibold whitespace-nowrap`}>
                                            Street
                                        </th>
                                        <TableHeader
                                            column='City'
                                            objectKey='address.city'
                                        ></TableHeader>
                                        <TableHeader
                                            column='Country'
                                            objectKey='address.country'
                                        ></TableHeader>
                                        <TableHeader
                                            column='Postal Code'
                                            objectKey='address.postcode'
                                            dataType='number'
                                        ></TableHeader>
                                        <TableHeader
                                            column='Website'
                                            objectKey='website'
                                        ></TableHeader>
                                        <th className={`py-[15px] px-[20px] font-semibold whitespace-nowrap`}>
                                            Status
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // No vendors found
                                        vendorsFiltered.length === 0 ?
                                            (
                                                <tr>
                                                    <td colSpan={13}>
                                                        <div className='text-center p-[2%]'>
                                                            No vendors found.
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            :
                                            (

                                                // Map in each commercial item
                                                vendorsFiltered.map((vendor, index) => (
                                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>
                                                        <td className='align-top py-[15px] px-[20px] border-solid left-0 drop-shadow-md bg-white sticky font-medium cursor-pointer hover:underline max-w-[30ch] whitespace-nowrap overflow-hidden text-ellipsis' onClick={() => navigate(`/inventory/vendor/${vendor?.vendorid}?view=overview`)}>{vendor?.vendorname}</td>
                                                        <td className='align-top py-[15px] px-[20px]'>{vendor?.registrationnumber}</td>
                                                        <td className='align-top py-[15px] px-[20px]'>{vendor?.tradingas}</td>
                                                        <td className='align-top py-[15px] px-[20px]'>{vendor?.address.street}</td>
                                                        <td className='align-top py-[15px] px-[20px]'>{vendor?.address.city}</td>
                                                        <td className='align-top py-[15px] px-[20px]'>{vendor?.address.country}</td>
                                                        <td className='align-top py-[15px] px-[20px]'>{vendor?.address.postcode}</td>
                                                        <td className='align-top py-[15px] px-[20px] whitespace-nowrap text-ellipsis max-w-[40ch] overflow-hidden'><a target="_blank" rel="noopener noreferrer" href={vendor?.website.replace(/^https:\/\//, '').replace(/.+/g, 'https://$&')}>{vendor?.website.replace(/^https:\/\//, '').replace(/.+/g, 'https://$&')}</a></td>
                                                        <td className='align-top py-[15px] px-[20px]'><StatusLabel status={vendor?.vendorstatus}></StatusLabel></td>
                                                    </tr>
                                                ))
                                            )
                                    }
                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>
            }
        ></PageComponent>
    )
}
