//================================================================
//  Component: Agreements Pane
//================================================================

//  Purpose: See list of Agreements in an Application

//  Properties:
//    - applicationDoc = {useState, contains the application document}
//    - setApplicationDoc = {useState used to determine if the side pane is open/closed}

//  AgreementsPane:
//    <AgreementsPane
//      applicationDoc={agreement}
//      setApplicationDoc={setAgreementPane}
//    ></AgreementsPane>    

//================================================================

//Libraries
import React, { useContext, useEffect, useReducer, useState } from 'react';

//Contexts
import { SetToast } from '../../Library/GlobalContexts';

//Components
import Tooltip from '../Tooltip/Tooltip';
import StatusLabel from '../StatusLabel/StatusLabel';
import ExportToCSV from '../ExportToCSV/ExportToCSV';

//Functions
import GetCollection from '../../Library/GetCollection';
import AddRemoveDocumentArray from '../../Library/AddRemoveDocumentArray';

//Images
import IconSearch from '../Images/Icon_Search_Grey.svg';
import InfoIcon from '../Images/Icon_Info_Blue.svg';
import ClearSearch from '../Images/Icon_Clear_Grey.svg';
import Add from '../Images/Icon_Add_Teal.svg';
import AddDisabled from '../Images/Icon_AddDisabled_Teal.svg';
import LoadingIcon from '../Images/Image_Loading_Ripple.svg';


export default function AgreementsPane({
    applicationDoc,
    setApplicationDoc,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store details about the agreements
    const [agreementData, setAgreementData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'allAgreements': [],                      // All agreements from the 'agreements' collection
            'linkedAgreements': [],                   // Agreements linked to this application
            'filteredAgreements': [],                 // List of agreements filtered by search
            'addedAgreements': [],                    // Agreements added to the application
            'removedAgreements': [],                  // Agreements removed from the application

            // Add Agreements Search
            'addAgreementSearchInput': '',
            'addAgreementSearchResults': [],

            // Table Search
            'tableSearchInput': ''
        }
    );

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to determine the status of the pane > 'pending', 'onload'
    const [paneStatus, setPaneStatus] = useState('pending');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search to add an agreement to the application
    // Search by: 
    //  1. PoA Number
    //  2. Vendor Name
    //  3. Vendor Trading As Name
    const handleAllAgreementsSearch = (value) => {

        const searchResults = agreementData?.allAgreements?.filter((object) =>
            object?.poanumber?.toLowerCase().includes(value.toLowerCase()) ||
            object?.vendor?.vendorname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.vendor?.tradingas?.toLowerCase().includes(value.toLowerCase())
        );

        // Turn Dropdown on if field is not empty
        setAgreementData({
            'addAgreementSearchResults': searchResults
        });

    };

    // Handles Table Search 
    const handleTableSearch = (value) => {

        setAgreementData({ 'tableSearchInput': value });

        // Reset the Filter
        if (value.length === 0) {
            setAgreementData({
                'filteredAgreements': agreementData?.linkedAgreements
            });
        };

        // Search Filter
        //  1. POA number
        //  2. Vendor Name
        //  3. Vendor Trading As Name
        const searchResults = agreementData?.linkedAgreements?.filter((object) =>
            object?.poanumber?.toLowerCase().includes(value.toLowerCase()) ||
            object?.vendor?.vendorname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.vendor?.tradingas?.toLowerCase().includes(value.toLowerCase())
        );

        setAgreementData({
            'filteredAgreements': searchResults
        });

    };

    // Submits changes to the table
    const handleSubmit = () => {

        const docWritePromises = [];

        // Add Agreements
        agreementData?.addedAgreements?.forEach((object) => {

            const agreementObject = {
                'agreementid': object.agreementid,
                'poanumber': object.poanumber
            };

            docWritePromises.push(
                AddRemoveDocumentArray('applications', applicationDoc?.applicationid, 'agreements', agreementObject, 'add'),
            );

        })


        // Remove Agreements
        agreementData?.removedAgreements?.forEach((object) => {

            const agreementObject = {
                'agreementid': object.agreementid,
                'poanumber': object.poanumber
            };

            docWritePromises.push(
                AddRemoveDocumentArray('applications', applicationDoc?.applicationid, 'agreements', agreementObject, 'remove'),
            );

        });

        // Settle promises
        Promise.all(docWritePromises)
        .then(() => {

            // Reset and Close Side Pane
            setPaneStatus('pending');
            setApplicationDoc(undefined);
            setAgreementData({
                'allAgreements': [],
                'linkedAgreements': [],
                'filteredAgreements': [],
                'addedAgreements': [], 
                'removedAgreements': [],
    
                // Add Agreements Search
                'addAgreementSearchInput': '',
                'addAgreementSearchResults': [],
    
                // Table Search
                'tableSearchInput': ''
            }); 

        })
        .catch((error) => {
    
            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to save changes',
            }); 

        });
      
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Onload
    // - Get all agreements
    useEffect(() => {

        // Close the pane if there are issues
        if (applicationDoc === undefined) return;
        if (paneStatus === 'onload') return;

        GetCollection('agreements')
        .then((document) => {

            // Use Applications document to get Agreement applications
            const tempAppList = [];

            applicationDoc?.agreements.forEach(object => {

                const filteredApps = document.filter((app) => app.poanumber === object.poanumber);
                tempAppList.push(...filteredApps);

            });

            setAgreementData({
                'allAgreements': document,
                'linkedAgreements': tempAppList, 
                'filteredAgreements': tempAppList
            });

            setPaneStatus('onload');

        })
        .catch((error) => {

            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to get agreements',
            });

        });

    // eslint-disable-next-line
    }, [applicationDoc]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (applicationDoc === undefined) return null; // Can I delete this?

    //====================================================
    //  Pending
    //====================================================

    if (paneStatus === 'pending') {
        return (
            <div className='Pane-Background'>
                <dialog className='Application-Details-Pane-Container flex flex-col gap-2 pb-0 items-center'>

                    <img className='w-[200px] self-center' alt='loading-circle-icon' src={LoadingIcon}></img>

                    <button className='Primary-Button w-fit self-center' onClick={() => {

                        setApplicationDoc(undefined);
                        setPaneStatus('pending');

                    }}>
                        Cancel
                    </button>

                </dialog >
            </div>
        )
    }

    //====================================================
    //  Onload
    //====================================================

    return (
        <div className='Pane-Background'>
            <dialog className='Application-Details-Pane-Container flex flex-col gap-2 pb-0'>

                {/* ------------------------------------------------------ */}
                {/*  Header Container                                      */}
                {/* ------------------------------------------------------ */}

                <div className='flex flex-col'>

                    {/* ------------------------------------------------------ */}
                    {/*  Header                                                */}
                    {/* ------------------------------------------------------ */}

                    <div className='flex flex-col gap-1'>

                        {/* Title */}
                        <h5> Agreements | {applicationDoc?.applicationname} </h5>
                        <span className='text-[#424242] text-sm' hidden={applicationDoc?.vendorname === undefined || applicationDoc?.vendorname?.length === 0}>
                            {applicationDoc?.vendorname}
                        </span>

                        {/* Border - Bottom */}
                        <hr className='my-2'></hr>

                    </div>

                    {/* ------------------------------------------------------ */}
                    {/*  Add Application Search Bar                            */}
                    {/* ------------------------------------------------------ */}           

                    <div className='relative my-[10px]'>
                        
                        {/* Search Bar */}
                        <div className='flex flex-row items-center'>
                            
                            {/* Search Input Field */}
                            <input
                                className={
                                    agreementData?.addAgreementSearchResults?.length > 0 ?
                                    'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] focus:outline-none'
                                    :
                                    'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] focus:outline-none'
                                }
                                type='text'
                                placeholder='Search existing agreements to add to application'
                                autoComplete='no'
                                onChange={(e) => {

                                    const value = e.target.value;
                                    setAgreementData({ 'addAgreementSearchInput': value });

                                    // Hide search
                                    if (value.length === 0) {

                                        return setAgreementData({
                                            'addAgreementSearchInput': '',
                                            'addAgreementSearchResults': [],
                                        });

                                    }

                                    // Trigger Search
                                    handleAllAgreementsSearch(value);

                                }}
                                value={agreementData?.addAgreementSearchInput}
                            ></input>

                            {/* Clear Search Button */}
                            {
                                agreementData?.addAgreementSearchResults?.length > 0 &&
                                <div className='animate-fadein  bg-[#F1F1F1] h-[45px] p-[15px] shadow-md border border-solid border-[#080808] border-l-[none] rounded-tl-none rounded-br-none rounded-tr-[5px] rounded-bl-none text-center align-center cursor-pointer'>
                                    <img className='self-center' src={ClearSearch} alt='ClearSearch' onClick={() => setAgreementData({
                                        'addAgreementSearchInput': '',
                                        'addAgreementSearchResults': [],
                                    })}></img>
                                </div>
                            }

                        </div>

                        {/* Search Results */}
                        {
                            agreementData?.addAgreementSearchResults?.length > 0 &&
                            <div className='absolute z-10 max-h-[350px] w-full overflow-y-auto bg-white shadow-lg border-[1px] border-[solid] border-[#D2D2D2]'>
                                {
                                    agreementData?.addAgreementSearchResults?.map((agreement, index) => (
                                        <div key={index} className='border-b border-b-[#E1E1E1] last:border-0 hover:bg-[#F0F0F0]'>

                                            {
                                                // Check to see if agreement is already linked
                                                agreementData?.filteredAgreements?.map(obj => obj?.poanumber).includes(agreement?.poanumber) ?


                                                    // Disabled State
                                                    (
                                                        <Tooltip
                                                            message={`This agreement has already been linked to this application.`}
                                                            children={
                                                                <div className='w-full flex flex-row justify-between py-[10px] px-[15px]'>
                                                                    <div className='flex flex-col'>
                                                                        <p className='m-0 font-medium text-[14px] text-[#C4C4C4] uppercase'>
                                                                            {agreement?.poanumber}
                                                                        </p>
                                                                        <p className='m-0 text-[14px] text-[#C4C4C4]'>
                                                                            {agreement?.vendor?.vendorname}
                                                                        </p>
                                                                    </div>

                                                                    {/* Add Button */}
                                                                    <img className='w-[28px] cursor-not-allowed' src={AddDisabled} alt='Add'></img>
                                                                </div>}
                                                        ></Tooltip>

                                                    ) :

                                                    // Active State
                                                    (
                                                        <div className='flex flex-row justify-between py-[10px] px-[15px]'>
                                                            <div className='flex flex-col'>
                                                                <p className='m-0 font-medium text-[14px] text-[#424242] disabled:text-[#C4C4C4] uppercase'>
                                                                    {agreement?.poanumber}
                                                                </p>
                                                                <p className='m-0 text-[14px] text-[#A0A0A0] disabled:text-[#C4C4C4]'>
                                                                    {agreement?.vendor?.vendorname}
                                                                </p>
                                                            </div>

                                                            {/* Add Button */}
                                                            <img className='cursor-pointer w-[28px]' src={Add} alt='Add' onClick={() => setAgreementData(
                                                                {
                                                                    'linkedAgreements': [...agreementData?.linkedAgreements, agreement],
                                                                    'filteredAgreements': [...agreementData?.filteredAgreements, agreement],
                                                                    'addAgreementSearchInput': '',
                                                                    'addAgreementSearchResults': [],
                                                                    'addedAgreements': [...agreementData?.addedAgreements, agreement]
                                                                }
                                                            )}></img>

                                                        </div>
                                                    )
                                            }
                                        </div>
                                    ))}
                            </div>
                        }

                    </div>

                </div>

                {/* ------------------------------------------------------ */}
                {/*  Body Container                                        */}
                {/* ------------------------------------------------------ */}

                <div className='flex flex-col gap-2 justify-between h-full overflow-hidden'>

                    <div className='flex flex-col gap-3'>

                        {/* ------------------------------------------------------ */}
                        {/*  Information Box                                       */}
                        {/* ------------------------------------------------------ */}

                        <div className='Info-Box flex flex-row px-2 py-0 items-center'>
                            <img className='p-2 self-center' alt='info-icon' src={InfoIcon}></img>
                            <label className='p-1'>To add new agreements to the inventory, click <a href='/inventory?view=agreements' target='_blank'> here</a>.</label>
                        </div>

                        {/* ------------------------------------------------------ */}
                        {/*  Table Container                                       */}
                        {/* ------------------------------------------------------ */}

                        <div className='Table-Container overflow-hidden'>

                            {/* ======== Sorting and Filters ========== */}
                            <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                                <label className='font-semibold self-center'>
                                    Total: {agreementData?.filteredAgreements.length} of {agreementData?.linkedAgreements?.length}
                                </label>

                                <div className='flex flex-row gap-2'>

                                    {/* ======== Search Bar ======== */}
                                    <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[400px]'>
                                        <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                        <label htmlFor='searchInput'>
                                            <input
                                                id='searchInput'
                                                className='border-none h-[36px] w-full p-0 m-0 outline-none bg-white'
                                                type='text'
                                                placeholder='Search agreements in this application'
                                                autoComplete='no'
                                                onChange={(e) => handleTableSearch(e.target.value)}
                                                value={agreementData?.tableSearchInput}
                                            ></input>
                                        </label>
                                    </div>

                                    {/* Download Report */}
                                    <ExportToCSV
                                        filename={`${applicationDoc?.vendorname}-Agreements-${Date.now()}`}
                                        data={agreementData?.filteredAgreements}
                                    ></ExportToCSV>

                                </div>

                            </div>

                            {/* ======================= Table ======================= */}
                            <table className='w-full max-h-96 overflow-y-scroll'>
                                <thead>
                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2'>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>PoA Number</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Vendor</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // No agreements found
                                        agreementData?.filteredAgreements?.length === 0 ?
                                            (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className='text-center p-[2%]'>
                                                            No agreements found.
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                            // Map in each agreement
                                            agreementData?.filteredAgreements?.map((doc, index) => (
                                                <tr key={index} className='border-b-[#C8C8C9] bg-[#FFFFFF] border-solid border-b last:border-0'>

                                                    {/* PoA Number */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        {doc?.poanumber}
                                                    </td>

                                                    {/* Vendor Name */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        {doc?.vendor?.vendorname}
                                                    </td>

                                                    {/* Status */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        <StatusLabel status={doc?.status}></StatusLabel>
                                                    </td>

                                                    {/* Delete Icon */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        <div className='Cancel-Icon cursor-pointer min-w-[32px]' onClick={() => 

                                                            setAgreementData(
                                                                {
                                                                    'linkedAgreements': agreementData?.linkedAgreements?.filter((object) => object !== doc),
                                                                    'filteredAgreements': agreementData?.linkedAgreements?.filter((object) => object !== doc),
                                                                    'removedAgreements': [...agreementData?.removedAgreements, doc]
                                                                }
                                                            )

                                                        }></div>
                                                    </td>

                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>

                        </div>

                    </div>

                    {/* ------------------------------------------------------ */}
                    {/*  Buttons Container                                     */}
                    {/* ------------------------------------------------------ */}

                    <div className='flex flex-row my-3 gap-2' >

                        {/* Submit */}
                        <button className='Primary-Button' disabled={false} type='submit' value='submit' onClick={() => handleSubmit()}>
                            Save
                        </button>

                        {/* Cancel */}
                        <button className='Secondary-Button' onClick={() => {

                            setAgreementData({
                                'allAgreements': [],     
                                'linkedAgreements': [],  
                                'filteredAgreements': [],
                                'addedAgreements': [],   
                                'removedAgreements': [], 
                    
                                // Add Agreements Search
                                'addAgreementSearchInput': '',
                                'addAgreementSearchResults': [],
                    
                                // Table Search
                                'tableSearchInput': ''
                            });

                            setApplicationDoc(undefined);
                            setPaneStatus('pending');

                        }}>
                            Cancel
                        </button>

                    </div>

                </div>

            </dialog>
        </div>
    )
}
