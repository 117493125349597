//================================================================
//  Component: Download File Blob
//================================================================

//  Purpose: Download a file from GCS using the 'filepath'


//  Properties:
//    - filepath = {String, '<PROJECT_ID>.appspot.com/applications/<APPLICATION_ID>/img.png'}

//  Example:
//    <DownloadFileBlob
//      filepath={auitmarketplacedev99285.appspot.com/applications/1234567890/img.png}
//    ></DownloadFileBlob>    

//================================================================


//Libraries
import React, { useContext } from 'react';

//Contexts
import { SetToast } from '../../Library/GlobalContexts';

//Functions
import DownloadBlob from '../../Library/DownloadBlob';

//Images
import FileGeneric from '../Images/Icon_File_Generic.svg';


export default function DownloadFileBlob({
    filename,
    filepath,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Downloads the content from GCS
    function handleDownload() {

        if (filepath === undefined) return;
        if (filepath.length === 0) return;

        // Exract the GCS bucket from the 'filepath'
        const bucketname = filepath.split('/')[0];

        // Remove the GCS bucket from the 'filepath'
        const filePath = filepath.replace(bucketname, '');

        // Open the toast
        setToast({
            'type': 'pending', // info, pending, success, warning, error
            'message': 'Preparing for download',
        });

        // Download the blob via Firebase
        DownloadBlob(filePath, bucketname).then((blob) => {

            // Create URL from blob
            const url = window.URL.createObjectURL(blob);

            // Create new Element -> Add the file name & URl
            const a = document.createElement('a');
            a.download = filename;
            a.href = url;

            // Trigger download & Cleanup
            a.click();

            // Turn off toast
            setToast({
                'type': '',
                'message': '',
            });

        }).catch((error) => {

            console.log(error);

            // Set error on toast
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to prepare link(s)',
            });

        });

    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div className='flex flex-row gap-2' onClick={() => handleDownload()}>

            <img className='w-[28px] opacity-80 p-[1px] rounded' src={FileGeneric} alt='file-generic-icon'></img>
            <label className='self-center font-medium bg-[#F5F5F5] text-[#0972D3] py-2 px-3 rounded-md cursor-pointer'>
                {filename}
            </label>

        </div>

    );

}
