//================================================================
//  Tab: Overview
//================================================================

//  Purpose: This tab displays all 'Overview' of an Transformation Item

//  Example:
//    <Overview></Overview>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';

//Functions
import QueryListener from '../../../../../Library/QueryListener';

//Images


export default function Overview() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Holds the application document from 
  const [transformationDoc, setTransformationDoc] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get Firestore document
  useEffect(() => {

    const transformationid = params.id;

    if (transformationid === '') return;
    if (transformationid === null) return;

    function onLoadChange(documents) {

      setTransformationDoc(documents[0]);
      setPageStatus('onload');

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = QueryListener('transformationregister', [['transformationid', '==', transformationid]], onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col w-[50%] px-[20px]'>

          {/* Process Name */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'processname'}
            label={<label className='font-medium'>Process Name</label>}
            defaultValue={transformationDoc?.processname}
            type={'text'}
            disabled={true}
          ></LiveField>

          {/* Process Description */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'processdescription'}
            label={<label className='font-medium'>Process Description</label>}
            defaultValue={transformationDoc?.processdescription}
            type={'multiline'}
            placeholder={'Provide a description of the process'}
            disabled={false}
          ></LiveField>

          {/* Proposed Changes */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'proposedchanges'}
            label={<label className='font-medium'>Proposed Changes</label>}
            defaultValue={transformationDoc?.proposedchanges}
            type={'multiline'}
            placeholder={'Provide a list of proposed changes'}
            disabled={false}
          ></LiveField>

          {/* Proposed Benefits */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'proposedbenefits'}
            label={<label className='font-medium'>Proposed Benefits</label>}
            defaultValue={transformationDoc?.proposedbenefits}
            placeholder={'Provide a list of the benefits of the proposed changes'}
            type={'multiline'}
            disabled={false}
          ></LiveField>

          {/* Changes Completed */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'changescompleted'}
            label={<label className='font-medium'>Changes Completed</label>}
            defaultValue={transformationDoc?.changescompleted}
            placeholder={'Provide a list of the completed changes'}
            type={'multiline'}
            disabled={false}
          ></LiveField>

          {/* Impact Assessment */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'impactassessment'}
            label={<label className='font-medium'>Impact Assessment</label>}
            defaultValue={transformationDoc?.impactassessment}
            type={'multiline'}
            placeholder={'Outline any associated risks and where possible the mitigations'}
            disabled={false}
          ></LiveField>

          {/* Capability Area */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'capabilityarea'}
            label={<label className='font-medium'>Capability Area</label>}
            defaultValue={transformationDoc?.capabilityarea}
            type={'text'}
            disabled={true}
          ></LiveField>

          {/* Capability / Service Owner */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'capabilityserviceowner'}
            label={<label className='font-medium'>Capability / Service Owner</label>}
            defaultValue={transformationDoc?.capabilityserviceowner}
            type={'user-search'}
            disabled={true}
          ></LiveField>

          {/* Est cost to change */}
          <div className='gap-[5px] p-[5px]'>

            <label className='font-medium pt-[5px]'>Est cost to change </label>
            <div className='flex flex-row pt-[10px]'>

              <LiveField
                type={'number'}
                collectionid={'transformationregister'}
                documentid={transformationDoc?.transformationid}
                fieldpath={'estcosttochange'}
                defaultValue={transformationDoc?.estcosttochange}
                styleInput={{ width: '315px' }}
              ></LiveField>

              <LiveField
                type={'choice'}
                collectionid={'transformationregister'}
                documentid={transformationDoc?.transformationid}
                fieldpath={'estcosttochangecurrency'}
                defaultValue={transformationDoc?.estcosttochangecurrency}
                options={[
                  'USD',
                  'SGD',
                  'GBP',
                  'AUD',
                ]}
                styleInput={{ width: '120px' }}
              ></LiveField>

            </div>
          </div>

          {/* Est Savings */}
          <div className='gap-[5px] p-[5px]'>

            <label className='font-medium pt-[5px]'>Est Savings</label>
            <div className='flex flex-row pt-[10px]'>

              <LiveField
                type={'number'}
                collectionid={'transformationregister'}
                documentid={transformationDoc?.transformationid}
                fieldpath={'estsavings'}
                defaultValue={transformationDoc?.estsavings}
                styleInput={{ width: '315px' }}
              ></LiveField>

              <LiveField
                type={'choice'}
                collectionid={'transformationregister'}
                documentid={transformationDoc?.transformationid}
                fieldpath={'estsavingscurrency'}
                defaultValue={transformationDoc?.estsavingscurrency}
                options={[
                  'USD',
                  'SGD',
                  'GBP',
                  'AUD',
                ]}
                styleInput={{ width: '120px' }}
              ></LiveField>

            </div>
          </div>

          {/* Status */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'status'}
            label={<label className='font-medium'>Status</label>}
            defaultValue={transformationDoc?.status}
            type={'choice'}
            options={[
              'New',
              'Open',
              'Closed',
              'On Hold',
            ]}
          ></LiveField>

          {/* Start Date */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'startdate'}
            label={<label className='font-medium'>Start Date</label>}
            defaultValue={transformationDoc?.startdate}
            type={'date-string'}
          ></LiveField>

          {/* End Date */}
          <LiveField
            collectionid={'transformationregister'}
            documentid={transformationDoc?.transformationid}
            fieldpath={'enddate'}
            label={<label className='font-medium'>End Date</label>}
            defaultValue={transformationDoc?.enddate}
            type={'date-string'}
          ></LiveField>

        </div>
      }
    ></PageComponent>
  )
}
