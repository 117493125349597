//================================================================
//  Component: Sort Filter ~ Filter tags child component
//================================================================

//  Purpose: Collection of components that create a sorting and filtering experience for tables

//  Properties:
//    - index = {string, current index}
//    - columnName = {string, current filters column name}
//    - filterKey = {string, current filter}
//    - filterValues = {array, filter values}
//
//
//    How to apply 'TableHeader' components
//    <>
//
//      <FilterTags
//          tableArray={agreements}
//          setFilteredTableArray={setAgreementsFiltered}
//      ></FilterTags>
//
//      <FilterByPane
//          tableArray={agreements}
//      ></FilterByPane>
//
//      <table className='w-full'>
//          <thead>
//              <tr>
//
//                  <TableHeader
//                    column='PoA Number'
//                    objectKey='vendor.vendorname'
//                    styleInput={{padding: '5px'}}
//                    sticky={true}
//                    dataType={''}
//                  ></TableHeader>
//  
//                  ...insert all table headers                
//  
//              </tr>
//
//          ...insert table body   
//
//    </>
//
//================================================================


//Libraries
import React, { useContext, useState } from 'react';

//Contexts
import { GetSortFilters, SetSortFilters } from '../../Library/GlobalContexts';

//Images
import CloseIcon from '../Images/Icon_Clear_Grey.svg'


export default function FilterTagsItem({
    index,
    columnName,
    filterKey,
    filterValues,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getSortFilters = useContext(GetSortFilters);
    const setSortFilters = useContext(SetSortFilters);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    const [open, setOpen] = useState(false);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div key={index} className='relative flex flex-row gap-2 bg-[#E9EBED] w-fit px-2 py-1 rounded-full text-[#424242] cursor-pointer'>

            <div onClick={() => setOpen(!open)}>

                <label className='px-1 cursor-pointer'>{columnName}</label>

                {/* Number of filters */}
                <label className='rounded-full bg-white px-2 font-semibold'>{filterValues.length}</label>

            </div>

            {/* Expanded --> show each filters */}
            <div hidden={!open} className='absolute z-50 top-[40px] left-[0px] shadow-lg flex flex-col gap-2 bg-white px-4 py-2 w-[350px] rounded'>
                {columnName}:
                {
                    filterValues.map((value, index) => (

                        <div className='flex flex-row gap-2 bg-[#E9EBED] w-fit px-3 py-1 rounded-full text-[#424242]' key={index}>
                            {value}
                            <img className='cursor-pointer w-[20px]' src={CloseIcon} alt='close-icon' onClick={() => {

                                // Remove one filter from the list
                                getSortFilters.filters[filterKey].values.splice(index, 1);
                                setSortFilters({ ...getSortFilters });

                                setOpen(false);

                            }}></img>

                        </div>

                    ))
                }
            </div>

            {/* Clear current filter */}
            <img className='cursor-pointer w-[20px]' src={CloseIcon} alt='close-icon' onClick={() => {

                // Deletes the filter key from the global context
                delete getSortFilters.filters[filterKey];
                setSortFilters({ ...getSortFilters });

            }}></img>

        </div>
    )
}
