//================================================================
//  Component: Add Vendor
//================================================================

//  Purpose: Add a new vendor to the vendors collection

//  Properties:
//    - None

//  AddVendor:
//    <AddVendor></AddVendor>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Contexts
import { GetUser, SetToast } from '../../../../Library/GlobalContexts';

//Components
import CountryList from '../../../../Library/CountryList.json';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';

//Images


export default function AddVendor() {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Open or Close the 'Add Vendor' side pane
    const [addVendor, setAddVendor] = useState(false);


    // --------------------------------------------------
    //  Form State
    // --------------------------------------------------

    // https://react-hook-form.com/get-started
    const {

        register,
        handleSubmit,
        reset,
        formState: { isSubmitSuccessful, errors },

    } = useForm();

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Submit Handler
    const onSubmit = (formData) => {

        // Open the toast
        setToast({
            'type': 'pending',
            'message': 'Preparing vendor',
        });       

        // Create a searcharray for lookup fields
        const searchArray = [];
        let previousValue = '';
        [...formData.vendorname]?.forEach((value, index) => {

            // Skip the first character
            if (index === 0) {

                previousValue = value;
                return searchArray.push(value.toLowerCase());

            }

            // Create an array of each character
            searchArray.push(`${previousValue}${value}`.toLowerCase());
            previousValue = `${previousValue}${value}`.toLowerCase();

        });

        const documentid = `${btoa(formData.vendorname)}-${Date.now()}`;

        const documentObject = {
            'vendorid': documentid,
            'vendorname': formData.vendorname,
            'registrationnumber': formData.registrationnumber,
            'tradingas': formData.tradingas,
            'address': {
                'street': formData.street,
                'city': formData.city,
                'country': formData.country,
                'postcode': formData.postcode,
            },
            'website': formData.website,
            'contactname': formData.contactname,
            'contactnumber': formData.contactnumber,
            'contactemail': formData.contactemail,
            'vendorstatus': formData.vendorstatus,
            'createdby': getUser.emailaddress,
            'createdbyname': `${getUser.givenname} ${getUser.surname}`, // Full Name (i.e. Nowshin Hassan)
            'lastmodifieddate': '', // Timestamp, added by the system not a user editable field
            'lastmodifiedby': '',  // Emailaddress
            'createddate': new Date(),
            'searcharray': searchArray
        }

        WriteDocument('vendors', documentid, documentObject, false)
        .then(() => {

            // Close Side Pane
            setAddVendor(false);

            // Show Success Toast
            setToast(
                {
                    'type': 'success',
                    'message': 
                        <>
                            Vendor added. <a href={`https://inventory.store.lendlease.com/inventory/vendor/${documentObject.vendorid}?view=overview`} target='_blank' rel='noopener noreferrer'>{documentObject.vendorname}</a>.
                        </>
                }
            );
            
        })
        .catch((error) => {

            console.log('error', error);

            // Close Side Pane
            setAddVendor(false);
                                  
            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to create vendor',
            });
            

        });

    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    useEffect(() => {

        if (isSubmitSuccessful) {
            reset();
        };


    }, [isSubmitSuccessful, reset]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (addVendor === false) return (

        <button className='Primary-Button whitespace-nowrap' onClick={() => setAddVendor(true)}>
            <strong>+</strong> Add Vendor
        </button>

    );

    return (
        <>

            {/* ======== Keeps the button is visible during slide animation ========= */}
            <button className='Primary-Button whitespace-nowrap' onClick={() => setAddVendor(true)}>
                <strong>+</strong> Add Vendor
            </button>

            {/* =========================================================== */}
            {/*  Add Vendor Pane                                         */}
            {/* =========================================================== */}

            <div className='Pane-Background'>
                <dialog className='Pane-Container'>

                    {/*  ========= Add Vendor Form * ========= */}
                    <form className='flex flex-col justify-between w-full' onSubmit={handleSubmit(onSubmit)}>

                        <div className='w-full overflow-x-auto'>

                            {/* Header */}
                            <h4> Add New Vendor </h4>

                            {/*  ========= 1. Vendor Name * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>1. Name <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.vendorname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='Enter Name'
                                    autoComplete='no'
                                    {...register(
                                        'vendorname', {
                                        required: 'This is a required field.',
                                        maxLength: 50,
                                    })}
                                />
                                {errors.vendorname && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.vendorname?.message}</p>}
                            </div>

                            {/*  ========= 2. Registration * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>2. Company Registration Number <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.registrationnumber ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='Enter Registration Number'
                                    autoComplete='no'
                                    {...register(
                                        'registrationnumber', {
                                        required: 'This is a required field.',
                                    })}
                                />
                                {errors.registrationnumber && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.registrationnumber?.message}</p>}
                            </div>

                            {/*  ========= 3. Trading as Name  ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>3. Trading as Name</label>
                                <input
                                    className={errors.tradingas ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='Enter Trading as Name'
                                    autoComplete='no'
                                    {...register(
                                        'tradingas', {
                                    })}
                                />
                                {errors.tradingas && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.tradingas?.message}</p>}
                            </div>

                            {/*  ========= 4. Street * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>4. Street Address <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.street ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='e.g. 1 John St'
                                    autoComplete='no'
                                    {...register(
                                        'street', {
                                        required: 'This is a required field.',
                                    })}
                                />
                                {errors.street && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.street?.message}</p>}
                            </div>

                            {/*  ========= City * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>5. City <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.city ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='e.g. Sydney'
                                    autoComplete='no'
                                    {...register(
                                        'city', {
                                        required: 'This is a required field.',
                                    })}
                                />
                                {errors.city && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.city?.message}</p>}
                            </div>

                            {/*  ========= 6. Country * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> 6. Country <span className='text-[#C4314B]'>*</span></label>
                                <select
                                    className='Input-Field-Select'
                                    {...register(
                                        'country', {
                                        required: 'This is a required field.',
                                    })
                                    } >
                                    {
                                        CountryList.map((country, index) => (
                                            <option value={country.name} key={index}>{country.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            {/*  ========= 7. Postal Code * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>7. Postal Code <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.postcode ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='Enter Postcode'
                                    autoComplete='no'
                                    {...register(
                                        'postcode', {
                                        required: 'This is a required field.',
                                    })}
                                />
                                {errors.postcode && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.postcode?.message}</p>}
                            </div>

                            {/*  ========= 8. Website ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>8. Website</label>
                                <input
                                    className={errors.website ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='e.g. www.lendlease.com'
                                    autoComplete='no'
                                    {...register(
                                        'website', {
                                    })}
                                />
                                {errors.website && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.website?.message}</p>}
                            </div>

                            {/*  ========= 9. Contact Name * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>9. Contact Name <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.contactname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='e.g. John Smith'
                                    autoComplete='no'
                                    {...register(
                                        'contactname', {
                                        required: 'This is a required field.',
                                    })}
                                />
                                {errors.contactname && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.contactname?.message}</p>}
                            </div>

                            {/*  ========= 10. Contact Phone * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>10. Contact Phone Number </label>
                                <input
                                    className={errors.contactnumber ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='Enter Contact Number'
                                    autoComplete='no'
                                    {...register(
                                        'contactnumber', {
                                        minLength: 8,
                                    })}
                                />
                                {errors.contactnumber && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.contactnumber?.message}</p>}
                            </div>

                            {/*  ========= 11. Contact Email * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'>11. Contact Email <span className='text-[#C4314B]'>*</span></label>
                                <input
                                    className={errors.contactemail ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    placeholder='e.g. john.smith@example.com'
                                    autoComplete='no'
                                    {...register(
                                        'contactemail', {
                                        required: 'This is a required field.',
                                    })}
                                />
                                {errors.contactemail && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.contactemail?.message}</p>}
                            </div>

                            {/*  ========= 12. Status * ========= */}
                            <div className='FormComponent-Row'>
                                <label className='font-medium'> 12. Status <span className='text-[#C4314B]'>*</span></label>
                                <select
                                    className='Input-Field-Select'
                                    {...register(
                                        'vendorstatus', {
                                        required: 'This is a required field.'
                                    })
                                    } >
                                    <option value='Active'>Active</option>
                                    <option value='Decommissioned'>Decommissioned</option>
                                </select>
                            </div>

                        </div>

                        {/*  ========= Button Container ========= */}
                        <div className='flex flex-row gap-2 border-solid border-t-2 pt-3 mt-3'>
                            {/* Submit */}
                            <button
                                className='Primary-Button whitespace-nowrap'
                                disabled={false}
                                type='submit'
                                value='submit'
                            >Submit</button>

                            {/* Cancel */}
                            <button className='Secondary-Button whitespace-nowrap' onClick={() => {

                                // close side pane
                                reset();
                                setAddVendor(false);

                            }}>Cancel</button>

                        </div>

                    </form>

                    {errors.vendors && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.vendors?.message}</p>}

                </dialog>
            </div>
        </>
    )
}

