//================================================================
//  Page: Vendor
//================================================================

//  Purpose: This page shows the current Vendor

//  Example:
//    <Vendor></Vendor>    

//================================================================


// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

// Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import TabView from '../../../../Components/TabView/TabView';
import BreadCrumbs from '../../../../Components/Breadcrumbs/BreadCrumbs';
import StatusLabel from '../../../../Components/StatusLabel/StatusLabel';

// Tabs
import Overview from './Tabs/Overview';


// Functions
import DocumentListener from '../../../../Library/DocumentListener';

// Images

// CSS
import './Vendor.css';



export default function Vendor() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const routerUseLocation = useLocation();
  const params = useParams();
  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState();

  // Holds the application document from 
  const [vendorDoc, setVendorDoc] = useState();

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Overview with the status of 'Active'
  useEffect(() => {

    const vendorId = params.id;

    if (vendorId === '') return;
    if (vendorId === null) return;

    function onLoadChange(document) {

      setVendorDoc(document);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('vendors', vendorId, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      pageStatus={pageStatus}
      header={
        <div className='Page-Header-Container'>

          <BreadCrumbs
            rootLinkName='Vendors'
            rootLinkRoute={`/${routerUseLocation.pathname.split('/')[1]}?view=vendors`}
            childLinkName={vendorDoc?.vendorname}
          ></BreadCrumbs>

          <div className='flex flex-row gap-4'>
            <p className='font-medium text-[20px] mb-0'>{vendorDoc?.vendorname}</p>
            <StatusLabel status={vendorDoc?.vendorstatus}></StatusLabel>
          </div>
        </div>
      }
      body={
        <div>

          <TabView
            defaultView={1}

            //------------------------------------------------------
            // Overview
            //------------------------------------------------------

            oneTitle={'Overview'}
            oneRoute={`/inventory/vendor/${params.id}?view=overview`}
            oneContent={
              <Overview></Overview>
            }

          ></TabView>

        </div>
      }
    ></PageComponent>
  )
}
