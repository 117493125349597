//================================================================
//  Component: Lookup Live Fields
//================================================================

//  Purpose: This is an input field that allows you to search any Firestore collection with the 'searcharray' field

//  Properties:
//    - collectionid = {string, Firestore collection id}
//    - defaultValue = {string, onload value for the input}
//    - placeholder = {string, placeholder string}
//    - errors = {defined value, this determines if this field is in its error state}
//    - disabled = {boolen, enable or disable the field}
//    - handleBlur = {call back function, triggers the save event to the Firestore Document}
//    - fieldStatus = {useState, LiveField component status}
//    - fieldError = {useState, LiveField error message}
//    - dataFieldPrimary = {string, field key which is saved to the Firestore document and SHOW in the drop down}
//    - dataFieldSecondary = <Optional> {string, field key which is saved to the Firestore document and SHOW in the drop down}
//    - dataFieldTertiary = <Optional> {string, field key which is saved to the Firestore document and IS NOT shown in the dropdown. Good for a documentid, etc}
//    - createNewHTML = <Optional> {HTML, generic html attached to the bottom of the search results}

//  Example:

//    <LookUpLiveFields
//      collectionid={'users'}
//      defaultValue={'mark.bennett@lendlease.com'}
//      placeholder={'Search user by email address'}
//      errors={true}
//      dataFieldPrimary={'emailaddress'}
//      dataFieldSecondary={'givenname'}
//      dataFieldTertiary={'surname'}
//      createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <a href='http://console.store.lendlease.com/inventory?view=vendors' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
//    ></LookUpLiveFields>    

//================================================================


//Libraries
import React, { useReducer, useEffect } from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../../Library/QueryDocument';

//Images
import successIcon from '../../Images/Icon_Save_Teal.svg';
import pendingIcon from '../../Images/Icon_LoadingFile_Grey.gif';
import errorIcon from '../../Images/Icon_Error_Red.svg';


export default function LookUpLiveFields({
    collectionid,
    defaultValue,
    placeholder,
    errors,
    disabled,
    handleBlur,
    fieldStatus,
    fieldError,
    dataFieldPrimary,
    dataFieldSecondary,
    dataFieldTertiary,
    createNewHTML,
}) {
    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store the search data
    const [searchData, setSearchData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'userInput': '',
            'queryMessage': '',
            'searchResults': []
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function used to handle search
    const handleSearch = (value) => {

        setSearchData({
            'searchResults': [],
            'userInput': value,
        })

        // Validate input
        if (value?.length === 0) {

            return setSearchData({ 'queryMessage': 'Please enter a value.' });

        }

        if (value?.length <= 2) {

            return setSearchData({ 'queryMessage': 'Search requires more than two characters.' });

        }

        const query = value?.toLowerCase();

        // Search by characters in email
        QueryDocument(collectionid, [
            ['searcharray', 'array-contains-any', [query]],
        ])
        .then((docs) => {

            setSearchData({
                'searchResults': docs,
                'queryMessage': `Showing ${docs?.length} result(s)`
            });

        })
        .catch((errors) => {

            console.log(errors);
            setSearchData({ 'queryMessage': 'Oops something went wrong!' });

        });

    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // Set the default value onload
    useEffect(() => {
      
        if (defaultValue === undefined) return;

        setSearchData({ 'userInput': defaultValue })

    }, [defaultValue])
    
    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    // onload
    if (fieldStatus === 'onload') {

        return (

            <div className='flex flex-col relative'>

                {/* ------------------------------------------------------ */}
                {/*  Users Search Bar                                      */}
                {/* ------------------------------------------------------ */}

                {/* Search Bar */}
                <div className='flex flex-row items-center'>

                    {/* Search Input Field */}
                    <input
                        className={
                            errors ?
                                'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border-[1px] border-solid border-[#DE0000] rounded-[5px] disabled:text-[#2a2a2a] disabled:bg-[#f8f8f8]'

                                : searchData?.searchResults?.length > 0 ?
                                'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none disabled:text-[#2a2a2a] disabled:bg-[#f8f8f8]'
                                :
                                'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] disabled:text-[#2a2a2a] disabled:bg-[#f8f8f8]'
                        }
                        type='text'
                        placeholder={placeholder}
                        onChange={(e) => handleSearch(e.target.value)}
                        onPaste={(e) => handleSearch(e.clipboardData.getData('Text'))}
                        autoComplete='no'
                        value={searchData.userInput}
                        disabled={disabled}
                    ></input>

                </div>

                {/* Search Results */}
                {
                    ((searchData?.searchResults?.length > 0 || defaultValue !== searchData.userInput) && searchData.userInput !== '') &&
                    (
                        <div className='absolute w-[450px]  z-10 top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>

                            {/* Results */}
                            {
                                searchData?.searchResults.map((document, index) => (
                                    <div
                                        key={index}
                                        className='flex flex-row justify-between px-4 py-2 cursor-pointer rounded-sm hover:bg-[#F0F0F0]'
                                        onClick={() => {

                                            const resultsObj = {
                                                [dataFieldPrimary]: document[dataFieldPrimary],
                                            }

                                            if (dataFieldSecondary) {

                                                resultsObj[dataFieldSecondary] = document[dataFieldSecondary];

                                            }

                                            if (dataFieldTertiary) {

                                                resultsObj[dataFieldTertiary] = document[dataFieldTertiary];

                                            }

                                            // Update the search results
                                            setSearchData({
                                                'searchResults': [],
                                                'userInput': document[dataFieldPrimary],
                                            });

                                            handleBlur(resultsObj, true);

                                        }}
                                    >

                                        {/* Detail */}
                                        <div className='flex flex-col'>
                                            <p className='m-0 font-medium text-[14px]'>{document?.[dataFieldPrimary]}</p>
                                            <p className='m-0 text-[14px]'>{document?.[dataFieldSecondary]}</p>
                                        </div>

                                    </div>
                                ))
                            }

                            {/* Total */}
                            <div className='flex flex-col text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData.queryMessage.length === 0}>
                                {searchData.queryMessage}
                                {createNewHTML}
                            </div>

                        </div>
                    )
                }

            </div>

        )

    }

    // pending
    else if (fieldStatus === 'pending') {

        return (
            <div className='flex flex-row gap-2'>

                {/* ========= Input ========= */}
                <div className='flex flex-col relative'>

                    {/* ------------------------------------------------------ */}
                    {/*  Users Search Bar                                      */}
                    {/* ------------------------------------------------------ */}

                    {/* Search Bar */}
                    <div className='flex flex-row items-center'>

                        {/* Search Input Field */}
                        <input
                            className={
                                errors ?
                                    'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border-[1px] border-solid border-[#DE0000] rounded-[5px]'

                                    : searchData?.searchResults?.length > 0 ?
                                        'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none'
                                        :
                                        'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px]'
                            }
                            type='text'
                            placeholder={placeholder}
                            value={searchData.userInput}
                            disabled={true}
                        ></input>

                    </div>

                </div>

                {/* ========= Pending Icon ========= */}
                <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>

            </div>
        )

    }

    // success
    else if (fieldStatus === 'success') {

        return (
            <div className='flex flex-row gap-2'>

                {/* ========= Input ========= */}
                <div className='flex flex-col relative'>

                    {/* ------------------------------------------------------ */}
                    {/*  Users Search Bar                                      */}
                    {/* ------------------------------------------------------ */}

                    {/* Search Bar */}
                    <div className='flex flex-row items-center'>

                        {/* Search Input Field */}
                        <input
                            className={
                                errors ?
                                    'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border-[1px] border-solid border-[#DE0000] rounded-[5px]'

                                    : searchData?.searchResults?.length > 0 ?
                                        'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none'
                                        :
                                        'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px]'
                            }
                            type='text'
                            placeholder={placeholder}
                            onChange={(e) => handleSearch(e.target.value)}
                            onPaste={(e) => handleSearch(e.clipboardData.getData('Text'))}
                            autoComplete='no'
                            value={searchData.userInput}
                            disabled={disabled}
                        ></input>

                    </div>

                    {/* Search Results */}
                    {
                        ((searchData?.searchResults?.length > 0 || defaultValue !== searchData.userInput) && searchData.userInput !== '') &&
                        (
                            <div className='absolute w-[450px]  z-10 top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>

                                {/* Results */}
                                {
                                    searchData?.searchResults.map((document, index) => (
                                        <div
                                            key={index}
                                            className='flex flex-row justify-between px-4 py-2 cursor-pointer rounded-sm hover:bg-[#F0F0F0]'
                                            onClick={() => {

                                                const resultsObj = {
                                                    [dataFieldPrimary]: document[dataFieldPrimary],
                                                }

                                                if (dataFieldSecondary) {

                                                    resultsObj[dataFieldSecondary] = document[dataFieldSecondary];

                                                }

                                                if (dataFieldTertiary) {

                                                    resultsObj[dataFieldTertiary] = document[dataFieldTertiary];

                                                }

                                                // Update the search results
                                                setSearchData({
                                                    'searchResults': [],
                                                    'userInput': document[dataFieldPrimary],
                                                });

                                                handleBlur(resultsObj, true);

                                            }}
                                        >

                                            {/* Detail */}
                                            <div className='flex flex-col'>
                                                <p className='m-0 font-medium text-[14px]'>{document?.[dataFieldPrimary]}</p>
                                                <p className='m-0 text-[14px]'>{document?.[dataFieldSecondary]}</p>
                                            </div>

                                        </div>
                                    ))
                                }

                                {/* Total */}
                                <div className='flex flex-col text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData.queryMessage.length === 0}>
                                    {searchData.queryMessage}
                                    {createNewHTML}
                                </div>

                            </div>
                        )
                    }

                </div>

                {/* ========= Pending Icon ========= */}
                <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>

            </div>
        )

    }

    // Catch All --> error
    else {

        return (
            <div className='flex flex-col gap-0'>

                <div className='flex flex-row gap-2'>

                    {/* ========= Input ========= */}
                    <div className='flex flex-col relative'>

                        {/* ------------------------------------------------------ */}
                        {/*  Users Search Bar                                      */}
                        {/* ------------------------------------------------------ */}

                        {/* Search Bar */}
                        <div className='flex flex-row items-center'>

                            {/* Search Input Field */}
                            <input
                                className={
                                    errors ?
                                        'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border-[1px] border-solid border-[#DE0000] rounded-[5px]'

                                        : searchData?.searchResults?.length > 0 ?
                                            'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none'
                                            :
                                            'w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px]'
                                }
                                type='text'
                                placeholder={placeholder}
                                onChange={(e) => handleSearch(e.target.value)}
                                onPaste={(e) => handleSearch(e.clipboardData.getData('Text'))}
                                autoComplete='no'
                                value={searchData.userInput}
                                disabled={disabled}
                            ></input>

                        </div>

                        {/* Search Results */}
                        {
                            ((searchData?.searchResults?.length > 0 || defaultValue !== searchData.userInput) && searchData.userInput !== '') &&
                            (
                                <div className='absolute w-[450px]  z-10 top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>

                                    {/* Results */}
                                    {
                                        searchData?.searchResults.map((document, index) => (
                                            <div
                                                key={index}
                                                className='flex flex-row justify-between px-4 py-2 cursor-pointer rounded-sm hover:bg-[#F0F0F0]'
                                                onClick={() => {

                                                    const resultsObj = {
                                                        [dataFieldPrimary]: document[dataFieldPrimary],
                                                    }

                                                    if (dataFieldSecondary) {

                                                        resultsObj[dataFieldSecondary] = document[dataFieldSecondary];

                                                    }

                                                    if (dataFieldTertiary) {

                                                        resultsObj[dataFieldTertiary] = document[dataFieldTertiary];

                                                    }

                                                    // Update the search results
                                                    setSearchData({
                                                        'searchResults': [],
                                                        'userInput': document[dataFieldPrimary],
                                                    });

                                                    handleBlur(resultsObj, true);

                                                }}
                                            >

                                                {/* Detail */}
                                                <div className='flex flex-col'>
                                                    <p className='m-0 font-medium text-[14px]'>{document?.[dataFieldPrimary]}</p>
                                                    <p className='m-0 text-[14px]'>{document?.[dataFieldSecondary]}</p>
                                                </div>

                                            </div>
                                        ))
                                    }

                                    {/* Total */}
                                    <div className='flex flex-col text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData.queryMessage.length === 0}>
                                        {searchData.queryMessage}
                                        {createNewHTML}
                                    </div>

                                </div>
                            )
                        }

                    </div>

                    {/* ========= Error Icon ========= */}
                    <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>

                </div>

                <label className='text-[#DE0000] font-semibold'>{fieldError}</label>

            </div>
        )

    }

    //------------------------------------------------------
}