//================================================================
//  Component: Sort Filter ~ Table Header
//================================================================

//  Purpose: Collection of components that create a sorting and filtering experience for tables

//  Properties:
//    - column = {String, name of the column 'PoA Number', 'Applications', etc}
//    - objectKey = {String, name of the key you are filtering on, 'vendor.vendorname', 'applicationname', etc}
//    - styleInput = <OPTIONAL> {object, CSS style object}
//    - sticky = <OPTIONAL> {boolen, only apply this option to the first column of a table}
//    - dataType = <OPTIONAL> {string, provide the datatype 'number' so the sorting works correctly for contract values, agreement totals, etc}
//    - setSearchInput = <OPTIONAL> {useState, resets the search input field above the table}
//
//
//    IMPORTANT!! if you are using sticky, add the below tailwind styling to the first <td> in the table.
//    'drop-shadow-md sticky left-0 bg-white'
//
//
//    How to apply 'TableHeader' components
//    <>
//
//      <FilterTags
//          tableArray={agreements}
//          setFilteredTableArray={setAgreementsFiltered}
//      ></FilterTags>
//
//      <FilterByPane
//          tableArray={agreements}
//      ></FilterByPane>
//
//      <table className='w-full'>
//          <thead>
//              <tr>
//
//                  <TableHeader
//                    column='PoA Number'
//                    objectKey='vendor.vendorname'
//                    styleInput={{padding: '5px'}}
//                    sticky={true}
//                    dataType={''}
//                  ></TableHeader>
//  
//                  ...insert all table headers                
//  
//              </tr>
//
//          ...insert table body   
//
//    </>
//
//================================================================


//Libraries
import React, { useContext, useState } from 'react';

//Contexts
import { GetSortFilters, SetSortFilters } from '../../Library/GlobalContexts';

//Images
import Arrow from '../Images/Icon_Sort.svg';
import ArrowActive from '../Images/Icon_Collapse_Teal.svg';
import FilterActive from '../Images/Icon_Filter_Active.svg';
import Sort from '../Images/Icon_ArrowDown_Grey.svg';
import IconTick from '../Images/Icon_Tick_Black.svg';


export default function TableHeader({
    column,
    objectKey,
    styleInput,
    sticky,
    dataType,
    setSearchInput,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getSortFilters = useContext(GetSortFilters);
    const setSortFilters = useContext(SetSortFilters);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Sorting and filtering menu
    const [menuOpen, setMenuOpen] = useState(false);

    //------------------------------------------------------
    //  Function
    //------------------------------------------------------

    function updateSortingParams(sortDirection) {

        getSortFilters.sortBy = objectKey;
        getSortFilters.sortDirection = sortDirection;
        getSortFilters.sortDataType = dataType;
        setSortFilters({ ...getSortFilters });

        // Reset the Search input field
        if (setSearchInput) setSearchInput('');

        setMenuOpen(false);

    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <th className={`relative py-[15px] px-[20px] font-semibold whitespace-nowrap min-w-[100px] ${sticky && 'sticky left-0 bg-white drop-shadow-sm z-50'}`}>

            <div style={styleInput} className='flex flex-row gap-2'>

                {column}

                {/* Filter Icon */}
                <img
                    hidden={Object.keys(getSortFilters.filters)?.includes(objectKey) === false}
                    src={FilterActive}
                    alt='Filter-Icon'
                    width='14px'
                    height='14px'
                ></img>

                {/* Sorting */}
                <img
                    hidden={getSortFilters.sortBy !== objectKey}
                    className={getSortFilters.sortBy === objectKey && getSortFilters.sortDirection === 'ascending' ? 'cursor-pointer' : 'rotate-180 cursor-pointer'}
                    onClick={() => updateSortingParams(getSortFilters.sortDirection === 'ascending' ? 'descending' : 'ascending')}
                    src={Sort}
                    alt='Sort-Icon'
                    width='14px'
                    height='14px'
                ></img>

                {/* Menu */}
                <img
                    className='cursor-pointer'
                    onClick={() => setMenuOpen(!menuOpen)}
                    src={menuOpen ? ArrowActive : Arrow}
                    alt='Menu-Icon'
                    width='14px'
                    height='14px'
                ></img>

                {/* ======================================= */}
                {/*              Menu Dropdown              */}
                {/* ======================================= */}

                <div hidden={!menuOpen} className='absolute top-[52px] z-50 flex flex-col w-[200px] bg-white shadow-lg font-normal rounded'>

                    {/* ===== Ascending ===== */}
                    <label className='w-fit' onClick={() => updateSortingParams('descending')}>

                        {/* Ascending Tick Icon */}
                        {
                            (getSortFilters.sortBy === objectKey && getSortFilters.sortDirection === 'descending') ?
                                <div className='flex flex-row py-1 cursor-default'>
                                    <img className='px-1 w-[22px]' src={IconTick} alt='Success-Tick'></img> Ascending
                                </div>
                                :
                                <label className='px-4 py-1 cursor-pointer'>
                                    Ascending
                                </label>
                        }
                        
                    </label>

                    {/* ===== Decending ===== */}
                    <label className='w-fit' onClick={() => updateSortingParams('ascending')}>
                        
                        {/* Decending Tick Icon */}
                        {
                            (getSortFilters.sortBy === objectKey && getSortFilters.sortDirection === 'ascending') ?
                                <div className='flex flex-row py-1 cursor-default'>
                                    <img className='px-1 w-[22px]' src={IconTick} alt='Success-Tick'></img> Descending
                                </div>
                                :
                                <label className='px-4 py-1 cursor-pointer'>
                                    Descending
                                </label>
                        }

                    </label>

                    {/* ===== Filterby ===== */}
                    <label className='w-full border-t border-[#D8D8D8] border-solid' onClick={() => {

                        getSortFilters.filterByPane = {
                            'columnname': column, // Vendor Name
                            'columnid': objectKey, // vendor.vendorname
                            'sortDataType': dataType,
                        }

                        // Reset the search input field
                        if (setSearchInput) setSearchInput('');
                        setSortFilters({ ...getSortFilters });
                        setMenuOpen(false);

                    }}>

                        {/* Filterby Tick Icon */}
                        {

                            getSortFilters.filters[objectKey]?.values === undefined ?
                                <label className='px-4 py-1 cursor-pointer'>
                                    Filter by
                                </label>
                                :
                                <div className='flex flex-row py-1 cursor-pointer'>
                                    <img className='px-1 w-[22px]' src={IconTick} alt='Success-Tick'></img>Filter by ({getSortFilters.filters[objectKey]?.values?.length})
                                </div>
                        }

                    </label>

                </div>

            </div>

        </th>
    )
}
