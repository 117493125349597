//================================================================
//  Component: ExportToCSV
//  Documentation: https://www.npmjs.com/package/react-csv
//================================================================

//  Purpose: Input firestore data and converts to a CSV file, with a user friendly export button!

//  Properties:
//    - filename <Required> = <String> the name of the file
//    - data <Required> = <Array of Arrays with Objects OR Array of Objects>
//    - disabled <Optional> = Enable or disable the button
//    - title <Optional> = Name of the button

//  ExportToCSV:
//    <ExportToCSV
//      filename='emissions'
//      data={projectEmissionsChartData} 
//    ></ExportToCSV>    

//================================================================


//Libraries
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

//Contexts

//Components

//Functions

//Images

import DownloadIcon from '../Images/Icon_Download_White.svg';

//CSS
import './ExportToCSV.css';


export default function ExportToCSV({
  filename,
  data,
  disabled,
  title
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    const csvLink = useRef();
    const [csvData, setCSVData] = useState([]);
  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    useEffect(() => {

      if (data === undefined) return;

      setCSVData(data);

    }, [data]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <CSVLink className='no-underline' ref={csvLink} filename={`${filename}.csv`} data={csvData}  target='_blank'>
      
      <button disabled={disabled} className='Primary-Button flex flex-row self-start'>
        <img className='mr-[5px]' src={DownloadIcon} alt='Download'></img>
        {title ? title : 'Download'}
      </button>

    </CSVLink>
  )
}
