//================================================================
//  Component: Attach File
//  Created by Nowshin Hassan
//================================================================

//  Purpose: This component handles the attachment of files to the commercial task record

//  Properties:
//  - folderPath = {string, folderpath within the GCS bucket}
//  - allowedFileTypes = {array, this is an array of all of the allowed file types}
//  - allowedFileSize = {number, this the total allowed file size}
//  - attachedFile = {useReducer, used to store uploaded file details}
//  - setAttachedFile = {useReducer, used to update useReducer with uploaded file details}

//  Example:
//  <AttachFile
//    folderPath='commercialuploads/${taskId}'
//    allowedFileTypes={['pdf']}
//    allowedFileSize={20000000}
//    attachedFile={formData} 
//    setAttachedFile={setFormData}
//  ></AttachFile>

//================================================================


// Libraries
import React, { useState } from 'react';

// Contexts

// Components

// Functions
import UploadFile from '../../Library/UploadFile';

// Images
import LoadingIcon from '../Images/Icon_LoadingFile_Grey.gif';
import TryAgain from '../Images/Icon_TryAgain_DarkRed.svg';
import Error from '../Images/Icon_Error_DarkRed.svg';

// CSS
import './AttachFile.css';


export default function AttachFile({
  folderPath,
  allowedFileTypes,
  allowedFileSize,
  attachedFile,
  setAttachedFile
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Handles the state of the upload > 'onchange', 'pending', 'success', 'error-fatal'
  const [uploadStatus, setUploadStatus] = useState('onchange');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // File Selector > Event Handler
  function handleChange(file) {

    //------------------------------------------------------
    //  Validate incoming file 
    //------------------------------------------------------

    if (!file) return setAttachedFile({ documentsErrorMessage: 'Please select a file' });

    // Validate File Types
    let fileType;
    fileType = file.name.split('.')
    fileType = fileType[fileType?.length - 1].toLowerCase();

    if (allowedFileTypes?.length > 0) {

      if (!allowedFileTypes.includes(fileType)) {
        return setAttachedFile({ documentsErrorMessage: `Please upload a file in the following format(s): ${allowedFileTypes.join(', ')}` });

      }

    }

    //------------------------------------------------------
    //  Client side validation --> Otherwise the upload MUST complete before the storage rules reject the request
    //------------------------------------------------------

    if (allowedFileSize !== undefined) {

      if (file.size > allowedFileSize) return setAttachedFile({ documentsErrorMessage: `The file must be smaller than ${allowedFileSize / 1000000}MB.` }); // Convert Bytes to MB

    }

    //------------------------------------------------------
    //  Passed all checks > Proceed with upload
    //------------------------------------------------------

    setAttachedFile({ documentsErrorMessage: '' });
    setUploadStatus('pending');

    const fileId = `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}.${fileType}`

    //Upload the file to GCS
    UploadFile(`${folderPath}/${fileId}`, file).then((url) => {

      // Save the file URL and file to formData
      setAttachedFile({
        'fileUrl': url,
        'fileId': fileId,
        'fileName': file.name,
        'fileType': fileType
      });

      setUploadStatus('success');

    }).catch((error) => {

      console.log(error);
      setAttachedFile({ documentsErrorMessage: error.message });
      setUploadStatus('error-fatal');

    });

  }

  // Try Again Button Handler
  function handleUploadStatus(status) {

    setAttachedFile({ documentsErrorMessage: '' });
    setUploadStatus(status);

  }

  // Clear Button Handler
  function clearFileSelector() {

    setAttachedFile({
      'fileUrl': '',
      'fileId': '',
      'fileName': '',
      'fileType': '',
      'documentsErrorMessage': '',
    });

    handleUploadStatus('onchange');

  }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  //------------------------------------------------------
  //  onchange
  //------------------------------------------------------

  if (uploadStatus === 'onchange') {
    return (
      <div>

        <div className='Attach-File-Onload-Container'>

          {/* File Input Field */}
          <input
            className={attachedFile?.documentsErrorMessage?.length === 0 ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
            style={{ padding: '0px' }}
            type='file'
            onChange={(e) => handleChange(e.target.files[0])}
          ></input>

          {/* Clear Button */}
          <button className='Primary-Button' disabled={attachedFile?.fileId?.length === 0 ? true : false} onClick={() => clearFileSelector()} > Clear </button>

        </div>

        <ul className='text-[13px] text-slate-500 mt-1' hidden={attachedFile?.documentsErrorMessage?.length > 0}>

          <li className='list-disc'>
            Please upload one document only
          </li>

          {/* File Type */}
          {
            allowedFileTypes?.length > 0 &&
            <li className='list-disc'>
              Supported File Types: <span className='uppercase'>{allowedFileTypes.join(', ')}</span>
            </li>
          }

          {/* File Size */}
          {
            allowedFileSize !== undefined &&
              <li className='list-disc'>
                <span>Up to {allowedFileSize / 1000000}MB</span>
              </li>
          }

        </ul>

        {/* Error Message */}
        {attachedFile?.documentsErrorMessage &&
          <label className='w-full flex flex-row gap-2 items-center border-1 border-solid border-[darkred] text-[darkred] text-sm bg-[rgb(255,_211,_211)] rounded-[5px] px-3 py-2'>
            <img src={Error} alt='Error'></img>
            {attachedFile?.documentsErrorMessage}
          </label>
        }

      </div>
    )
  }

  //------------------------------------------------------
  //  pending
  //------------------------------------------------------

  else if (uploadStatus === 'pending') {
    return (
      <div className='flex flex-row justify-start items-center text-left gap-x-2 my-[15px]'>
        <img src={LoadingIcon} alt='loading-icon' width='30px' height='30px'></img>
        Uploading...
      </div>
    )
  }

  //------------------------------------------------------
  //  success
  //------------------------------------------------------

  else if (uploadStatus === 'success') {
    return (
      <div className='flex flex-row gap-2 mt-[10px] mb-[20px] items-center'>

        {/* File Link */}
        <a
          className='w-full flex flex-row gap-x-2 items-center rounded-[5px] border-[1px] border-[#D8D8D8] border-[solid] bg-[white] px-[20px] py-[10px] text-base'
          href={attachedFile?.fileUrl}
          target='_blank'
          rel='noreferrer'
        >{attachedFile?.fileName}</a>

        {/* Clear Button */}
        <button className='h-[40px] bg-[var(--teal)] px-[15px] py-[0px] rounded-[5px] text-white font-medium hover:scale-[1.03] cursor-pointer' onClick={() => clearFileSelector()} > Clear </button>

      </div>
    )

  }

  //------------------------------------------------------
  //  error-fatal
  //------------------------------------------------------

  else if (uploadStatus === 'error-fatal') {
    return (
      <div className='flex flex-col gap-[10px 0px] mt-[5px] mb-[15px]'>
        <label className='w-full flex flex-row border-1 border-solid border-[darkred] text-[darkred] text-sm bg-[rgb(255,_211,_211)] rounded-[5px] px-3 py-2 my-2'>
          Failed to upload file.
          <button className='flex flex-row gap-1 items-center mx-1 font-semibold underline' disabled={attachedFile?.fileName?.length === 0 ? true : false} onClick={() => handleUploadStatus('onchange')}>
            <img src={TryAgain} alt='Try Again'></img>
            Try Again
          </button>
        </label>
      </div>
    )

  }

  //------------------------------------------------------
}
