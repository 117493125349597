//================================================================
//  Tab: Supporting Documentation
//================================================================

//  Purpose: This tab displays all the Supporting Documentation associated to this application

//  Example:
//    <SupportingDocumentation></SupportingDocumentation>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import DownloadFileBlob from '../../../../../Components/DownloadFileBlob/DownloadFileBlob';
import AddSupportingDocumentationModal from './Components/AddSupportingDocumentationModal';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';
import ConvertDate from '../../../../../Library/ConvertDate';

//Images
import IconSearch from '../../../../../Components/Images/Icon_Search_Grey.svg';
import IconUpload from '../../../../../Components/Images/Icon_Upload_White.svg';


export default function SupportingDocumentation() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Holds the application document 
  const [application, setApplication] = useState();

  // Search Input
  const [searchInput, setSearchInput] = useState('');

  // Holds the filtered documents
  const [docsFiltered, setDocsFiltered] = useState([]);

  // Open or close the upload pane
  const [openUploadPane, setOpenUploadPane] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Handles search function
  function handleSearch(value) {

    setSearchInput(value);

    // Reset the Filter
    if (value.length === 0) {

      setDocsFiltered(application.supportingdocumentation);

    };

    // Search Filter
    //  1. Contract ID
    //  2. Short Description
    const searchResults = application?.supportingdocumentation?.filter((object) =>
      object?.filename?.toLowerCase().includes(value.toLowerCase()) ||
      object?.filedescription?.toLowerCase().includes(value.toLowerCase())
    );

    // Save the filtered search results to the useState
    setDocsFiltered(searchResults);

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get Supporting Docs
  useEffect(() => {

    const applicationid = params.id;

    if (applicationid === '') return;
    if (applicationid === null) return;

    function onLoadChange(document) {

      let results = [];

      // Handle missing 'supportingdocumentation' key
      if (document?.supportingdocumentation !== undefined) {

        results = document?.supportingdocumentation;

      }

      setApplication(document);
      setDocsFiltered(results);

      setPageStatus('onload');

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('applications', applicationid, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='px-[20px]'>
          <div className='Table-Container'>

            {/* ======== Total, Upload & Search ========== */}
            <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

              <label className='font-semibold self-center'>
                Total: {docsFiltered?.length} of {application?.supportingdocumentation?.length}
              </label>

              {/* ======== Upload & Search  ========== */}
              <div className='flex flex-row gap-3'>

              {/* Upload File */}
              <button className='Primary-Button flex flex-row gap-2 items-center' onClick={() => setOpenUploadPane(true)}>
                <img src={IconUpload} alt='upload-icon'></img>
                Upload File
              </button>
              <AddSupportingDocumentationModal
                application={application}
                paneOpen={openUploadPane}
                setPaneOpen={setOpenUploadPane}
              ></AddSupportingDocumentationModal>

              {/* Search Bar */}
              <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>

                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                <label htmlFor='searchInput'>
                  <input
                    id='searchInput'
                    className='border-none h-[40px] p-0 m-0 outline-none'
                    type='text'
                    placeholder='Search'
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchInput}
                  ></input>
                </label>
              </div>

              </div>

            </div>

            {/* Files Table */}
            <table className='w-full max-h-96 overflow-y-scroll'>
              <thead>
                <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                  <th className='py-[15px] px-[20px] font-medium'>File Name</th>
                  <th className='py-[15px] px-[20px] font-medium'>Short Description</th>
                  <th className='py-[15px] px-[20px] font-medium'>Created By</th>
                  <th className='py-[15px] px-[20px] font-medium'>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  // No SupportingDocumentation found
                  docsFiltered?.length === 0 ?
                    (
                      <tr>
                        <td colSpan={5}>
                          <div className='text-center p-[2%]'>
                            No documentations found.
                          </div>
                        </td>
                      </tr>
                    )
                    :
                    (
                      // Map in each contract
                      docsFiltered?.map((doc, index) => (

                        <tr key={index} className='border-b-[#C8C8C9] border-solid border-b align-top last:border-none'>

                          {/* File Name */}
                          <td className='py-[15px] px-[20px]'>

                            <DownloadFileBlob
                              filename={doc?.filename}
                              filepath={doc?.filepath}
                            ></DownloadFileBlob>

                          </td>

                          {/* Short Description */}
                          <td className='py-[15px] px-[20px]'>
                            <div className='leading-normal overflow-hidden text-ellipsis'>
                              {doc?.filedescription}
                            </div>
                          </td>

                          {/* Created By */}
                          <td className='py-[15px] px-[20px]'>
                            <b className='font-medium'>{doc?.createdbyname}</b>
                            <br></br>
                            {doc?.createdbyemail}
                          </td>

                          {/* Created Date */}
                          <td className='py-[15px] px-[20px] min-w-[200px]'>
                            {ConvertDate(doc?.createddate)}
                          </td>

                        </tr>
                      ))
                    )
                  }
              </tbody>

            </table>

          </div>
        </div>

      }
    ></PageComponent>
  )
}
