//================================================================
//  Tab: Workflows
//================================================================

//  Purpose: This tab displays all 'Workflows' of an Application

//  Example:
//    <Workflows></Workflows>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images


export default function Workflows() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState();

  // Holds the application document from 
  const [applicationDoc, setApplicationDoc] = useState();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Overview with the status of 'Active'
  useEffect(() => {

    const applicationId = params.id;

    if (applicationId === '') return;
    if (applicationId === null) return;

    function onLoadChange(document) {

      setApplicationDoc(document);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('applications', applicationId, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col w-[50%] px-[20px]'>

          {/* Default App policies */}
          <div>

            <span className=' font-medium '>
              {
                applicationDoc?.defaultapp.length > 0 ? (
                  'Update an existing Default App policy'
                ): (
                  'Create a Default App policy'
                )
              }
            </span>

            <p className='mt-2'>
              Enable our automated workflows to manage users based off Azure AD changes.
              <br></br><br></br>
              <i>I authorize ITM to automatically:</i>
              <ul className='mt-2'>
                <li className='list-disc'>Subscribe <strong>active</strong> Lendlease Employees to this application.</li>
                <li className='list-disc'>Unsubscribe <strong>inactive</strong> Lendlease Employees from this application.</li>
              </ul>
            </p>
          </div>

          <LiveField
            styleInput={{height: '350px'}}
            type='multiselect'
            collectionid='applications'
            documentid={applicationDoc?.applicationid}
            fieldpath={'defaultapp'}
            label={<label className='font-medium'>Select regions and/or business units</label>}
            defaultValue={applicationDoc?.defaultapp}
            options={[
              'All',
              'AU - Investment Management',
              'AU - Construction',
              'Construction',
              'AU - Development',
              'AU - Communities',
              'AU - Capella',
              'AU - Defence',
              'Defence',
              'Operations',
              'Group - CA&M',
              'Group - EH&S',
              'Group - Risk',
              'Group - Finance',
              'Group - Legal',
              'Group - Treasury',
              'Group - Sustainability',
              'Group - Internal Audit',
              'Group - IT',
              'Group - People and Culture',
              'Podium',
              'AUST',
              'AMER',
              'EUR/UK',
              'ASIA',
            ]}
          ></LiveField>

        </div>
      }
    ></PageComponent>
  )
}
