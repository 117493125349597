//================================================================
//  Component: Status Label
//================================================================

//  Purpose: This is a label component displaying the status of a business request

//  Properties:
//    - status = {A string, this contains the business request status}

//  StatusLabel:
//    <StatusLabel
//      status={request}
//    ></StatusLabel>    

//================================================================

//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images

//CSS
import './StatusLabel.css';


export default function StatusLabel({
  status
}) { 

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div>
      {
        [

          {'name': 'Active', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#3C7D0F', 'status': 'Active'}, 
          {'name': 'Open', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#3C7D0F', 'status': 'Open'}, 
          {'name': 'Draft', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'Draft'}, 
          {'name': 'New', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'New'}, 
          {'name': 'Not Started', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'Not Started'},
          {'name': 'In Progress', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'In Progress'}, 
          {'name': 'Completed', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'Completed'},
          {'name': 'Cancelled', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'Cancelled'},
          {'name': 'Rejected', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'Rejected'}, 
          {'name': 'Expired', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Expired'}, 
          {'name': 'Divest', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'Divest'}, 
          {'name': 'Dormant', 'background': '#F2FFFF', 'color': '#00A7A4', 'bordercolor': '#00A7A4', 'status': 'Dormant'}, 
          {'name': 'On Hold', 'background': '#F2FFFF', 'color': '#00A7A4', 'bordercolor': '#00A7A4', 'status': 'On Hold'}, 
          {'name': 'Archived', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Archived'}, 
          {'name': 'Decommissioned', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'Decommissioned'}, 
          {'name': 'None', 'background': '#F5F5F5', 'color': 'black', 'bordercolor': '#616161', 'status': ''}, 

          

          // TODO - Check commercial agreement statuses with Ellyica & Commercial guys

        ].filter((style) => style.status === status).map((object, index) => (
          <label key={index} style={{backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}`}}  className='Status-Label'>
            {object.name}
          </label>
        ))
      }
    </div>
  )
}
