  //================================================================
//  Tab: Configuration
//================================================================

//  Purpose: This tab displays all 'Configuration' of an Application

//  Example:
//    <Configuration></Configuration>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images


export default function Configuration() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState();
  
    // Holds the application document from 
    const [applicationDoc, setApplicationDoc] = useState();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Overview with the status of 'Active'
  useEffect(() => {

    const applicationId = params.id;

    if (applicationId === '') return;
    if (applicationId === null) return;

    function onLoadChange(document) {

      setApplicationDoc(document);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('applications', applicationId, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);
  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='flex flex-col w-[50%] px-[20px]'>

            <LiveField
              type='choice'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'type'}
              label={<label className='font-medium'>Application Type</label>}
              defaultValue={applicationDoc?.type}
              options={['COTS', 'Product', 'Integration', 'Custom', 'N/A']}
            ></LiveField>

            <LiveField
              type='text'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'deploymentname'}
              label={<label className='font-medium'>Deployment Name</label>}
              defaultValue={applicationDoc?.deploymentname}
              placeholder={'The name of the licenced instanced, Aconex AU1, etc'}
            ></LiveField>

            <LiveField
              type='choice'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'deploymenttype'}
              label={<label className='font-medium'>Deployment Type</label>}
              defaultValue={applicationDoc?.deploymenttype}
              options={['Web Application', 'Desktop Application', 'Mobile Application', 'Server Application', 'N/A']}
            ></LiveField>

            <LiveField
              type='choice'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'supportedby'}
              label={<label className='font-medium'>Supported By</label>}
              defaultValue={applicationDoc?.supportedby}
              options={[
                'Accenture',
                'CapGemini',
                'Trident',
                'Business',
                'Deloitte',
                'Other',
              ]}
            ></LiveField>

            <LiveField
              type='choice'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'supportlevelinternal'}
              label={<label className='font-medium'>Support Level (Internal)</label>}
              defaultValue={applicationDoc?.supportlevelinternal}
              options={[
                'Level 1',
                'Level 2',
                'Level 3',
                'N/A',
              ]}
            ></LiveField>

            <LiveField
              type='choice'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'supportlevelexternal'}
              label={<label className='font-medium'>Supported Level (External)</label>}
              defaultValue={applicationDoc?.supportlevelexternal}
              options={[
                'Level 1',
                'Level 2',
                'Level 3',
                'N/A',
              ]}
            ></LiveField>

            <LiveField
              type='choice'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'ssoimplemented'}
              label={<label className='font-medium'>SSO Implemented</label>}
              defaultValue={applicationDoc?.ssoimplemented}
              options={[
                'Yes',
                'No',
                'N/A',
              ]}
            ></LiveField>


            <LiveField
              type='choice'
              collectionid='applications'
              documentid={applicationDoc?.applicationid}
              fieldpath={'mfaimplemented'}
              label={<label className='font-medium'>MFA Implemented</label>}
              defaultValue={applicationDoc?.mfaimplemented}
              options={[
                'Yes',
                'No',
                'N/A',
              ]}
            ></LiveField>
            
          </div>
        }
      ></PageComponent>
    )
}
