//================================================================
//  Page: Transformation Item
//================================================================

//  Purpose: This page shows the current Transformation Item

//  Example:
//    <TransformationItem></TransformationItem>    

//================================================================


// Libraries
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

// Contexts

// Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import TabView from '../../../../Components/TabView/TabView';
import BreadCrumbs from '../../../../Components/Breadcrumbs/BreadCrumbs';

// Tabs
import Overview from './Tabs/Overview';

// Functions

// Images

// CSS
import './TransformationItem.css';

 
export default function TransformationItem() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
  
    const routerUseLocation = useLocation();
    const params = useParams();

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container'>

            <BreadCrumbs
              rootLinkName='Process Transform Register'                      
              rootLinkRoute={`/${routerUseLocation.pathname.split('/')[1]}?view=transformationregister`}
              childLinkName={routerUseLocation.pathname.split('/')[3]}
            ></BreadCrumbs>   

            <p className='font-medium text-[20px] mb-0'>Process Transform Register</p>

          </div>
        }
        body={
          <div>

            <TabView
              defaultView={1}

              //------------------------------------------------------
              // Overview
              //------------------------------------------------------

              oneTitle={'Overview'}
              oneRoute ={`/inventory/transformationregister/${params.id}?view=overview`}
              oneContent={
                <Overview></Overview>
              }

            ></TabView>    

          </div>
        }
      ></PageComponent> 
    )
}
