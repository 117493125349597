//================================================================
//  Component: Sort Filter ~ Filterby Side Pane
//================================================================

//  Purpose: Collection of components that create a sorting and filtering experience for tables

//  Properties:
//    - tableArray = {useState, Parent useState with unfiltered data}
//
//
//    How to apply 'TableHeader' components
//    <>
//
//      <FilterTags
//          tableArray={agreements}
//          setFilteredTableArray={setAgreementsFiltered}
//      ></FilterTags>
//
//      <FilterByPane
//          tableArray={agreements}
//      ></FilterByPane>
//
//      <table className='w-full'>
//          <thead>
//              <tr>
//
//                  <TableHeader
//                    column='PoA Number'
//                    objectKey='vendor.vendorname'
//                    styleInput={{padding: '5px'}}
//                    sticky={true}
//                    dataType={''}
//                  ></TableHeader>
//  
//                  ...insert all table headers                
//  
//              </tr>
//
//          ...insert table body   
//
//    </>
//
//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';

//Contexts
import { SetToast, GetSortFilters, SetSortFilters } from '../../Library/GlobalContexts';

//Images
import CloseIcon from '../Images/Icon_Clear_Grey.svg';
import IconSearch from '../Images/Icon_Search_Grey.svg';


export default function FilterByPane({
    tableArray,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    const getSortFilters = useContext(GetSortFilters);
    const setSortFilters = useContext(SetSortFilters);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Sorting and filtering menu
    const [filterOptions, setFilterOptions] = useState([]);                     // all filter options (checkboxes)
    const [filterOptionsFiltered, setFilterOptionsFiltered] = useState([]);     // filter options (checkboxes) filtered by search
    const [selectedFilters, setSelectedFilters] = useState([]);                 // selected filter options

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onChange
    //  1. Triggered by the sidepane opening
    //  2. Populates the 'selectedFilters' useState
    useEffect(() => {

        if (getSortFilters.filterByPane.columnname === '') return;
        if (selectedFilters.length > 0) return;
        if (getSortFilters.filters[getSortFilters.filterByPane.columnid] === undefined) return;

        setSelectedFilters(getSortFilters.filters[getSortFilters.filterByPane.columnid].values);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSortFilters.filterByPane.columnname]);

    // onChange
    //  1. Triggered by the sidepane opening
    //  2. Populates the 'filterOptions' useState... Sorry it has to be this complicated due to stacking of filters :-(
    useEffect(() => {

        if (tableArray === undefined) return;
        if (getSortFilters.filterByPane.columnname === '') return;
        if (getSortFilters.filterByPane.columnid === '') return;

        //------------------------------------------------------
        // Variables
        //------------------------------------------------------

        const filters = Object.keys(getSortFilters.filters);

        // Holds the filtered values
        let results = tableArray;

        // Options that appear in the sidepane
        const arrayOfOptions = [];

        //------------------------------------------------------
        // Function to handle filters with nested values
        //------------------------------------------------------

        const deepTraversal = (object, filterKey) => {

            if (filterKey.includes('.') === false) return object[filterKey];

            // Found nested keys
            const keys = filterKey.split('.');

            if (keys.length === 2) return object[keys[0]]?.[keys[1]]; // { 'parentKey': { 'childKey1': { 'childKey2': 'filterValue' }}}
            if (keys.length === 3) return object[keys[0]]?.[keys[1]]?.[keys[2]]; // { 'parentKey': { 'childKey1': { 'childKey2': { 'childKey3': 'filterValue' }}}}
            if (keys.length === 4) return object[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]];  // { 'parentKey': { 'childKey1': { 'childKey2': { 'childKey3': { 'childKey4': 'filterValue' }}}}}

            setToast({
                'type': 'error',
                'message': `Fatal Error - Maximum traversal limit reached on filter '${filterKey}'`,
            });

        };

        //------------------------------------------------------
        // Handle stacking of filters
        // - each filter needs to be applied
        // - Outcome is an array of options, that are PRESENT in the column!
        //------------------------------------------------------

        filters.forEach((filterKey) => {

            // Don't apply the current filter over the current column
            if (filterKey === getSortFilters.filterByPane.columnid) return;

            const filterValues = getSortFilters.filters[filterKey].values;

            const tempResults = [];

            // Stack each filter
            filterValues.forEach((filterValue) => {

                try {

                    tempResults.push(
                        ...results.filter((object) => deepTraversal(object, filterKey)?.toLowerCase()?.includes(filterValue?.toLowerCase())),
                    );

                } catch (error) {

                    // Set error on toast
                    setToast({
                        'type': 'error',
                        'message': `Failed to apply filter as it doesn't align with the database schema.`,
                    });

                }

            });

            results = tempResults;

        });

        //------------------------------------------------------
        // Extract an array of values for sidepane
        // - [ Google, Microsoft, etc]
        //------------------------------------------------------

        // This enforces unique values in the filter options for every row that is accessible
        results.forEach((object) => {

            const value = deepTraversal(object, getSortFilters.filterByPane.columnid);

            if (arrayOfOptions.includes(value)) return;
            if (value === undefined) return;
            if (value.length === 0) return;

            arrayOfOptions.push(value);

        });

        setFilterOptionsFiltered(arrayOfOptions);
        setFilterOptions(arrayOfOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSortFilters]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (getSortFilters.filterByPane.columnname === '') return null;

    return (

        <div style={{ background: 'rgba(0, 0, 0, 0.2)' }} className='Pane-Background'>

            {/* Pane Container */}
            <dialog className='Pane-Container'>

                <div className='flex flex-col gap-3 w-full justify-between'>

                    {/* ===================================== */}
                    {/*  Header                               */}
                    {/* ===================================== */}

                    <div className='flex flex-row justify-between'>

                        <h4 className=''> Filter By: <b className='font-medium uppercase '></b>{getSortFilters.filterByPane.columnname}</h4>
                        <img className='w-[24px] cursor-pointer' src={CloseIcon} alt='close-icon' onClick={() => {

                            getSortFilters.filterByPane = {
                                'columnname': '',
                                'columnid': '',
                            };
                            setSortFilters({ ...getSortFilters });

                        }}></img>

                    </div>

                    {/* ======== Search Bar ======== */}
                    <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-full'>
                        <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                        <label htmlFor='searchInput'>
                            <input
                                className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                type='text'
                                placeholder='Search'
                                onChange={(e) => {

                                    const value = e.target.value;

                                    if (value.length === 0) {

                                        setFilterOptionsFiltered(filterOptions);

                                    }

                                    const searchResults = filterOptions.filter((string) => string.toLowerCase().includes(value.toLowerCase()));

                                    setFilterOptionsFiltered(searchResults);

                                }}
                                autoComplete='no'
                            ></input>
                        </label>
                    </div>

                    {/* ===================================== */}
                    {/*  Body                                 */}
                    {/* ===================================== */}

                    <div className='flex flex-col gap-2 h-[80vh] overflow-y-auto'>

                        {

                            filterOptionsFiltered?.map((value, index) => (

                                <label key={index} style={{ fontSize: '16px' }} className='Checkbox-Container'>
                                    <input
                                        type='checkbox'
                                        value={value}
                                        checked={selectedFilters.includes(value)}
                                        onChange={() => {

                                            // Uncheck and remove from useState
                                            if (selectedFilters.includes(value)) {

                                                selectedFilters.splice(selectedFilters.indexOf(value), 1);
                                                setSelectedFilters([
                                                    ...selectedFilters,
                                                ]);

                                                return;

                                            };

                                            setSelectedFilters([...selectedFilters, value]);

                                        }}
                                    ></input>
                                    <span className='Checkbox'></span>
                                    {value}
                                </label>
                            ))

                        }

                    </div>

                    {/* ------------------------------- */}
                    {/*  Buttons                        */}
                    {/* ------------------------------- */}

                    <div className='flex flex-row gap-2'>

                        {/* ==== Apply ==== */}
                        <button className='Primary-Button' onClick={() => {

                            // Update THIS filter with the new values
                            getSortFilters.filters[getSortFilters.filterByPane.columnid] = {
                                'columnname': getSortFilters.filterByPane.columnname,
                                'values': selectedFilters,
                            };
                            getSortFilters.filterByPane = {
                                'columnname': '',
                                'columnid': '',
                            }

                            setSelectedFilters([]);

                            // Apply filters
                            setSortFilters({ ...getSortFilters });

                        }}>
                            Apply
                        </button>

                        {/* ==== Clear all ==== */}
                        <button className='Secondary-Button' onClick={() => {

                            delete getSortFilters.filters[getSortFilters.filterByPane.columnid];
                            getSortFilters.filterByPane = {
                                'columnname': '',
                                'columnid': '',
                            }

                            setSelectedFilters([]);

                            // Apply filters
                            setSortFilters({ ...getSortFilters });

                        }}>
                            Clear all
                        </button>
                    </div>

                </div>

            </dialog>

        </div>

    )
}
