//================================================================
//  Component: Lookup React Hook Form
//================================================================

//  Purpose: This is an input field that allows you to search any Firestore collection with the 'searcharray' field

//  Properties:
//    - fieldId = {string, fieldid in React Hook Forms}
//    - collectionId = {string, Firestore collection id}
//    - dataField = {string, primary field key in Firestore Document, 'emailaddress', 'vendorname', etc}
//    - placeholder = {string, placeholder string}
//    - required = {boolean, this determines if this field is required}
//    - register = {react hooks forms state, connects the field to the form state}
//    - setValue = {react hooks form state, programmatically sets the value for a field}
//    - errors = {react hooks form state, stores the errors for the field}
//    - clearErrors = {react hooks form state, allows you to clear errors on a certain field}
//    - disabled = <Optional> {boolean, determines if the field is disabled}
//    - dataFieldSecondary = <Optional> {string, field key which is visible in the dropdown list, and saved to React Hook Forms}
//    - dataFieldTertiary = <Optional> {string, field key which is NOT VISIBLE in the dropdown list, but is saved to React Hook Forms. Tip: this is excellent for doc ids, file urls, etc}
//    - additionalQueries = <Optional> {array, additional Firestore queries}
//    - createNewHTML = <Optional> {HTML, generic html attached to the bottom of the search results}

//  Example:
//
//    <LookUpRHF
//      fieldId={'itowner'}
//      collectionId={'users'}
//      dataField={'emailaddress'}
//      placeholder={'Search user by email address'}
//      required={true}
//      register={register}
//      setValue={setValue}
//      errors={errors.itowner}
//      clearErrors={clearErrors}
//      dataFieldSecondary={'givenname'}
//      dataFieldTertiary={'surname'}
//      additionalQueries={['usertype', '==', 'lendleaseuser']}
//      createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <a href='https://google.com' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
//      disabled={task?.status === 'Completed'}
//    ></LookUpRHF>    


//  IMPORTANT: You can access, additional values found in the document through 'dataFieldSecondary' & 'dataFieldTertiary' props!
//
//     Referring to the above example. Within the onSubmit handler, you can get the users 'givenname' and 'surname'
//    
//     console.log('Givenname: ', formData['itowner-givenname'] );
//     console.log('Surname: ', formData['itowner-surname'] );


//  IMPORTANT: You can show two values in the dropdown list.
//  
//      Referring to the above example, 'emailaddress' (dataField prop) AND 'givenname' (dataFieldSecondary prop) will be shown in the dropdown list. 
//      Whereas 'surname' (dataFieldTertiary prop) will NOT be shown in the dropdown list.


//================================================================


//Libraries
import React, { useReducer } from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Images


export default function LookUpRHF({
    fieldId,
    collectionId,
    dataField,
    placeholder,
    required,
    register,
    setValue,
    errors,
    dataFieldSecondary,
    dataFieldTertiary,
    additionalQueries,
    createNewHTML,
    clearErrors,
    disabled
}) {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the search data
    const [searchData, setSearchData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
          'userInput': '',
          'queryMessage': '',
          'searchResults': []
        }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Function used to handle search
    const handleSearch = (value) => {

        setSearchData({
            'searchResults': [],
            'userInput': value,        
        })

        // Validate input
        if (searchData?.userInput?.length === 0) {

            return setSearchData({ 'queryMessage': 'Please enter a value.' });

        }

        if (searchData?.userInput?.length === 2) {

            return setSearchData({ 'queryMessage': 'Search requires more than two characters.' });

        }

        // Create query
        const query = [
            ['searcharray', 'array-contains-any', [searchData?.userInput?.toLowerCase()]],
        ];
        if (additionalQueries !== undefined) query.push(additionalQueries);

        // Search by characters in email
        QueryDocument(collectionId, query).then((docs) => {

            setSearchData({
                'searchResults': docs,
                'queryMessage': `Showing ${docs?.length} result(s)`
            });

        }).catch((error) => {

            console.log(error);
            setSearchData({ 'queryMessage': 'Oops something went wrong!' });

        });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
        <div className='flex flex-col w-full h-full relative'>

            {/* ------------------------------------------------------ */}
            {/*  Users Search Bar                                      */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center mb-0'>

                {/* Search Input Field */}
                <input
                    className={
                        errors ?
                        'min-w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border-[1.5px] border-solid border-[#DE0000] rounded-[5px] disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                        : searchData?.searchResults?.length > 0 ?
                        'min-w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                        :
                        'min-w-[450px] h-[42px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] disabled:text-[#424242] disabled:bg-[#F8F8F8] disabled:cursor-text'
                    }
                    type='text'
                    placeholder={placeholder}
                    {...register(fieldId, { 
                        required: required ? 'This field is required' : false,
                    })}
                    onChange={(e) => handleSearch(e.target.value)}
                    onPaste={(e) => handleSearch(e.clipboardData.getData('Text'))}
                    autoComplete='off'
                    disabled={disabled}
                ></input>
            
            </div>

            {/* Search Results */}
            {
            searchData?.userInput?.length > 0 &&
            (
                <div className='absolute w-[450px] z-10 top-10 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>
                    
                    {/* Results */}
                    {
                        searchData?.searchResults.map((document, index) => (
                        <div 
                            key={index} 
                            className='flex flex-row justify-between px-4 py-2 cursor-pointer rounded-sm hover:bg-[#F0F0F0]' 
                            onClick={() => {

                                // Save values
                                setValue(fieldId, document?.[dataField]);
                                setValue(`${fieldId}-${dataFieldSecondary}`, document?.[dataFieldSecondary]);
                                setValue(`${fieldId}-${dataFieldTertiary}`, document?.[dataFieldTertiary]);
                                clearErrors !== undefined && clearErrors(fieldId);

                                // Update the search results
                                setSearchData({ 
                                    'searchResults': [],
                                    'userInput': '',
                                });

                            }}
                        >
            
                            {/* User Detail */}
                            <div className='flex flex-col'>
                                <p className='m-0 font-medium text-[14px]'>{document?.[dataFieldSecondary]}</p>
                                <p className='m-0 text-[14px]'>{document?.[dataField]}</p>
                            </div>
                        
                        </div>
                        ))
                    }
                    
                    {/* Total */}
                    <div className='flex flex-col text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchData.queryMessage.length === 0}>
                        {searchData.queryMessage}
                        {createNewHTML}
                    </div>

                </div>
            )
            }

            {/* Error Message */}
            {errors && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors?.message}</p>}

            
        </div>
    )

  //------------------------------------------------------
}
