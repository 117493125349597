// Library
import React, {useState} from 'react'

// Contexts:

// Auth config, status and errors
export const GetFireBaseProvider = React.createContext();
export const SetFireBaseProvider = React.createContext();
export const GetAppStatus = React.createContext();
export const SetAppStatus = React.createContext();
export const GetAppErrors = React.createContext();
export const SetAppErrors = React.createContext();
export const GetAppSize = React.createContext();
export const SetAppSize = React.createContext();

// Firebase user and profile
export const GetFireBaseUser = React.createContext();
export const SetFireBaseUser = React.createContext();
export const GetUser = React.createContext();
export const SetUser = React.createContext();

// Component and page errors
export const GetToast = React.createContext();
export const SetToast = React.createContext();

// Sort & Filtering
export const GetSortFilters = React.createContext();
export const SetSortFilters = React.createContext();


/**
 * Define global states that are shared across components without the need for props
 * 
 * @param {object} children
 * 
 * ```
 * <GlobalContexts>
 *     <App></App>
 * </GlobalContexts>
 * 
 * ```
 * 
 * Documentation
 * - https://www.w3schools.com/react/react_usecontext.asp
 * - https://stackoverflow.com/questions/64256481/referenceerror-cannot-access-usercontext-before-initialization-react
 * - https://mindsers.blog/post/updating-react-context-from-consumer/
 */

export default function GlobalContexts({children}) {

  //------------------------------------------------------
  //  Define a useState to each context
  //------------------------------------------------------

    const [fireBaseProvider, setFireBaseProvider] = useState();
    const [appStatus, setAppStatus] = useState('pending');
    const [appErrors, setAppErrors] = useState();
    const [appSize, setAppSize] = useState({
      'width': 0,
      'height': 0,
    });

    const [firebaseUser, setFirebaseUser] = useState();
    const [getUser, setUser] = useState();

    const [getToast, setToast] = useState({
      'type': '', // info, pending, success, warning, error
      'message': '', // String or HTML
    });

    const [getSortFilters, setSortFilters] = useState({

      // Sorting
      'sortBy': '', // vendor.vendorname
      'sortDirection': '', // ascending & descending
      'sortDataType': '', // '' or 'number'

      // Filtering (Map of objects)
      'filters': {

        // 'INSERT_FILTER_KEY_1': {                         
        //    'columnname': '',           // Column Display Name
        //    'values': [
        //      'filter value 1',
        //      'filter value 2',
        //      'filter value 3'
        //    ],  
        // },

        // 'vendor': {
        //    'columnname': 'Vendor Name',
        //    'values': [
        //      'Google',
        //      'Microsoft',
        //      'Amazon Web Services'
        //    ],  
        // }
        
      },

      // Sidepane for selecting filterby content
      'filterByPane': {
        'columnname': '', // Vendor Name
        'columnid': '', // vendor.vendorname
      }

    });

  //------------------------------------------------------
  //  Pass down all Parent components & contexts to childern
  //------------------------------------------------------

    return (

      // Firebase Provider
      <GetFireBaseProvider.Provider value={fireBaseProvider}>
      <SetFireBaseProvider.Provider value={setFireBaseProvider}>

      {/* App Status */}
      <GetAppStatus.Provider value={appStatus}>
      <SetAppStatus.Provider value={setAppStatus}>

      {/* App Errors */}
      <GetAppErrors.Provider value={appErrors}>
      <SetAppErrors.Provider value={setAppErrors}>

      <GetAppSize.Provider value={appSize}>
      <SetAppSize.Provider value={setAppSize}>

      {/* Firebase User */}
      <GetFireBaseUser.Provider value={firebaseUser}>
      <SetFireBaseUser.Provider value={setFirebaseUser}>

      {/* Current (Signed In) User */}
      <GetUser.Provider value={getUser}>
      <SetUser.Provider value={setUser}>

      {/* Errors */}
      <GetToast.Provider value={getToast}>
      <SetToast.Provider value={setToast}>

      {/* Sorting & Filters */}
      <GetSortFilters.Provider value={getSortFilters}>
      <SetSortFilters.Provider value={setSortFilters}>

        {children}

      </SetSortFilters.Provider>
      </GetSortFilters.Provider>

      </SetToast.Provider>
      </GetToast.Provider>

      </SetUser.Provider>
      </GetUser.Provider>

      </SetFireBaseUser.Provider>
      </GetFireBaseUser.Provider>

      </SetAppSize.Provider>
      </GetAppSize.Provider>

      </SetAppErrors.Provider>
      </GetAppErrors.Provider>

      </SetAppStatus.Provider>
      </GetAppStatus.Provider>
      
      </SetFireBaseProvider.Provider>
      </GetFireBaseProvider.Provider>
    )

  //------------------------------------------------------
}
