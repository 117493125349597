//================================================================
//  Tab: Allocation
//================================================================

//  Purpose: This tab displays all 'Allocation' of an Application

//  Example:
//    <Allocation></Allocation>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';
import UserPane from '../../../../../Components/UserPane/UserPane';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images
import EditIcon from '../../../../../Components/Images/Icon_Edit_Teal.svg';


export default function Allocation() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState();

  // Holds the application document from 
  const [applicationDoc, setApplicationDoc] = useState();

  // Open & Close the side panes
  const [openBudgetPane, setOpenBudgetPane] = useState(false);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Overview with the status of 'Active'
  useEffect(() => {

    const applicationId = params.id;

    if (applicationId === '') return;
    if (applicationId === null) return;

    function onLoadChange(document) {

      setApplicationDoc(document);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('applications', applicationId, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='px-[20px]'>
          <div className='flex justify-between'>

            <div className='flex flex-col w-[50%]'>

              <LiveField
                type='choice'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'typeofcost'}
                label={<label className='font-medium'>Type of Cost</label>}
                defaultValue={applicationDoc?.typeofcost}
                options={[
                  'Business Cost',
                  'Functional Technology Cost',
                  'Enterprise IT'
                ]}
              ></LiveField>

              <LiveField
                type='text'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'projectcode'}
                label={<label className='font-medium'>Project Code</label>}
                defaultValue={applicationDoc?.projectcode}
                placeholder={'26001'}
              ></LiveField>

              <LiveField
                type='text'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'tasknumber'}
                label={<label className='font-medium'>Task Number</label>}
                defaultValue={applicationDoc?.tasknumber}
                placeholder={'1000.1100'}
              ></LiveField>

              <LiveField
                type='text'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'expendituretype'}
                label={<label className='font-medium'>Expenditure Type</label>}
                defaultValue={applicationDoc?.expendituretype}
                placeholder={'ICT'}
              ></LiveField>

              <LiveField
                type='choice'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'licencetype'}
                label={<label className='font-medium'>License Type</label>}
                defaultValue={applicationDoc?.licencetype}
                options={[
                  'Subscription',
                  'Perpetual',
                  'Concurrent',
                  'Other'
                ]}
              ></LiveField>

              <LiveField
                type='choice'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'costallocationcurrency'}
                label={<label className='font-medium'>Cost Allocation Currency</label>}
                defaultValue={applicationDoc?.costallocationcurrency}
                options={[
                  'USD',
                  'SGD',
                  'GGP',
                  'AUD'
                ]}
              ></LiveField>

              <LiveField
                type='number'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'nooflicensespurchased'}
                label={<label className='font-medium'>Number of Licenses Purchased</label>}
                defaultValue={applicationDoc?.nooflicensespurchased}
                placeholder={'100'}
              ></LiveField>

            </div>

            {/* ----------------------------------- */}
            {/*  Tiles                              */}
            {/* ----------------------------------- */}

            <div className='flex flex-col gap-3'>

              {/* Budget Owners */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> Budget Owners </label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setOpenBudgetPane(true)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='min-h-[40px] max-h-[160px] overflow-y-auto'>
                  <label className='py-[15px] px-[20px]'>
                    {applicationDoc?.budgetowner?.length} owner(s)
                  </label>
                </div>

              </div>

            </div>

          </div>

          {/* ----------------------------------- */}
          {/*  Side Panes                         */}
          {/* ----------------------------------- */}

          <UserPane
            title={`Budget Owners - ${applicationDoc?.applicationname}`}
            vendorname={applicationDoc?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={applicationDoc?.applicationid}
            fieldPath={'budgetowner'}
            defaultUsers={applicationDoc?.budgetowner}
            paneOpen={openBudgetPane}
            setPaneOpen={setOpenBudgetPane}
          ></UserPane>

        </div>
      }
    ></PageComponent>
  )
}
