//================================================================
//  Component: Sort Filter ~ Filter tags
//================================================================

//  Purpose: Collection of components that create a sorting and filtering experience for tables

//  Properties:
//    - tableArray = {useState, Parent useState with unfiltered data}
//    - setFilteredTableArray = {useState, Parent useState that will filter the table}
//
//
//    How to apply 'TableHeader' components
//    <>
//
//      <FilterTags
//          tableArray={agreements}
//          setFilteredTableArray={setAgreementsFiltered}
//      ></FilterTags>
//
//      <FilterByPane
//          tableArray={agreements}
//      ></FilterByPane>
//
//      <table className='w-full'>
//          <thead>
//              <tr>
//
//                  <TableHeader
//                    column='PoA Number'
//                    objectKey='vendor.vendorname'
//                    styleInput={{padding: '5px'}}
//                    sticky={true}
//                    dataType={''}
//                  ></TableHeader>
//  
//                  ...insert all table headers                
//  
//              </tr>
//
//          ...insert table body   
//
//    </>
//
//================================================================


//Libraries
import React, { useContext, useEffect } from 'react';

//Contexts
import { SetToast, GetSortFilters, SetSortFilters } from '../../Library/GlobalContexts';

//Components
import FilterTagsItem from './FilterTagsItem';

//Images


export default function FilterTags({
    tableArray,
    setFilteredTableArray,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    const getSortFilters = useContext(GetSortFilters);
    const setSortFilters = useContext(SetSortFilters);

    //------------------------------------------------------
    // Function to handle filters with nested values
    //------------------------------------------------------

    const deepTraversal = (object, filterKey) => {

        if (filterKey.includes('.') === false) return object[filterKey];

        // Found nested keys
        const keys = filterKey.split('.');

        if (keys.length === 2) return object[keys[0]]?.[keys[1]]; // { 'parentKey': { 'childKey1': { 'childKey2': 'filterValue' }}}
        if (keys.length === 3) return object[keys[0]]?.[keys[1]]?.[keys[2]]; // { 'parentKey': { 'childKey1': { 'childKey2': { 'childKey3': 'filterValue' }}}}
        if (keys.length === 4) return object[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]];  // { 'parentKey': { 'childKey1': { 'childKey2': { 'childKey3': { 'childKey4': 'filterValue' }}}}}

        setToast({
            'type': 'error',
            'message': `Fatal Error - Maximum traversal limit reached on filter '${filterKey}'`,
        });

    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onChange
    //  1. Applies ALL Sorting & Filtering
    //  2. Nested here to prevent performance issues, as this component unmounts with the table!
    useEffect(() => {

        if (getSortFilters.sortBy === undefined) return;
        if (getSortFilters.sortDirection === undefined) return;
        if (tableArray === undefined) return;

        //------------------------------------------------------
        // Variables
        //------------------------------------------------------

        const filters = Object.keys(getSortFilters.filters);

        const sortBy = getSortFilters.sortBy;

        const sortDirection = getSortFilters.sortDirection;

        // Holds the results
        let results = tableArray;

        //------------------------------------------------------
        //  Filtering
        //------------------------------------------------------

        filters.forEach((filterKey) => {

            const filterValues = getSortFilters.filters[filterKey].values;

            const tempResults = [];

            // Stack each filter
            filterValues.forEach((filterValue) => {

                try {

                    tempResults.push(
                        ...results.filter((object) => deepTraversal(object, filterKey)?.toLowerCase()?.includes(filterValue?.toLowerCase())),
                    );

                } catch (error) {

                    // Set error on toast
                    setToast({
                        'type': 'error',
                        'message': `Failed to apply filter as it doesn't align with the database schema.`,
                    });

                }

            });

            results = tempResults;

        });

        //------------------------------------------------------
        //  Sorting
        //------------------------------------------------------

        // ascending
        if (sortDirection === 'ascending') {

            results = results.sort((a, b) => {

                // Extract nested values if the column is e.g. vendor.vendorname
                let aVal = deepTraversal(a, sortBy);
                let bVal = deepTraversal(b, sortBy);

                // Sometimes we will need to convert the data before sorting, e.g. contract amount from string to number to sort by highest to lowest
                if (getSortFilters.sortDataType === 'number') {

                    if (typeof (aVal) === 'string') aVal = parseInt(aVal?.replaceAll(',', ''));
                    if (isNaN(aVal)) aVal = 0; // If the value is an empty string/NaN return 0
                    if (typeof (bVal) === 'string') bVal = parseInt(bVal?.replaceAll(',', ''));
                    if (isNaN(bVal)) bVal = 0;

                }

                if (typeof (aVal) === 'string') {

                    if (aVal?.toLocaleLowerCase() > bVal?.toLocaleLowerCase()) return -1
                    if (aVal?.toLocaleLowerCase() > bVal?.toLocaleLowerCase()) return 1;
                    return 0;
                }

                return bVal - aVal;

            });

        }

        // descending
        if (sortDirection === 'descending') {

            results = results.sort((a, b) => {

                // Extract nested values if the column is e.g. vendor.vendorname
                let aVal = deepTraversal(a, sortBy);
                let bVal = deepTraversal(b, sortBy);

                // Sometimes we will need to convert the data before sorting, e.g. contract amount from string to number to sort by highest to lowest
                if (getSortFilters.sortDataType === 'number') {

                    if (typeof (aVal) === 'string') aVal = parseInt(aVal?.replaceAll(',', ''));
                    if (isNaN(aVal)) aVal = 0;
                    if (typeof (bVal) === 'string') bVal = parseInt(bVal?.replaceAll(',', ''));
                    if (isNaN(bVal)) bVal = 0;

                }

                if (typeof (aVal) === 'string') {

                    if (aVal?.toLocaleLowerCase() < bVal?.toLocaleLowerCase()) return -1
                    if (aVal?.toLocaleLowerCase() < bVal?.toLocaleLowerCase()) return 1;
                    return 0;
                }

                return aVal - bVal;

            });

        }

        //------------------------------------------------------
        //  Update Parent useState
        //------------------------------------------------------

        setFilteredTableArray([...results]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSortFilters, tableArray]);

    // un-mount
    //  1. Clear useContext when table unmounts!
    useEffect(() => {

        return () => {

            setSortFilters({

                // Sorting
                'sortBy': '', // vendor.vendorname
                'sortDirection': '', // ascending & descending
                'sortDataType': '', // '' or 'number'
          
                // Filtering (Map of objects)
                'filters': {},
          
                // Sidepane for selecting filterby content
                'filterByPane': {
                  'columnname': '', // Vendor Name
                  'columnid': '', // vendor.vendorname
                }
          
            });
           
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div className='flex flex-row gap-2 items-center' hidden={Object.keys(getSortFilters.filters).length === 0}>
            Filters: 
            {

                Object.keys(getSortFilters.filters)?.map((filterKey, index) => (

                    <FilterTagsItem
                        index={index}
                        columnName={getSortFilters.filters[filterKey].columnname}
                        filterKey={filterKey}
                        filterValues={getSortFilters.filters[filterKey].values}
                    ></FilterTagsItem>

                ))

            }
        </div>
    
    )
}
