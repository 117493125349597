//================================================================
//  Tab: Overview
//================================================================

//  Purpose: This tab displays all 'Overview' of a Vendor

//  Example:
//    <Overview></Overview>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import LiveField from '../../../../../Components/LiveField/LiveField';
import CountryList from '../../../../../Library/CountryList.json';

//Functions
import QueryListener from '../../../../../Library/QueryListener';

//Images


export default function Overview() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Holds the application document from 
  const [vendorDoc, setVendorDoc] = useState();

  //------------------------------------------------------
  //  Global variables
  //------------------------------------------------------

  const countryList = [];

  CountryList.map((country) => (
    countryList.push(country.name)
  ));

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get Firestore document
  useEffect(() => {

    const vendorid = params.id;

    if (vendorid === '') return;
    if (vendorid === null) return;

    function onLoadChange(documents) {

      setVendorDoc(documents[0]);
      setPageStatus('onload');

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = QueryListener('vendors', [['vendorid', '==', vendorid]], onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col w-[50%] px-[10px]'>

          {/* Name */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'vendorname'}
            label={<label className='font-medium'>Name</label>}
            defaultValue={vendorDoc?.vendorname}
            type={'text'}
            disabled={true}
          ></LiveField>

          {/* Registration Number */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'registrationnumber'}
            label={<label className='font-medium'>Company Registration Number</label>}
            defaultValue={vendorDoc?.registrationnumber}
            type={'text'}
            placeholder={'12345678'}
            disabled={false}
          ></LiveField>

          {/* Trading as Name */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'tradingas'}
            label={<label className='font-medium'>Trading as Name</label>}
            defaultValue={vendorDoc?.tradingas}
            type={'text'}
            placeholder={'Google Cloud'}
            disabled={false}
          ></LiveField>

          {/* Street */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'address.street'}
            label={<label className='font-medium'>Street</label>}
            defaultValue={vendorDoc?.address.street}
            type={'text'}
            placeholder={'16 James Street'}
            disabled={false}
          ></LiveField>

          {/* City */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'address.city'}
            label={<label className='font-medium'>City</label>}
            defaultValue={vendorDoc?.address.city}
            type={'text'}
            placeholder={'Melbourne'}
            disabled={false}
          ></LiveField>

          {/* Country */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'address.country'}
            label={<label className='font-medium'>Country</label>}
            defaultValue={vendorDoc?.address.country}
            type={'choice'}
            disabled={false}
            options={countryList}
          ></LiveField>

          {/* Zip / Postal Code */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'address.postcode'}
            label={<label className='font-medium'>Zip / Postal Code</label>}
            defaultValue={vendorDoc?.address.postcode}
            type={'text'}
            placeholder={'3001'}
            disabled={false}
          ></LiveField>

          {/* Website */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'website'}
            label={<label className='font-medium'>Website</label>}
            defaultValue={vendorDoc?.website}
            type={'text'}
            placeholder={'https://www.google.com.au'}
            disabled={false}
          ></LiveField>

          {/* Contact Name */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'contactname'}
            label={<label className='font-medium'>Contact Name</label>}
            defaultValue={vendorDoc?.contactname}
            type={'text'}
            placeholder={'Jane'}
            disabled={false}
          ></LiveField>

          {/* Contact Phone */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'contactnumber'}
            label={<label className='font-medium'>Contact Phone</label>}
            defaultValue={vendorDoc?.contactnumber}
            type={'text'}
            placeholder={'+61 400 000 000'}
            disabled={false}
          ></LiveField>

          {/* Contact Email */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'contactemail'}
            label={<label className='font-medium'>Contact Email</label>}
            defaultValue={vendorDoc?.contactemail}
            type={'text'}
            placeholder={'jane@gmail.com'}
            disabled={false}
          ></LiveField>

          {/* Status */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'vendorstatus'}
            label={<label className='font-medium'>Status</label>}
            defaultValue={vendorDoc?.vendorstatus}
            type={'choice'}
            options={['Active', 'Decommissioned']}
            disabled={false}
          ></LiveField>

          {/* Last Modified By */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'lastmodifiedby'}
            label={<label className='font-medium'>Last Modified By</label>}
            defaultValue={vendorDoc?.lastmodifiedby?.email}
            type={'text'}
            disabled={true}
          ></LiveField>

          {/* Last Modified Date */}
          <LiveField
            collectionid={'vendors'}
            documentid={vendorDoc?.vendorid}
            fieldpath={'lastmodifieddate'}
            label={<label className='font-medium'>Last Modified Date</label>}
            defaultValue={Date(vendorDoc?.lastmodifieddate)}
            type={'text'}
            disabled={true}
          ></LiveField>

          <br />
        </div>
      }
    ></PageComponent>
  )
}
