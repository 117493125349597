//================================================================
//  Component: Add Transformation Item
//================================================================

//  Purpose: Add a new item to the 'transformationregister' collection

//  Properties:
//   - N/A

//  Example:
//    <AddTransformationItem></AddTransformationItem>    

//================================================================


//Libraries
import React, { useContext, useState, useReducer, useEffect } from 'react';

//Contexts
import { GetUser, SetToast } from '../../../../Library/GlobalContexts';

//Components
import UserSearch from '../../../../Components/UserSearch/UserSearch';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';


export default function AddTransformationItem() {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Open or Close the 'Add Transformation Item' side pane
    const [AddTransformationItem, setAddTransformationItem] = useState(false);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store the search data
    const [formData, setFormData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {    
            'processname': '',
            'processnameErrorMessage': '',

            'processdescription': '',
            'processdescriptionErrorMessage': '',

            'proposedchanges': '',
            'proposedchangesErrorMessage': '',

            'proposedbenefits': '',
            'proposedbenefitsErrorMessage': '',

            'changescompleted': '',
            'changescompletedErrorMessage': '',

            'impactassessment': '',
            'impactassessmentErrorMessage': '',

            'capabilityarea': '',
            'capabilityareaErrorMessage': '',

            // capabilityserviceowner
            'newUserInput': '', // selected user from SearchUser component
            'searchResults': '',
            'newUserName': '',
            'newGivenname': '',
            'newSurname': '',
            'queryMessage': '',
            'capabilityserviceownerErrorMessage': '',

            'estcosttochange': '',
            'estcosttochangecurrency': 'USD',
            'estcosttochangeErrorMessage': '',

            'estsavings': '',
            'estsavingscurrency': 'USD',
            'estsavingsErrorMessage': '',

            'startdate': '',
            'startdateErrorMessage': '',

            'enddate': '',
            'enddateErrorMessage': '',

            'status': 'New', // New, Open, Closed & On Hold

            'formSubmissionError': '', // Incomplete form or Firebase errors
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Create the new Process Transformation item in Firestore
    const submitHandler = () => {

        // Open the toast
        setToast({
            'type': 'pending',
            'message': 'Preparing transformation item',
        });

        let preventSubmit = false;

        // Validation
        if (formData.processname.length === 0) {

            setFormData({'processnameErrorMessage': 'Enter a Process Name'});
            preventSubmit = true;

        } else {

            setFormData({'processnameErrorMessage': ''});

        }

        if (formData.processdescription.length === 0) {

            setFormData({'processdescriptionErrorMessage': 'Enter a Process Description'});
            preventSubmit = true;

        } else {

            setFormData({'processdescriptionErrorMessage': ''});

        }

        if (formData.proposedchanges.length === 0) {

            setFormData({'proposedchangesErrorMessage': 'Enter the Proposed Changes'});
            preventSubmit = true;
        } else {

            setFormData({'proposedchangesErrorMessage': ''});

        }
        
        if (formData.proposedbenefits.length === 0) {

            setFormData({'proposedbenefitsErrorMessage': 'Enter the Proposed Benefits'});
            preventSubmit = true;

        } else {

            setFormData({'proposedbenefitsErrorMessage': ''});

        }

        if (formData.impactassessment.length === 0) {

            setFormData({'impactassessmentErrorMessage': 'Enter an Impact Assessment'});
            preventSubmit = true;

        } else {

            setFormData({'impactassessmentErrorMessage': ''});

        }

        if (formData.capabilityarea.length === 0) {

            setFormData({'capabilityareaErrorMessage': 'Enter the Capability Area'});
            preventSubmit = true;

        } else {

            setFormData({'capabilityareaErrorMessage': ''});

        }

        if (formData.newUserInput.length === 0) {

            setFormData({'capabilityserviceownerErrorMessage': 'Enter the Capability / Service Owner'});
            preventSubmit = true;

        } else {

            setFormData({'capabilityserviceownerErrorMessage': ''});

        }

        if (formData.estcosttochange.length === 0) {

            setFormData({'estcosttochangeErrorMessage': 'Enter the Estimated Cost Change'});
            preventSubmit = true;

        } else {

            setFormData({'estcosttochangeErrorMessage': ''});

        }

        if (formData.estsavings.length === 0) {

            setFormData({'estsavingsErrorMessage': 'Enter the Estimated Cost Saving'});
            preventSubmit = true;

        } else {

            setFormData({'estsavingsErrorMessage': ''});

        }

        if (formData.startdate.length === 0) {

            setFormData({'startdateErrorMessage': 'Enter the Start Date'});
            preventSubmit = true;

        } else {

            setFormData({'startdateErrorMessage': ''});

        }

        if (formData.enddate.length === 0) {

            setFormData({'enddateErrorMessage': 'Enter the End Date'});
            preventSubmit = true;

        } else {

            setFormData({'enddateErrorMessage': ''});

        }

        // Prevent submission on errors
        if (preventSubmit === true) return setFormData({'formSubmissionError': 'Complete the form before proceeding'});
        setFormData({'formSubmissionError': ''});

        const documentObject = {
            'transformationid': `item-${Date.now()}`, // item-<timestamp>,
        
            'createdby': getUser.emailaddress,
            'createdbyname': `${getUser.givenname} ${getUser.surname}`, // Full Name (i.e. Nowshin Hassan)
            'createddate': new Date(),
            'lastmodifieddate': new Date(), // Timestamp, added by the system not a user editable field
            'lastmodifiedby': {
                'email': getUser.emailaddress,
                'givenname': getUser.givenname,
                'surname': getUser.surname
            },  

            'processname': formData.processname,
            'processdescription': formData.processdescription,
            'proposedchanges': formData.proposedchanges,
            'proposedbenefits': formData.proposedbenefits,
            'changescompleted': formData.changescompleted,
            'impactassessment': formData.impactassessment,
            'capabilityarea': formData.capabilityarea,
            'capabilityserviceowner': {
                'email': formData.newUserInput,
                'givenname': formData.newGivenname,
                'surname': formData.newSurname,
            }, 
            'estcosttochange': formData.estcosttochange,
            'estcosttochangecurrency': formData.estcosttochangecurrency,
            'estsavings': formData.estsavings,
            'estsavingscurrency': formData.estsavingscurrency,
            'status': formData.status, // New, Open, Closed & On Hold
            'startdate': formData.startdate,
            'enddate': formData.enddate,
        }

        WriteDocument('transformationregister', documentObject.transformationid, documentObject, false)
        .then(() => {

            // Reset form & close side pane
            setAddTransformationItem(false);

            // Show Success Toast
            setToast(
                {
                    'type': 'success',
                    'message': 
                        <>
                            Transformation item added. <a href={`https://inventory.store.lendlease.com/inventory/transformationregister/${documentObject.transformationid}?view=overview`} target='_blank' rel='noopener noreferrer'>{documentObject.transformationid}</a>.
                        </>
                }
            );

        })
        .catch((error) => {

            console.log('error', error);

            // Reset form & close side pane
            setAddTransformationItem(false);
                      
            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to create transformation item',
            });
            

        });
    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onChange
    // - Reset Form when sidepane is opened / closed
    useEffect(() => {

        setFormData(        {    
            'processname': '',
            'processnameErrorMessage': '',

            'processdescription': '',
            'processdescriptionErrorMessage': '',

            'proposedchanges': '',
            'proposedchangesErrorMessage': '',

            'proposedbenefits': '',
            'proposedbenefitsErrorMessage': '',

            'impactassessment': '',
            'impactassessmentErrorMessage': '',

            'capabilityarea': '',
            'capabilityareaErrorMessage': '',

            // capabilityserviceowner
            'newUserInput': '', // selected user from SearchUser component
            'searchResults': '',
            'newUserName': '',
            'newGivenname': '',
            'newSurname': '',
            'queryMessage': '',
            'capabilityserviceownerErrorMessage': '',

            'estcosttochange': '',
            'estcosttochangecurrency': 'USD',
            'estcosttochangeErrorMessage': '',

            'estsavings': '',
            'estsavingscurrency': 'USD',
            'estsavingsErrorMessage': '',

            'startdate': '',
            'startdateErrorMessage': '',

            'enddate': '',
            'enddateErrorMessage': '',

            'status': 'New', // New, Open, Closed & On Hold

            'formSubmissionError': '', // Incomplete form or Firebase errors
        });

    }, [AddTransformationItem]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (AddTransformationItem === false) return (

        <button className='Primary-Button whitespace-nowrap' onClick={() => setAddTransformationItem(true)}>
            <strong>+</strong> Add Process Transformation
        </button>

    );

    return (
        <>

            {/* ======== Keeps the button is visible during slide animation ========= */}
            <button className='Primary-Button whitespace-nowrap' onClick={() => setAddTransformationItem(true)}>
                <strong>+</strong> Add Process Transformation
            </button>

            {/* =========================================================== */}
            {/*  Add Agreement Pane                                         */}
            {/* =========================================================== */}

            <div className='Pane-Background'>

                <dialog className='Pane-Container flex flex-col justify-between'>

                    {/*  ========= Add Agreement Form * ========= */}
                    <div className='overflow-x-auto'>

                        {/* Header */}
                        <h4> Add Process Transformation </h4>

                        {/*  ========= 1. Process Name * ========= */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> 1. Process Name <span className='text-[#C4314B]'>*</span></label>
                            <input
                                className={formData.processnameErrorMessage ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                type='text'
                                placeholder='Enter Process Name'
                                value={formData.processname}
                                onChange={(e) => setFormData({'processname': e.target.value})}
                            />
                            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.processnameErrorMessage}</p>
                        </div>

                        {/*  ========= 2. Process Description * ========= */}
                        <div className='FormComponent-Row mr-2'>
                            <label className='font-medium'> 2. Process Description <span className='text-[#C4314B] h-0'><span className='text-[#C4314B]'>*</span></span></label>
                            <textarea
                                className={formData.processdescriptionErrorMessage ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                type='text'
                                placeholder='Enter Process Description'
                                value={formData.processdescription}
                                onChange={(e) => setFormData({'processdescription': e.target.value})}
                            />
                           <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.processdescriptionErrorMessage}</p>
                        </div>

                        {/*  ========= 3. Proposed Changes * ========= */}
                        <div className='FormComponent-Row  mr-2'>
                            <label className='font-medium'> 3. Proposed Changes <span className='text-[#C4314B]'>*</span></label>
                            <textarea
                                className={formData.proposedchangesErrorMessage ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                type='text'
                                placeholder='Enter Proposed Changes'
                                value={formData.proposedchanges}
                                onChange={(e) => setFormData({'proposedchanges': e.target.value})}
                            />
                           <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.proposedchangesErrorMessage}</p>
                        </div>

                        {/*  ========= 4. Proposed Benefits * ========= */}
                        <div className='FormComponent-Row mr-2'>
                            <label className='font-medium'> 4. Proposed Benefits <span className='text-[#C4314B]'>*</span></label>
                            <textarea
                                className={formData.proposedbenefitsErrorMessage ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                type='text'
                                placeholder='Enter Proposed Benefits'
                                value={formData.proposedbenefits}
                                onChange={(e) => setFormData({'proposedbenefits': e.target.value})}
                            />
                           <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.proposedbenefitsErrorMessage}</p>
                        </div>

                        {/*  ========= 5. Proposed Benefits * ========= */}
                        <div className='FormComponent-Row mr-2'>
                            <label className='font-medium'> 5. Changes Completed </label>
                            <textarea
                                className={formData.changescompletedErrorMessage ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                type='text'
                                placeholder='Enter the completed changes'
                                value={formData.changescompleted}
                                onChange={(e) => setFormData({'changescompleted': e.target.value})}
                            />
                           <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.changescompletedErrorMessage}</p>
                        </div>

                        {/*  ========= 6. Impact Assessment * ========= */}
                        <div className='FormComponent-Row mr-2'>
                            <label className='font-medium'> 6. Impact Assessment <span className='text-[#C4314B]'>*</span></label>
                            <textarea
                                className={formData.impactassessmentErrorMessage ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                type='text'
                                placeholder='Enter Impact Assessment'
                                value={formData.impactassessment}
                                onChange={(e) => setFormData({'impactassessment': e.target.value})}
                            />
                            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.impactassessmentErrorMessage}</p>
                        </div>
                        
                        {/*  ========= 7. Capability Area * ========= */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> 7. Capability Area <span className='text-[#C4314B]'>*</span></label>
                            <input
                                className={formData.capabilityareaErrorMessage ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                type='text'
                                placeholder='Enter Capability Area'
                                value={formData.capabilityarea}
                                onChange={(e) => setFormData({'capabilityarea': e.target.value})}
                            />
                            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.capabilityareaErrorMessage}</p>
                        </div>

                        {/*  ========= 8. Capability Area * ========= */}
                        <div className='FormComponent-Row w-[455px]'>
                            <label className='font-medium'> 8. Capability / Service Owner <span className='text-[#C4314B]'>*</span></label>
                            <UserSearch
                                selectedUser={formData}
                                setSelectedUser={setFormData}
                                errorMessage={formData.capabilityserviceownerErrorMessage}
                            ></UserSearch>

                        </div>

                        {/*  ========= 9. Est Cost To Save * ========= */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> 9. Est Cost to Save <span className='text-[#C4314B]'>*</span></label>

                            <div className='flex flex-row w-[445px] gap-2'>
                                <input
                                    className={formData.estcosttochangeErrorMessage ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    value={formData.estcosttochange}
                                    placeholder='10,000'
                                    onBlur={() => {

                                        let value = formData.estcosttochange.replaceAll(',','');

                                        // Format into something pretty
                                        value = new Intl.NumberFormat('en-GB').format(value);

                                        setFormData({'estcosttochange': value});

                                    }}
                                    onChange={(e) => {

                                        // Prevent non-numeric values!
                                        if (/^[0-9,]{0,30}$/g.test(e.target.value) === false) {


                                            return setFormData({'estcosttochange': formData.estcosttochange});

                                        }
                                        
                                        setFormData({'estcosttochange': e.target.value});

                                    }}
                                />

                                <select
                                    style={{width: '100px'}}
                                    className={formData.estcosttochangecurrencyErrorMessage ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                                    value={formData.estcosttochangecurrency}
                                    onChange={(e) => setFormData({'estcosttochangecurrency': e.target.value})}
                                >
                                    <option value='USD'>USD</option>
                                    <option value='SGD'>SGD</option>
                                    <option value='GBP'>GBP</option>
                                    <option value='AUD'>AUD</option>
                                </select>
                            </div>

                            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.estcosttochangeErrorMessage}</p>

                        </div>

                        {/*  ========= 10. Est Savings * ========= */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> 10. Est Savings <span className='text-[#C4314B]'>*</span></label>

                            <div className='flex flex-row w-[445px] gap-2'>
                                <input
                                    className={formData.estsavingsErrorMessage ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                    type='text'
                                    value={formData.estsavings}
                                    placeholder='10,000'
                                    onBlur={() => {

                                        let value = formData.estsavings.replaceAll(',','');
    
                                        // Format into something pretty
                                        value = new Intl.NumberFormat('en-GB').format(value);
    
                                        setFormData({'estsavings': value});
    
                                    }}
                                    onChange={(e) => {
    
                                        // Prevent non-numeric values!
                                        if (/^[0-9,]{0,30}$/g.test(e.target.value) === false) {
    
    
                                            return setFormData({'estsavings': formData.estcosttochange});
    
                                        }
                                        
                                        setFormData({'estsavings': e.target.value});
    
                                    }}
                                />

                                <select
                                    style={{width: '100px'}}
                                    className={formData.estsavingscurrencyErrorMessage ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                                    value={formData.estsavingscurrency}
                                    onChange={(e) => setFormData({'estsavingscurrency': e.target.value})}
                                >
                                    <option value='USD'>USD</option>
                                    <option value='SGD'>SGD</option>
                                    <option value='GBP'>GBP</option>
                                    <option value='AUD'>AUD</option>
                                </select>
                            </div>
                            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.estsavingsErrorMessage}</p>
                        
                        </div>

                        {/*  ========= 11. Start Date * ========= */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> 11. Start Date <span className='text-[#C4314B]'>*</span></label>
                            <input
                                className={formData.startdateErrorMessage ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                type='date'
                                value={formData.startdate}
                                onChange={(e) => setFormData({'startdate': e.target.value})}
                            />
                            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.startdateErrorMessage}</p>
                        </div>

                        {/*  ========= 12. End Date * ========= */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> 12. End Date <span className='text-[#C4314B]'>*</span></label>
                            <input
                                className={formData.enddateErrorMessage ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                type='date'
                                value={formData.enddate}
                                onChange={(e) => setFormData({'enddate': e.target.value})}
                            />
                           <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.enddateErrorMessage}</p>

                        </div>

                        {/*  ========= 13. Status * ========= */}
                        <div className='FormComponent-Row'>
                            <label className='font-medium'> 13. Status <span className='text-[#C4314B]'>*</span></label>
                            <select
                                className='Input-Field-Select'
                                value={formData.status}
                                onChange={(e) => setFormData({'status': e.target.value})}
                            >
                                <option value='New'>New</option>
                                <option value='Open'>Open</option>
                                <option value='Closed'>Closed</option>
                                <option value='On Hold'>On Hold</option>
                            </select>

                        </div>

                    </div>

                    {/*  ========= Button Container ========= */}
                    <div className='border-solid border-t-2 pt-3 mt-2'>

                        {/* Submit & Close Buttons */}
                        <div className='flex flex-row gap-2'>
                            <button className='Primary-Button whitespace-nowrap' disabled={false} onClick={() => submitHandler()}>Submit</button>
                            <button className='Secondary-Button whitespace-nowrap' onClick={() => setAddTransformationItem(false)}>Cancel</button>
                        </div>
            
                        {/* Form Submission Errors */}
                        <p className='font-medium text-sm text-[var(--darkred)] my-2'>{formData.formSubmissionError}</p>
                        
                    </div>

                </dialog>
            
            </div>
        </>
    )
}
