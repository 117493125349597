//================================================================
//  Tab: TransformationRegister
//================================================================

//  Purpose: This tab displays all 'TransformationRegister'

//  Example:
//    <TransformationRegister></TransformationRegister>    

//================================================================

// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Context
import { SetSortFilters } from '../../../Library/GlobalContexts';

// Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import AddTransformationItem from './SidePanes/AddTransformationItem';
import TableHeader from '../../../Components/SortFilter/TableHeader';
import FilterTags from '../../../Components/SortFilter/FilterTags';
import FilterByPane from '../../../Components/SortFilter/FilterByPane';

// Functions
import QueryListener from '../../../Library/QueryListener';

// Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';


export default function TransformationRegister() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const navigate = useNavigate();

  const setSortFilters = useContext(SetSortFilters);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Current Filter > 'New', 'Open', 'Closed', 'On Hold' & 'All'
  const [currentFilter, setCurrentFilter] = useState('All');

  // Search Input
  const [searchInput, setSearchInput] = useState('');

  // Stores all items
  const [items, setItems] = useState([]);
  const [itemsFiltered, setItemsFiltered] = useState([]);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Handles search function
  function handleSearch(value) {

    // Clear ALL filters & sorting
    if (value.length > 0 && value.length < 2) {

      setSortFilters({

        // Sorting
        'sortBy': '', // vendor.vendorname
        'sortDirection': '', // ascending & descending
        'sortDataType': '', // '' or 'number'

        // Filtering (Map of objects)
        'filters': {},

        // Sidepane for selecting filterby content
        'filterByPane': {
          'columnname': '', // Vendor Name
          'columnid': '', // vendor.vendorname
        }

      });
      
    }

    setSearchInput(value);

    // Reset the Filter
    if (value.length === 0) {

      setItemsFiltered(items);

    };

    // Search Filter
    //  1. processname
    //  2. capabilityarea
    //  3. capabilityserviceowner
    const searchResults = items.filter((object) =>
      object?.processname?.toLowerCase().includes(value.toLowerCase()) ||
      object?.capabilityarea?.toLowerCase().includes(value.toLowerCase()) ||
      object?.capabilityserviceowner?.email?.toLowerCase().includes(value.toLowerCase())
    );

    // Save the filtered search results to the useState
    setItemsFiltered(searchResults);

  };

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all items with the status of 'Active'
  useEffect(() => {

    if (currentFilter === undefined) return;

    function onLoad(documents) {

      setItems(documents);
      setItemsFiltered(documents);
      
      setPageStatus('onload');

    }

    function onChange(documents) {

      setItems(documents);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = QueryListener('transformationregister', currentFilter === 'All' ? [] : [['status', '==', currentFilter]], onLoad, onChange, onError, ['processname', 'asc']);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [currentFilter]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='flex flex-col px-[15px] gap-[20px] w-[calc(100vw-40px)] max-h-[calc(100vh-220px)] overflow-hidden'>

          {/* ----------------------------------- */}
          {/*  Status & Search                    */}
          {/* ----------------------------------- */}

          <div className='flex flex-row justify-between items-center gap-3'>

            {/* ======== Status (Buttons) ========== */}
            <div className='flex flex-row items-center gap-2'>

              {/* New */}
              <button className={currentFilter === 'New' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('New')}>
                New
              </button>

              {/* Open */}
              <button className={currentFilter === 'Open' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Open')}>
                Open
              </button>

              {/* Closed */}
              <button className={currentFilter === 'Closed' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Closed')}>
                Closed
              </button>

              {/* On Hold */}
              <button className={currentFilter === 'On Hold' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('On Hold')}>
                On Hold
              </button>

              {/* All */}
              <button className={currentFilter === 'All' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('All')}>
                All
              </button>

            </div>

            {/* ======== Search, Add Agreement & Download ========== */}
            <div className='flex flex-row justify-end items-center gap-2'>

              {/* ======== Search Bar ======== */}
              <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px]'>
                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                <label htmlFor='searchInput'>
                  <input
                    id='searchInput'
                    className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                    type='text'
                    placeholder='Search'
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchInput}
                    autoComplete='no'
                  ></input>
                </label>
              </div>

              {/* + Add Process Transformation */}
              <AddTransformationItem></AddTransformationItem>

              {/* Download Report */}
              <ExportToCSV
                filename={`Transformation-Register-${Date.now()}`}
                data={itemsFiltered}
              ></ExportToCSV>

            </div>

          </div>

          {/* ----------------------------------- */}
          {/*  Process Transform Register Table   */}
          {/* ----------------------------------- */}

          <FilterTags
            tableArray={items}
            setFilteredTableArray={setItemsFiltered}
          ></FilterTags>

          <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

            {/* ======== Header ========== */}
            <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

              <label className='font-semibold self-center'>
                Total: {itemsFiltered.length} of {items.length}
              </label>

            </div>

            {/* ======== Table Scrollable ========== */}
            <div className='w-full overflow-auto min-h-[350px]'>

              <FilterByPane
                tableArray={items}
              ></FilterByPane>

              <table className='w-full'>
                <thead>
                  <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2 z-10 box-border overflow-visible'>

                    <TableHeader
                      column='Process Name'
                      objectKey='processname'
                      sticky={true}
                    ></TableHeader>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      Process Description
                    </th>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      Proposed Changes
                    </th>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      Proposed Benefits
                    </th>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      Changes Completed
                    </th>
                    <TableHeader
                      column='Impact Assessment'
                      objectKey='impactassessment'
                    ></TableHeader>
                    <TableHeader
                      column='Capability Area'
                      objectKey='capabilityarea'
                    ></TableHeader>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      Capability / Service Owner
                    </th>
                    <TableHeader
                      column='Est cost to change'
                      objectKey='estcosttochange'
                      dataType='number'
                    ></TableHeader>
                    <TableHeader
                      column='Est Savings'
                      objectKey='estsavings'
                      dataType='number'
                      styleInput={{ width: '110px' }}
                    ></TableHeader>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      Start Date
                    </th>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      End Date
                    </th>
                    <th className='py-[15px] px-[20px] font-semibold whitespace-nowrap'>
                      Status
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    // No items found
                    itemsFiltered.length === 0 ?
                      (
                        <tr>
                          <td colSpan={13}>
                            <div className='text-center p-[2%]'>
                              No items found.
                            </div>
                          </td>
                        </tr>
                      )
                      :
                      (
                        // Map in each commercial item
                        itemsFiltered.map((item, index) => (
                          <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>
                            <td className='py-[15px] px-[20px] align-top drop-shadow-md sticky left-0 bg-white'><label className='font-semibold cursor-pointer underline' onClick={() => navigate(`/inventory/transformationregister/${item.transformationid}?view=overview`)}>{item?.processname}</label></td>
                            <td className='py-[15px] px-[20px] align-top'>
                              <p className='line-clamp-4'>{item?.processdescription}</p>
                            </td>
                            <td className='py-[15px] px-[20px] align-top'>
                              <p className='line-clamp-4'>{item?.proposedchanges}</p>
                            </td>
                            <td className='py-[15px] px-[20px] align-top'>
                              <p className='line-clamp-4'>{item?.proposedbenefits}</p>
                            </td>
                            <td className='py-[15px] px-[20px] align-top'>
                              <p className='line-clamp-4'>{item?.changescompleted}</p>
                            </td>
                            <td className='py-[15px] px-[20px] align-top'>
                              <p className='line-clamp-4'>{item?.impactassessment}</p>
                            </td>
                            <td className='py-[15px] px-[20px] align-top'>{item?.capabilityarea}</td>
                            <td className='py-[15px] px-[20px] align-top'>{item?.capabilityserviceowner?.email}</td>
                            <td className='py-[15px] px-[20px] align-top'>${item?.estcosttochange} {item?.estcosttochangecurrency?.length > 0 && `(${item?.estcosttochangecurrency})`}</td>
                            <td className='py-[15px] px-[20px] align-top'>${item?.estsavings} {item?.estsavingscurrency?.length > 0 && `(${item?.estsavingscurrency})`}</td>
                            <td className='py-[15px] px-[20px] align-top whitespace-nowrap'>{item?.startdate}</td>
                            <td className='py-[15px] px-[20px] align-top whitespace-nowrap'>{item?.enddate}</td>
                            <td className='py-[15px] px-[20px] align-top'><StatusLabel status={item?.status}></StatusLabel></td>
                          </tr>
                        ))
                      )
                  }
                </tbody>
              </table>

            </div>

          </div>

        </div>
      }
    ></PageComponent>
  )
}
